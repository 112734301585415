<template>
    <div style="width:200px;">
        <slim-cropper
                :options="slimOptions"
                data-label="Drop your image here"
                :id="selector"
        />
    </div>
</template>

<script>
	import Slim from '../assets/js/Plugins/slim/slim.vue';

	export default {
		components: {
			'slim-cropper': Slim,
		},
		props: {
			selector: {
				type: String,
				default: 'slim-1',
			},
			target: {
				type: String,
				default: '/'
			},
            autoPush: {
				type: Boolean,
				default: false
			}
		},
		data() {
			let vm = this;
			return {
				hasImage: false,
				slimInstance: null,
				isSaving: false,
				slimOptions: {
					label: 'Click to upload',
					push: vm.autoPush,
	                service: vm.target,
					didInit(data, slim) {
						vm.slimInstance = slim;
					},
					didLoad: function(file, image, meta) {
						Bus.$emit('imageUploader-hide-existing-image', vm.selector);
						vm.hasImage = true;
						return true;
					},
					didRemove: function() {
						vm.hasImage = false;
						return true;
					},
                    willRequest(xml) {
						xml.setRequestHeader('X-CSRF-TOKEN', document.head.querySelector('meta[name="csrf-token"]').content);
						xml.setRequestHeader('Authorization', 'Bearer '+document.head.querySelector('meta[name="sesjwt"]').content);
					},
					willSave(data, ready) {
						Bus.$emit(`${vm.selector}-is-saving`);

						data.meta.isSaving = vm.isSaving;
						ready(data);
					},
                    didUpload(error, data, response) {
						Bus.$emit(`${vm.selector}-did-upload`, ...arguments);
					},
				},
			};
		},

		mounted() {


		},
	};
</script>
