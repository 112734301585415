import * as types from "../mutation-types";
import store from "../index";

const state = {
    jwt: '',
    authenticationErrorMsg: ''
};
const getters = {
    isAuthenticated: state => state.jwt != '',
    getJwt: state => state.jwt,
};
const actions = {

    async LogIn({commit}, user)
    {
        await axios.post('user/login', {
            email: user.email,
            password: user.password
        }).then(r => {
            commit(types.SET_JWT_TOKEN, r.data.response.data.token);
            commit(types.SET_AUTHENTICATION_ERROR, '');
        }).catch(error => {
            commit(types.SET_AUTHENTICATION_ERROR, error.response.data.response.error.error_msg);
            reject()
        });
    },

    async GoogleLogIn({commit}, user)
    {
        await axios.post('user/login/google', {
            email: user.email,
            google_id: user.google_id,
            google_token: user.google_token,
            first_name: user.first_name,
            last_name: user.last_name,
            photo_url: user.photo_url
        }).then(r => {
            commit(types.SET_JWT_TOKEN, r.data.response.data.token);
            commit(types.SET_AUTHENTICATION_ERROR, '');
        }).catch(error => {
            commit(types.SET_AUTHENTICATION_ERROR, error.response.data.response.error.error_msg);
            reject()
        });
    },

    async LogOut({commit})
    {
        commit(types.UNSET_JWT_TOKEN, '')
    },

    async SetAuthError({commit}, error)
    {
        commit(types.SET_AUTHENTICATION_ERROR, error.error_msg);
    }

};
const mutations = {
    [types.SET_JWT_TOKEN](state, jwt)
    {
        state.jwt = jwt
        window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.jwt;
    },
    [types.UNSET_JWT_TOKEN](state)
    {
        state.jwt = ''
    },
    [types.SET_AUTHENTICATION_ERROR](state, error_msg)
    {
        state.authenticationErrorMsg = error_msg
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};
