<template>
   <div>
      <div class="row setTitleDiv set_res_project_margin_top">
         <div v-if="page_url=='mvd'">
            <P> MV Project Status-{{this.allMonthTitle}}-2019 </p>
         </div>
         <div v-if="page_url=='dpm'">
            <P> PM Project Status-{{this.allMonthTitle}}-2019 </p>
         </div>
         <svg preserveAspectRatio="none" width="15px" height="20px" viewBox="0 0 15 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="projectdetail-desktop" @click="changeAllField(Allprojectid)">
            <title>Arrow Right</title>
            <desc>Created with Sketch.</desc>
            <defs></defs>
            <g id="s-Image_25-Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
               <g id="s-Image_25-Components" transform="translate(-463.000000, -1276.000000)" fill="#DDDDDD">
                  <g id="s-Image_25-Arrow-Right" transform="translate(463.000000, 1276.000000)">
                     <polyline points="1.157 0 0 1.144 12.325 13.62 0 25.691 1.325 27 15 13.691 1.157 0" style="fill: rgb(0, 0, 0) !important;"></polyline>
                  </g>
               </g>
            </g>
         </svg>
      </div>
      <div class="setMarginTop">
         <div class="row">
            <div class="col-md-6 col-xl-6">
               <div class="card setShadow">
                  <div class="card-body">
                     <div v-for="(item, index) in items">
                        <div>
                           <div class="row col-sm-12">
                              <div class="col-2 setpadding imageset" v-if="page_url=='mvd'">
                                 <img v-bind:src="'' + item.mvd" class="img_logo"   />
                              </div>
                              <div class="col-2 setpadding imageset" v-if="page_url=='dpm'">
                                 <img v-bind:src="'' + item.dpm" class="img_logo"   />
                              </div>
                              <div class="col-2 setproject_box setpadding">
                                 <div class="number">
                                    <p>Total</p>
                                    {{item.count}}
                                 </div>
                              </div>
                              <div class="col-2 setproject_boxpadding setpadding">
                                 <div class="number">
                                    <p>Male</p>
                                    {{item.count}}
                                 </div>
                              </div>
                              <div class="col-2 setproject_boxpadding setpadding">
                                 <div class="number">
                                    <p>Female</p>
                                    {{item.count}}
                                 </div>
                              </div>
                              <div class="col-2 setproject_boxpadding setpadding">
                                 <div class="number">
                                    <p>Young</p>
                                    {{item.count}}
                                 </div>
                              </div>
                              <div class="col-2 setproject_boxpadding setresponsivebox  setpadding"  v-if="page_url=='mvd'">
                                 <div class="number">
                                    <p>Lactating</p>
                                    {{item.count}}
                                 </div>
                              </div>
                              <div class="col-2 setproject_boxpadding setresponsivebox  setpadding"   v-if="page_url=='dpm'">
                                 <div class="number">
                                    <p>Reproductive</p>
                                    {{item.count}}
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div>
                           <hr>
                           <div class="stats">
                              <i class="ti-timer"></i> <span class="Setfontsize">{{  monthtitle   }}-2019 </span>
                              <svg preserveAspectRatio="none" width="15px" height="27px" viewBox="0 0 15 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="Set-floatright" @click="changeMonth(index)">
                                 <title>Arrow Right</title>
                                 <desc>Created with Sketch.</desc>
                                 <defs></defs>
                                 <g id="s-Image_25-Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="s-Image_25-Components" transform="translate(-463.000000, -1276.000000)" fill="#DDDDDD">
                                       <g id="s-Image_25-Arrow-Right" transform="translate(463.000000, 1276.000000)">
                                          <polyline points="1.157 0 0 1.144 12.325 13.62 0 25.691 1.325 27 15 13.691 1.157 0" style="fill: rgb(0, 0, 0) !important;"></polyline>
                                       </g>
                                    </g>
                                 </g>
                              </svg>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-md-6 col-xl-6">
               <div class="card setShadow">
                  <div class="card-body">
                     <div v-for="(itembox, index) in products">
                        <div>
                           <div class="row col-sm-12">
                              <div class="col-2 setpadding imageset"  v-if="page_url=='mvd'">
                                 <img v-bind:src="'' + itembox.mvd" class="img_logo" />
                              </div>
                              <div class="col-2 setpadding imageset"  v-if="page_url=='dpm'">
                                 <img v-bind:src="'' + itembox.dpm" class="img_logo" />
                              </div>
                              <div class="col-2  setproject_box setpadding ">
                                 <div class="number">
                                    <p>Total</p>
                                    {{itembox.count_date}}
                                 </div>
                              </div>
                              <div class="col-2  setproject_boxpadding setpadding ">
                                 <div class="number">
                                    <p>Male</p>
                                    {{itembox.count_date}}
                                 </div>
                              </div>
                              <div class="col-2  setproject_boxpadding setpadding ">
                                 <div class="number">
                                    <p>Female</p>
                                    {{itembox.count_date}}
                                 </div>
                              </div>
                              <div class="col-2  setproject_boxpadding setpadding ">
                                 <div class="number">
                                    <p>Young</p>
                                    {{itembox.count_date}}
                                 </div>
                              </div>
                              <div class="col-2 setproject_boxpadding setresponsivebox  setpadding" v-if="page_url=='mvd'">
                                 <div class="number">
                                    <p>Lactating</p>
                                    {{itembox.count_date}}
                                 </div>
                              </div>
                              <div class="col-2  setproject_boxpadding setresponsivebox setpadding" v-if="page_url=='dpm'">
                                 <div class="number">
                                    <p>Reproductive</p>
                                    {{itembox.count_date}}
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div>
                           <hr>
                           <div class="stats">
                              <i class="ti-timer"></i> <span class="Setfontsize"> {{itembox.date_title}}</span>
                              <svg preserveAspectRatio="none" width="15px" height="27px" viewBox="0 0 15 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="Set-floatright" @click="changeCount(id,index)">
                                 <title>Arrow Right</title>
                                 <desc>Created with Sketch.</desc>
                                 <defs></defs>
                                 <g id="s-Image_25-Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="s-Image_25-Components" transform="translate(-463.000000, -1276.000000)" fill="#DDDDDD">
                                       <g id="s-Image_25-Arrow-Right" transform="translate(463.000000, 1276.000000)">
                                          <polyline points="1.157 0 0 1.144 12.325 13.62 0 25.691 1.325 27 15 13.691 1.157 0" style="fill: rgb(0, 0, 0) !important;"></polyline>
                                       </g>
                                    </g>
                                 </g>
                              </svg>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
   import Vue from 'vue';
   import {Table, TableColumn, Select, Option} from 'element-ui';
   import PPagination from '../UIComponents/Pagination.vue';
   import users from './users';
   import swal from 'sweetalert2';
     import mvdlogo from '../../../assets/images/mv-logo.png';
     import dpmlogo from '../../../assets/images/pm-logo.png';

   Vue.use(Table);
   Vue.use(TableColumn);
   Vue.use(Select);
   Vue.use(Option);
   export default {
   	components: {
   		PPagination,
   	},
   	computed: {
         },
          created: function () {
             this.month='Jan';
             this.allMonthTitle='Jan';
             this.monthtitle='Jan';
             this.countdata='500';
             //this.datetitle='15-Jan-2019';
        },
        props: {
              mvdlogo: {
                  type: String,
                  default: mvdlogo,
              },
              dpmlogo: {
                  type: String,
                  default: dpmlogo,
              },
               page_url: {
                  type: String,
                  default: 'TEST USER',
              },
            },
      	data() {
   		return {
            id:1,
            month:"",
            monthtitle:"",
            allMonthTitle:"",
            dateId:0,
            Allprojectid:0,
            index:0,
            countdata:"",
           items: [
   				{
   				   count: '1000',
                    mvd:mvdlogo,
                    dpm:dpmlogo,
                    year:'2019',
                    //monthtitle: 'Jan-2019',
                    //datetitle: '14-Jan-2019',
                  },
              ],
              products: [
   				{
   				   count_date: '1000',
                    mvd:mvdlogo,
                    dpm:dpmlogo,
                    year:'2019',
                   // monthtitle: 'Jan-2019',
                    date_title: '15-Jan-2019',
                 },
              ],
              montharray:['Jan','Feb','Mar'],
              datearray:[15,16,17],
              countarray:[1000,500,700],
              }
   	},
   	methods: {
           // change MVD month
   		changeMonth(index) {	 // Record dispaly According to month
              this.index = this.index+1;
                 if(this.index>2){
                    this.index=0;
                 }
                 this.monthtitle=this.montharray[this.index];
               this.items[0].count= this.countarray[this.index];
         },
           changeCount(id,index) 	{
              this.dateId=this.dateId + 1;
              if(this.dateId > 2){
                this.dateId = 0;
              }
              //this.countarray[this.dateId];
             this.products[0].date_title= this.datearray[this.dateId]+'-'+this.allMonthTitle+'-2019';
             this.products[0].count_date= this.countarray[this.dateId];
             //this.dateId++;
   			// if(this.id==1){
            	// 	this.id=2;
   			// 	this.index=1;
              //    this.products[0].date_title='15-'+this.month+'-2019';
              //    this.products[0].count_date='500';
              //    return ;
   			// }
   			// if(this.id==2){
              // 	this.id=3;
   			// 	this.index=1;
              //    this.products[0].date_title='16-'+this.month+'-2019';
   			// 	this.products[0].count_date='100';
   			// 	return;
   			// }
   			// if(this.id==3){
   			// 	this.id=1;
   			// 	this.index=1;
              //    this.products[0].date_title='14-'+this.month+'-2019';
   			// 	this.products[0].count_date='1000';
   			// 	return ;
              // }


        },
        // Change all months
        changeAllField(Allprojectid) {
             this.Allprojectid =this.Allprojectid+1;
             if(this.Allprojectid>2){
                this.Allprojectid = 0;
             }
             this.index = this.Allprojectid;
             //change month
             this.monthtitle=this.montharray[this.index];
             this.allMonthTitle = this.montharray[this.Allprojectid];
             this.products[0].date_title= this.datearray[this.dateId]+'-'+this.allMonthTitle+'-2019';
             //change count
             this.items[0].count= this.countarray[this.index];
             this.products[0].count_date= this.countarray[this.index];
        }
     },
   };
</script>
<style lang="scss">
   .el-table .td-actions {
   button.btn {
   margin-right: 5px;
   }
   }
   @media only screen and (max-device-width: 1024px) {
   .setpadding{
   padding:0px
   }
   .setresponsivebox{
   -webkit-box-flex: 0;
   -ms-flex: 0 0 33.33333333%;
   /* flex: 0 0 33.33333333%; */
   max-width: 33.33333333%;
   margin-left: 85px;
   }
   }
   @media only screen and (max-device-width: 360px) {
   .projectdetail-desktop{
   margin-left: 260px;
   }
   }
   @media only screen and (max-device-width: 768px) {
   .setproject_box{
   -webkit-box-flex: 0;
   -ms-flex: 0 0 100%;
   flex: 0 0 100%;
   max-width: 100%;
   }
   .setproject_boxpadding{
   -webkit-box-flex: 0;
   -ms-flex: 0 0 50%;
   flex: 0 0 50%;
   max-width: 50%;
   }
   .setresponsivebox{
   margin-left: 0px;
   }
   }
   .setpadding{
   padding:0px;
   }
   // Pradeep Start
   .imageset{
      text-align: left;
   }
   .img_logo{
        height: 30px;
        margin-top: 5px;
        border-radius: 0px !important;
   }
   .Set-floatright {
    float: right;
   }
   // @media (min-width: 320px) and (max-width: 374px) {
	// 	.imageset {
	// 		text-align: left;
   //       margin-left: -8px !important;
	// 	}
	// }
   // @media (min-width: 375px) and (max-width: 768) {
	// 	.imageset {
	// 		text-align: left;
   //       margin-left: -9px !important;
	// 	}
	// }
   .setTitleDiv p {
    font-size: 15px;
    font-weight: 600;
    color: black;
   }
   .card .number{
     font-size: 1.5em;
     text-align: right;
   }
   .card .number p {
    margin: 0;
    font-size: 13px;
    color: #9A9A9A;
   }
   .projectdetail-desktop{
		margin-left: 20px !important;
      cursor: pointer;
	}
   // Pradeep End
</style>
