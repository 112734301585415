import * as types from "../mutation-types";

const state = {
	items: [],
	selectedItems: []
};

// getters
const getters = {
	items: state => state.items,
	selectedItems: state => state.selectedItems
};

// actions
const actions = {
	getDemoItems({commit}) {
		return axios.get('/api/demo').then((response) => {
			commit(types.SET_DEMO_DATA, response.data.data);
		});
	},
	appendDemoItem({commit}, item) {
		commit(types.APPEND_DEMO_DATA, item);
		return Promise.resolve();
	},
	addSelectedItem({commit,dispatch}, item) {
		dispatch('appendDemoItem', item).then(() => {
			commit(types.SET_SELECTED_DATA, item);
		});
	}
};

// mutations
const mutations = {
	[types.SET_DEMO_DATA](state, data) {
		state.items = data;
	},
	[types.APPEND_DEMO_DATA](state, item) {
		state.items.push(item);
	},
	[types.SET_SELECTED_DATA](state, item) {
		state.selectedItems.push(item);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};