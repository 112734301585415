<template>
    <navbar v-model="showNavbar">
        <div class="navbar-wrapper">
            <div class="navbar-minimize">
                <button id="minimizeSidebar" class="btn btn-icon btn-round navbar-icon" @click="minimizeSidebar">
                    <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
                    <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
                </button>
            </div>
            <div class="navbar-toggle">
                <navbar-toggle-button @click.native="toggleSidebar">
                </navbar-toggle-button>
            </div>
            <a class="navbar-brand" href="#pablo">{{ appName }}</a>
        </div>

        <template slot="navbar-menu">
            <ul class="navbar-nav navbar-all-nav">
                <li>
                    <div class="notification-icon-wrapper">
                        <div v-if="notificationCount" class="notification-red-badge">{{
                                notificationCount
                            }}
                        </div>
                        <a class="nav-link ti-bell" title="Notifications" style="font-size: 20px; cursor: pointer"
                           @click="userNotificationsPage"></a>
                    </div>
                </li>
                <li>
                    <a class="nav-link ti-help" title="Need help?" style="font-size: 20px" href="#"
                       @click="showHelpModal()"></a>
                </li>
                <div class="photo" v-if="usertype=='admin'"><img :src="adminImage" height="40px"/></div>
                <div class="photo" v-if="usertype=='user'"><img :src="userImage" height="40px"/></div>
                <drop-down icon="nc-icon nc-bell-55" tag="li" position="right" direction="none"
                           class="nav-item btn-rotate dropdown">
                    <a slot="title" slot-scope="{isOpen}" class="nav-link dropdown-toggle dropdown-toggle-name"
                       aria-haspopup="true" :aria-expanded="isOpen">

                        <p class="user-name" :title="username">{{ username }}</p>
                    </a>
                    <a class="dropdown-item" @click="userProfilePage">
                        <span class="sidebar-mini-icon">Mp</span>
                        <span class="sidebar-normal">My Profile</span>
                    </a>
                    <!-- Pradeep Start-->
                    <!-- only user side view  Settings and Organisation Settings in dropdown -->
                    <!-- <a class="dropdown-item" href="#" v-if="usertype=='user'">
                        <span class="sidebar-normal">Settings</span>
                    </a> -->
                    <a class="dropdown-item" @click="userOrganisationPage" v-if="usertype=='user'">
                        <span class="sidebar-normal">Organisation Settings</span>
                    </a>
                    <!-- <a class="dropdown-item" href="#" @click="showHelpModal()">
                           <span class="sidebar-normal">Help</span>
                    </a> -->
                    <!-- Pradeep End -->
                    <router-link to="" tag="a">
                        <a class="dropdown-item" @click="logout()">
                            <span class="sidebar-normal">Logout</span>
                        </a>
                    </router-link>
                </drop-down>
                <!--<li class="nav-item">
                    <a class="nav-link btn-rotate" href="#pablo">
                        <i class="fa fa-question-circle" aria-hidden="true"></i>
                    </a>
                </li>-->
            </ul>

        </template>
        <div class="type-header" v-if="projectType=='MV'">
            <span>Mass Vaccination</span>
        </div>
        <div class="type-header" v-if="projectType=='PM'">
            <span>Population Management</span>
        </div>
        <help-modal ref="helpModal2"></help-modal>
    </navbar>
</template>
<script>
import {Navbar, NavbarToggleButton} from '../UIComponents';
import HelpModal from "./HelpModal.vue";

export default {
    components: {
        Navbar,
        NavbarToggleButton,
        "help-modal": HelpModal
    },
    data()
    {
        return {
            username: '',
            activeNotifications: false,
            showNavbar: false,
            projectType: "",
            userImage: '',
            notificationCount: null,
        };
    },
    props: {
        usertype: {
            type: String,
        }
    },
    methods: {
        userProfilePage()
        {
            this.$router.push({'name': 'userProfile',});
        },
        userOrganisationPage()
        {
            this.$router.push({'name': 'organisationSettings',});
        },
        userNotificationsPage()
        {
            this.$router.push({'name': 'userNotifications',});
        },
        capitalizeFirstLetter(string)
        {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown()
        {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown()
        {
            this.activeNotifications = false;
        },
        toggleSidebar()
        {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar()
        {
            this.$sidebar.displaySidebar(false);
        },
        minimizeSidebar()
        {
            let href = location.href;
            if (href.indexOf('record_detail_list') > -1) {
                if (document.querySelector('body').getAttribute('class').indexOf('sidebar-mini') >= 0) {
                    document.querySelectorAll('.project_name')[0].style.fontSize = "1.57em";
                    document.querySelectorAll('.img_fix_size')[0].style.height = "100px";
                } else {
                    document.querySelectorAll('.project_name')[0].style.fontSize = "13px";
                    document.querySelectorAll('.img_fix_size')[0].style.height = "60px";
                }
            }
            document.querySelectorAll('.sidebar')[0].style.zIndex = "1";
            this.$sidebar.toggleMinimize();
        },
        toggleNavbar()
        {
            this.showNavbar = !this.showNavbar;
        },
        sidebarSetProjectNameImg()
        {
            let sidebar = document.querySelectorAll(".sidebar")[0];
            sidebar.onmouseover = (event => {
                if (document.querySelector('body').getAttribute('class').indexOf('sidebar-mini') >= 0) {
                    document.querySelectorAll('.project_name')[0].style.fontSize = "1.57em";
                    document.querySelectorAll('.img_fix_size')[0].style.height = "100px";
                    document.querySelectorAll('.sidebar')[0].style.zIndex = "1030";
                }
            });
            sidebar.onmouseout = (event => {
                if (document.querySelector('body').getAttribute('class').indexOf('sidebar-mini') >= 0) {
                    document.querySelectorAll('.project_name')[0].style.fontSize = "13px";
                    document.querySelectorAll('.img_fix_size')[0].style.height = "60px";
                    document.querySelectorAll('.sidebar')[0].style.zIndex = "1";
                }
            });
        },
        sidebarMenuIcon()
        {
            let sidebar = document.querySelectorAll(".sidebar")[0];
            let href = location.href;
            sidebar.onmouseover = (event => {
                if (this.$sidebar.isMinimized) {
                    document.querySelectorAll(".navbar-icon")[0].style.setProperty("margin-left", "130px", "important");
                }
                if (href.indexOf('record_detail_list') > -1) {
                    if (document.querySelector('body').getAttribute('class').indexOf('sidebar-mini') >= 0) {
                        document.querySelectorAll('.project_name')[0].style.fontSize = "1.57em";
                        document.querySelectorAll('.img_fix_size')[0].style.height = "100px";
                    }
                }
            });
            sidebar.onmouseout = (event => {
                if (this.$sidebar.isMinimized) {
                    document.querySelectorAll(".navbar-icon")[0].style.setProperty("margin-left", "-50px", "important");
                }
                if (href.indexOf('record_detail_list') > -1) {
                    if (document.querySelector('body').getAttribute('class').indexOf('sidebar-mini') >= 0) {
                        document.querySelectorAll('.project_name')[0].style.fontSize = "13px";
                        document.querySelectorAll('.img_fix_size')[0].style.height = "60px";
                    }
                }
            });
        },
        projectDetails(projectId)
        {
            axios.get("/project/details", {
                params: {
                    project_id: projectId,
                }
            })
                .then(response => {
                    var project = response.data.response.data.project;
                    this.projectType = project.type;
                })
                .catch(error => {
                });
        },
        async logout()
        {
            await this.$store.dispatch('LogOut')
            this.$router.push('/login')
        },
        notifications()
        {
            let vm = this;
            axios.get('user/notifications').then(response => {
                if (response.data.response.error.error_code == 0) {
                    var notifications = response.data.response.data.notifications;
                    for (var i = 1; i < notifications.length; i++) {
                        var read = notifications[i].read;
                        if (read == 0) {
                            if (vm.notificationCount == null) {
                                vm.notificationCount = 0;
                            }
                            vm.notificationCount++;
                        }
                    }
                    if (vm.notificationCount > 99) {
                        vm.notificationCount = '99+';
                    }
                }
            }).catch(error => {
            });
        },
        showHelpModal()
        {
            this.$refs.helpModal2.loadUrl(window.USER_MANUAL_URL);
        }
    },
    computed: {
        appName()
        {
            return window.AppName;
        },
        adminImage()
        {
            if (this.$props.userimage == null) {
                return window.DefaultUserImage;
            } else {
                if (this.$props.userimage == '') {
                    return window.DefaultUserImage;
                }
                return this.$props.userimage;
            }
        },
        logo()
        {
            return "https://aaf1a18515da0e792f78-c27fdabe952dfc357fe25ebf5c8897ee.ssl.cf5.rackcdn.com/104/HSI-logo.png?v=1503710412000";
        },
        title()
        {
            return window.appConfig.appName;
        },
    },
    created()
    {
        let vm = this;

        var userData = JSON.parse(localStorage.getItem('userdata'));
        vm.username = userData.userName;
        var user_image = userData.userImage;
        if (user_image == null || user_image == '') {
            vm.userImage = window.DefaultUserImage;
        } else {
            vm.userImage = user_image;
        }

        vm.sidebarMenuIcon();

        this.helpLinkUrl = window.USER_MANUAL_URL;
        Bus.$emit("projecNavbar", 1);
        let href = location.href;
        if (href.indexOf('record_detail_list') > -1) {
            this.$sidebar.toggleMinimize();
            document.querySelectorAll('.project_name')[0].style.fontSize = "13px";
            document.querySelectorAll('.img_fix_size')[0].style.height = "60px";
        }
        document.querySelectorAll('.sidebar')[0].style.zIndex = "1";
        let url = location.href;
        let project = JSON.parse(localStorage.project);
        if (href.indexOf('edit_area') > -1 || href.indexOf('area_details') > -1) {
            this.projectType = "MV";
        } else {
            this.projectDetails(project.id);
        }
        vm.notifications();

        vm.$forceUpdate();
    }
};

</script>
<style lang="scss" scoped>
.type-header {
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  color: black;
}

@media (min-width: 1024px) {
  .type-header {
    width: 50%;
  }
}

@media (min-width: 1366px) {
  .type-header {
    width: 65%;
  }
}

@media (min-width: 1367px) {
  .type-header {
    width: 76%;
  }
}

.remove-color:hover {
  color: #66615B !important;
}

.badge {
  position: absolute;
  top: -14px;
  right: -6px;
  padding: 3px 7px 5px 7px;
  border-radius: 50%;
  background-color: red;
  color: white;
}

.navbar-icon {
  margin-left: -50px !important;
}

.user-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 120px;
}

.dropdown-toggle-name::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  top: 18px;
}

@media (max-width: 800px) {
  .navbar-all-nav {
    flex-direction: row;
    margin-top: 30px;
  }
  .dropdown-icon {
    margin-top: 0px !important;
  }
}

.notification-red-badge {
  position: absolute;
  right: 5px;
  padding-left: 2px;
  padding-right: 2px;
  background-color: red;
  text-align: center;
  margin: 0;
  font-size: 12px;
  color: white;
  font-weight: bold;
  border-radius: 30px;
  height: 19px;
  min-width: 19px;
}

.notification-icon-wrapper {
  position: relative;
}

</style>
