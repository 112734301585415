import defaultLogo from './assets/images/HSIApps-Trnprnt-green.png';

export default {
    appName: 'HSAPPS',
    //logo: 'https://s3.amazonaws.com/hsi-platform/HSII_LogoV_India_3435_4Cc-1.png',
    logo: defaultLogo,
    title: 'MVD App',
    project: 'mvd',
    nav: [
        {
            name: 'Overview',
            icon: 'ti-dashboard',
            path: '/project/details',

        },
        {
            name: 'Areas',
            icon: 'ti-map-alt',
            path: 'areas',
            p:'/areas',
        },
        {
            name: 'Assignments',
            icon: 'ti-check-box',
            path: '/project/catch_rlease_user',

        },
        {
            name: 'Records',
            icon: 'ti-view-list-alt',
            id: 'third-party',
            children: [
                {
                    name: 'Awaiting Sterilization',
                    path: '/project/not_operated',
                },
                {
                    name: 'Sterilized',
                    path: '/project/operated',
                },
                {
                    name: 'Treatment Status',
                    path: '/project/treatment_status',
                },
                {
                    name: 'Ready for Release',
                    path: '/project/ready_for_release',
                },
                {
                    name: 'Released',
                    path: '/project/released',
                },
                {
                    name: 'Archive',
                    path: '/catch_release/archived/records',
                }
            ],
        },
        {
            name: 'Reports',
            icon: 'ti-bar-chart-alt',
            id: 'third-party',
            children: [
                {
                    name: 'Locality',
                    path: '/project/locality',
                },
                {
                    name: 'Map',
                    path: '/project/dpm_report_map',
                },
                {
                    name: 'Microchip',
                    path: '/project/microchip',
                },
            ],
        },
        {
            name: 'Settings',
            icon: 'nc-icon nc-settings',
            path: '/pm/edit/project',
        }
    ],
};
