<template>
    <div>
        <!--pradeep start -->
        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline"
        >
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div id="MVD">
            <div class="row setMarginTop">
                <template v-if="project.banner_message!=null">
                    <div v-if="project.banner_message!=''" class="col-sm-12">
                        <div class="card setShadow">
                            <div class="card-body" style="padding: 10px;">
                                <p style="margin-top: 15px; font-size: 15px; font-weight: bold;">{{
                                        project.banner_message
                                    }}</p>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12">
                    <div class="card setShadow">
                        <div class="card-body" style="padding: 10px;">
                            <div
                                    id="map"
                                    style="height: 400px !important;width:100%;"
                            ></div>
                            <!--<GmapMap
                              gestureHandling="greedy"
                              :center="center"
                              :options="{scrollwheel: true}"
                              style="width: 100%; height: 400px"
                            >
                              <gmap-marker
                                :key="index"
                                v-for="(m, index) in mvmarkers"
                                :position="m.postion"
                                :icon="m.icon"
                              ></gmap-marker>
                            </GmapMap>-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row setMarginTop">
                <div class="col-xs-6 col-sm-6 col-md-3">
                    <div class="card setShadow">
                        <div class="card-body">
                            <div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="numbers">
                                            <p>Project Owner</p>
                                            {{ projectOverview.project_owner }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-3">
                    <div class="card setShadow">
                        <div class="card-body">
                            <div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="numbers">
                                            <p>Total Treated</p>
                                            {{
                                                projectOverview.total_animals_treated
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-3">
                    <div class="card setShadow">
                        <div class="card-body">
                            <div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="numbers">
                                            <p>Avg Treated/Day*</p>
                                            <!-- 999 -->
                                            {{ projectOverview.avg_animals_vaccinated_per_day }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-3">
                    <div class="card setShadow">
                        <div class="card-body">
                            <div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="numbers">
                                            <p>Out of Area</p>
                                            <!-- 999 -->
                                            {{ projectOverview.total_out_of_area }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row setMarginTop">
                <div class="col-sm-6 col-xs-6 col-md-3">
                    <div class="card setShadow">
                        <div class="card-body">
                            <div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="numbers">
                                            <p>Vaccination Areas</p>
                                            <!-- 999 -->
                                            {{ projectOverview.total_areas }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xs-6 col-md-3">
                    <div class="card setShadow">
                        <div class="card-body">
                            <div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="numbers">
                                            <p>
                                                Vacc. Areas Assigned
                                            </p>
                                            <!-- 899 -->
                                            {{ projectOverview.assigned_areas }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xs-6 col-md-3">
                    <div class="card setShadow">
                        <div class="card-body">
                            <div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="numbers">
                                            <p>Avg Vacc. Time</p>
                                            {{
                                                projectOverview.avg_time_taken_for_vaccination
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row setMarginTop">
                <div class="col-sm-6 col-xs-6 col-md-3">
                    <div class="card setShadow">
                        <div class="card-body">
                            <div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="numbers">
                                            <p>Areas Surveyed</p>
                                            <!-- 799 -->
                                            {{ projectOverview.areas_surveyed }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xs-6 col-md-3">
                    <div class="card setShadow">
                        <div class="card-body">
                            <div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="numbers">
                                            <p>Areas Surveyed & Target Met</p>
                                            {{
                                                projectOverview.areas_completed
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xs-6 col-md-3">
                    <div class="card setShadow">
                        <div class="card-body">
                            <div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="numbers">
                                            <p>Areas Not Surveyed</p>
                                            <!-- 99 -->
                                            {{
                                                projectOverview.areas_not_surveyed
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-3">
                    <div class="card setShadow">
                        <div class="card-body">
                            <div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="numbers">
                                            <p>
                                                Vacc. Rate Based on Surveys
                                            </p>
                                            {{ projectOverview.avg_area_covered }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row setMarginTop set_res_margin_top">
                <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12">
                    <div class="row setTitleDiv">
                        <p></p>
                    </div>
                    <!-- Pradeep Start -->
                    <div class="setMarginTop">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-6">
                                <div class="card setShadow">
                                    <!---->
                                    <!--<div class="card-header">
                                      <div class="containBytitile">
                                        <h4 class="card-title">Animals Treated (Species)</h4>
                                      </div>
                                      <p class="card-category"></p>
                                    </div>-->
                                    <div
                                            class="card-body"
                                            style="height: 435px;"
                                    >
                                        <!--<pie-charts></pie-charts>-->
                                        <highcharts
                                                :options="PieChartOptions"
                                        ></highcharts>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6">
                                <!--<line-mvd charttitle="Vaccinations by Month"></line-mvd>-->
                                <div class="card setShadow">
                                    <div
                                            class="card-body"
                                            style="height: 435px;"
                                    >
                                        <highcharts
                                                :options="LineChartOptions"
                                        ></highcharts>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-6">
                                <!--<line-mvd charttitle="Vaccinations by Month"></line-mvd>-->
                                <div class="card setShadow">
                                    <div
                                            class="card-body"
                                            style="height: 435px;"
                                    >
                                        <highcharts
                                                :options="StackedColumnChartOptions"
                                        ></highcharts>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Pradeep End -->
                </div>
            </div>
            <!-- <mvd-project page_url="mvd"></mvd-project> -->
            <div class="row setMarginTop">
                <div class="col-md-12 col-xl-12 col-sm-12 col-xs-12 set_res_margin_top">
                    <div class="row setTitleDiv">
                        <p>Field User - Stats</p>
                    </div>
                    <div class="row setMarginTop">
                        <div class="col-sm-6 col-xs-6 col-md-3 col-xl-3">
                            <div class="card setShadow">
                                <div class="card-body">
                                    <div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="numbers">
                                                    <p>Vaccinators</p>
                                                    <!-- 99 -->
                                                    {{ projectOverview.vaccinators }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-6 col-md-3 col-xl-3">
                            <div class="card setShadow">
                                <div class="card-body">
                                    <div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="numbers">
                                                    <p>Surveyors</p>
                                                    <!-- 99 -->
                                                    {{ projectOverview.surveyors }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-6 col-md-3 col-xl-3">
                            <div class="card setShadow">
                                <div class="card-body">
                                    <div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="numbers">
                                                    <p>
                                                        Avg Animals Treated by User/Day
                                                    </p>
                                                    {{ projectOverview.avg_animals_vaccinated_per_user_day }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-6 col-md-3 col-xl-3">
                            <div class="card setShadow">
                                <div class="card-body">
                                    <div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="numbers">
                                                    <p>Non-Active Field Users</p>
                                                    <!-- 0 -->
                                                    {{ projectOverview.non_active_field_users }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--            <div class="row setMarginTop">
                            <div class="col-md-12 col-xl-12 col-sm-12 col-xs-12 set_res_margin_top">
                                <div class="row setTitleDiv">
                                    <p>Total Admin Users</p>
                                </div>
                                <div class="row setMarginTop">
                                    <div class="col-xs-6 col-sm-6 col-md-3 col-xl-2">
                                        <div class="card setShadow">
                                            <div class="card-body">
                                                <div>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="numbers">
                                                                <p>Managers</p>
                                                                &lt;!&ndash; 1 &ndash;&gt;
                                                                {{ projectOverview.managers }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-3 col-xl-2">
                                        <div class="card setShadow">
                                            <div class="card-body">
                                                <div>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="numbers">
                                                                <p>Contributors</p>
                                                                &lt;!&ndash; 3 &ndash;&gt;
                                                                {{ projectOverview.contributors }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-3 col-xl-2">
                                        <div class="card setShadow">
                                            <div class="card-body">
                                                <div>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="numbers">
                                                                <p>Viewers</p>
                                                                &lt;!&ndash; 2 &ndash;&gt;
                                                                {{ projectOverview.viewers }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                                        <div class="card setShadow">
                                            <div class="card-body">
                                                <div>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="numbers">
                                                                <p>Total Admin Users</p>
                                                                &lt;!&ndash; 10 &ndash;&gt;
                                                                UC
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
                                        <div class="card setShadow">
                                            <div class="card-body">
                                                <div>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="numbers">
                                                                <p>Non-Active Users</p>
                                                                &lt;!&ndash; 1 &ndash;&gt;
                                                                UC
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>-->

            <div class="row">
                <div class="col-sm-12">
                    <div class="row setTitleDiv">
                        <p>Active Field Users (Past 10 days)</p>
                    </div>
                </div>
                <div class="col-sm-12">
                    <dash-tables :users="projectOverview.active_users_last_ten_days"></dash-tables>
                </div>
                <div class="col-sm-12" style="margin-top: 35px;">
                    <hr>
                    <p>*Days with no activity are not considered in this calculation</p>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import mixin from "../../mixins/GlobalMixin";
import FgInput from "./UIComponents/Inputs/formGroupInput";
import Mvdproject from "./Tables/MvdprojectDetails.vue";
import DashboardTables from "./Tables/DashboardTables.vue";
import DashboardCharts from "./Dashboardchart.vue";
import DashboardpieCharts from "./DashboardpieCharts.vue";
import Mvd from "./Mvd.vue";

import {Autocomplete} from "element-ui";

import {mapGetters} from "vuex";

import {API_KEY} from "./Maps/API_KEY";
import {MAP_STYLE} from "./Maps/MAP_STYLE";
import GoogleMapsLoader from "google-maps";
import swal from "sweetalert2";
import VOffline from "v-offline";

// Highcharts Start
import {Chart} from "highcharts-vue";
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import exportingInitData from "highcharts/modules/export-data";

GoogleMapsLoader.KEY = API_KEY;

exportingInit(Highcharts);
exportingInitData(Highcharts);
// Highcharts End

export default {
    components: {
        FgInput,
        [Autocomplete.name]: Autocomplete,
        "mvd-project": Mvdproject,
        "dash-tables": DashboardTables,
        charts: DashboardCharts,
        "pie-charts": DashboardpieCharts,
        "line-mvd": Mvd,
        VOffline,
        highcharts: Chart,
    },
    props: {
        projectId: {
            type: Number
        },
        organisation: String
    },
    mixins: [mixin],
    data()
    {
        return {
            projectOwnerName: "",
            project: {},
            userEmail: "",
            errorMsg: null,
            callingApi: true,
            selectedUserRole: 1,
            modelValidations: {
                userEmail: {
                    required: true,
                    email: true,
                    regex: /^([a-zA-Z0-9])(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
                },
                surveyPercentage: {
                    required: true
                }
            },
            invitingUser: false,
            searchedUsers: [],
            timeout: null,
            isUpdatingSurveyPercentage: false,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            markers: [
                {
                    postion: {
                        lat: 22.030496303812726,
                        lng: 72.59690873769523
                    },
                    icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                },
                {
                    postion: {
                        lat: 22.030496303812726,
                        lng: 71.59690873769523
                    },
                    icon:
                        "http://maps.google.com/mapfiles/ms/icons/pink-dot.png"
                },
                {
                    postion: {
                        lat: 22.030496303812726,
                        lng: 70.59690873769523
                    },
                    icon:
                        "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                },
                {
                    postion: {
                        lat: 23.030496303812726,
                        lng: 71.59690873769523
                    },
                    icon:
                        "http://maps.google.com/mapfiles/ms/icons/pink-dot.png"
                },
                {
                    postion: {
                        lat: 23.030496303812726,
                        lng: 72.59690873769523
                    },
                    icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                }
            ],
            center: {lat: 33.8291523, lng: -116.8004315},
            mvmarkers: [],
            projectOverview: {},
            //Pie Chart Start MVD
            PieChartOptions: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                    style: {
                        fontFamily:
                            "Montserrat,Helvetica Neue,Arial,sans-serif",
                        fontSize: "10px"
                    }
                },
                title: {
                    text: "Animals Treated (Species)",
                    align: "left",
                    style: {
                        color: "#252422",
                        fontSize: "18px",
                        fontWeight: "400",
                        fontFamily: "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            format:
                                "<b>{point.name}</b>: {point.y}",
                            style: {
                                fontWeight: "400"
                            }
                        }
                    }
                },
                series: [
                    {
                        name: "Animal",
                        colorByPoint: true,
                        data: [
                            // {
                            //     name: "Dog",
                            //     y: 50,
                            //     // sliced: true,
                            //     // selected: true,
                            //     color: "#98ccaf"
                            // },
                            // {
                            //     name: "Cat",
                            //     y: 25,
                            //     color: "#4acccd"
                            // },
                        ]
                    },
                ]
            },
            //Pie Chart End MVD
            //Line Chart Start MVD
            StackedColumnChartOptions: {
                chart: {
                    type: 'column',
                    style: {
                        fontFamily: "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },
                title: {
                    text: 'Vaccinations by Gender/Age',
                    align: "left",
                    style: {
                        color: "#252422",
                        fontSize: "18px",
                        fontWeight: "400",
                        fontFamily: "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },
                xAxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec"
                    ],
                    labels: {
                        //rotation: -90,
                        formatter: function () {
                            const date = new Date();
                            const month = date.toLocaleString('default', {month: 'short'});
                            if (month == this.value) {
                                return '<span style="fill: red; font-size: 13px;">' + this.value + '</span>';
                            } else {
                                return this.value;
                            }
                        }
                    },
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    floating: false,
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || 'white',
                    shadow: false
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: [],
            },
            LineChartOptions: {
                chart: {
                    type: "column",
                    style: {
                        fontFamily: "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },
                title: {
                    text: "Vaccinations by Month",
                    align: "left",
                    style: {
                        color: "#252422",
                        fontSize: "18px",
                        fontWeight: "400",
                        fontFamily: "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },
                subtitle: {
                    text: ""
                },
                xAxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec"
                    ],
                    labels: {
                        //rotation: -90,
                        formatter: function () {
                            const date = new Date();
                            const month = date.toLocaleString('default', {month: 'short'});
                            if (month == this.value) {
                                return '<span style="fill: red; font-size: 13px;">' + this.value + '</span>';
                            } else {
                                return this.value;
                            }
                        }
                    },
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ""
                    }
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: []
            },
            //Line Chart End MVD
            //Row Line Chart Start MVD
            RowLineOptions: {
                chart: {
                    style: {
                        fontFamily: "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },
                title: {
                    text:
                        'Active Field Users, then a subtitle that says "(Past 10 Days)"',
                    align: "left",
                    style: {
                        color: "#252422",
                        fontSize: "18px",
                        fontWeight: "400",
                        fontFamily: "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },

                xAxis: {
                    tickInterval: 1,
                    type: "logarithmic"
                },

                yAxis: {
                    type: "logarithmic",
                    minorTickInterval: 0.1
                },

                tooltip: {
                    headerFormat: "<b>{series.name}</b><br />",
                    pointFormat: "x = {point.x}, y = {point.y}"
                },

                series: [
                    {
                        color: "#ed7107",
                        data: [1, 2, 4, 8, 16, 32, 64, 128, 256, 512],
                        pointStart: 1
                    }
                ]
            },
            //Row Line Chart End MVD
            //Pie Chart Start DPM
            PieChartOptionsPM: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                    style: {
                        fontFamily:
                            "Montserrat,Helvetica Neue,Arial,sans-serif",
                        fontSize: "10px"
                    }
                },
                title: {
                    text: "Animals Treated (Species)",
                    align: "left",
                    style: {
                        color: "#252422",
                        fontSize: "18px",
                        fontWeight: "400",
                        fontFamily: "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            format:
                                "<b>{point.name}</b>: {point.percentage:.1f} %",
                            style: {
                                fontWeight: "400"
                            }
                        }
                    }
                },
                series: [
                    {
                        name: "Brands",
                        colorByPoint: true,
                        data: [
                            {
                                name: "Dog",
                                y: 26.32,
                                // sliced: true,
                                // selected: true,
                                color: "#98ccaf"
                            },
                            {
                                name: "Cat",
                                y: 26.32,
                                color: "#4acccd"
                            },
                            {
                                name: "Cattels",
                                y: 19.74,
                                color: "#fcc468"
                            },
                            {
                                name: "Horse",
                                y: 13.16,
                                color: "#ed7107"
                            },
                            {
                                name: "Camel",
                                y: 14.47,
                                color: "#ffed4a"
                            }
                        ]
                    }
                ]
            },
            //Pie Chart End DPM
            //Line Chart Start DPM
            LineChartOptionsPM: {
                chart: {
                    type: "column",
                    style: {
                        fontFamily: "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },
                title: {
                    text: "Vaccinations by Month PM",
                    align: "left",
                    style: {
                        color: "#252422",
                        fontSize: "18px",
                        fontWeight: "400",
                        fontFamily: "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },
                subtitle: {
                    text: ""
                },
                xAxis: {
                    categories: [
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "10",
                        "11",
                        "12"
                    ],
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ""
                    }
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: null,
            },
            //Line Chart End DPM
            //Row Line Chart Start DPM
            RowLineOptionsPM: {
                chart: {
                    style: {
                        fontFamily: "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },
                title: {
                    text:
                        'Active Field Users, then a subtitle that says "(Past 10 Days)"',
                    align: "left",
                    style: {
                        color: "#252422",
                        fontSize: "18px",
                        fontWeight: "400",
                        fontFamily: "Montserrat,Helvetica Neue,Arial,sans-serif"
                    }
                },

                xAxis: {
                    tickInterval: 1,
                    type: "logarithmic"
                },

                yAxis: {
                    type: "logarithmic",
                    minorTickInterval: 0.1
                },

                tooltip: {
                    headerFormat: "<b>{series.name}</b><br />",
                    pointFormat: "x = {point.x}, y = {point.y}"
                },

                series: [
                    {
                        color: "#ed7107",
                        data: [1, 2, 4, 8, 16, 32, 64, 128, 256, 512],
                        pointStart: 1
                    }
                ]
            }
            //Row Line Chart End DPM
        };
    },
    methods: {
        // geolocate()
        // {
        // navigator.geolocation.getCurrentPosition(position => {
        //    this.center = {
        //       lat: position.coords.latitude,
        //       lng: position.coords.longitude
        //    };
        // });
        //this.markers.push({ position: this.center });
        // },
        // getError: function getError(fieldName) {
        //     return this.errors.first(fieldName);
        // },
        inviteUserToProject()
        {
            let vm = this;
            this.$validator.validateAll().then(result => {
                if (result) {
                    vm.invitingUser = true;
                    axios
                        .post("/project/invite", {
                            email: vm.userEmail,
                            on_project: vm.projectId,
                            with_role: vm.selectedUserRole
                        })
                        .then(response => {
                            vm.invitingUser = false;
                            console.log(response);
                            if (response.data.response.error.error_code === 0) {
                                vm.showAlert(
                                    response.data.response.error.msg,
                                    vm.userEmail,
                                    true
                                );
                                vm.project.invitations.push(
                                    response.data.response.data.invitation
                                );
                                vm.userEmail = "";
                                vm.$validator.reset();
                            } else {
                                alert(response.data.response.error.error_msg);
                            }
                        })
                        .catch(error => {
                            vm.showAlert(
                                "Sorry",
                                error.response.data.response.error.error_msg,
                                false
                            );
                            vm.invitingUser = false;
                        });
                }
            });
        },
        cancelInvitation(invitationCode, index)
        {
            let vm = this;
            swal({
                title: "Are you sure you want to cancel this invitation?",
                text: `You won't be able to revert this!`,
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Cancel Invitation",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    axios
                        .get("/project/cancel/invitation/" + invitationCode)
                        .then(response => {
                            if (response.data.response.error.error_code == 0) {
                                vm.showAlert(
                                    response.data.response.error.msg,
                                    "",
                                    true
                                );
                                vm.project.invitations.splice(index, 1);
                            } else {
                                vm.showAlert(
                                    response.data.response.error.error_msg,
                                    "",
                                    false
                                );
                            }
                        })
                        .catch(error => {
                            if (error.response.data.response.error) {
                                vm.showAlert(
                                    response.data.response.error.error_msg,
                                    "",
                                    false
                                );
                            }
                        });
                }
            });
        },
        removeUser(assignedUser, index)
        {
            let vm = this;
            swal({
                title: "Are you sure you want to remove this user?",
                text: `You won't be able to revert this!`,
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Remove",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    axios
                        .post("/project/remove_user", {
                            project_id: assignedUser.project_id,
                            user_id: assignedUser.user.id,
                            user_role: assignedUser.user_role.id
                        })
                        .then(response => {
                            if (response.data.response.error.error_code == 0) {
                                vm.showAlert(
                                    response.data.response.error.msg,
                                    "",
                                    true
                                );
                                vm.project.assigned_users.splice(index, 1);
                            } else {
                                vm.showAlert(
                                    response.data.response.error.error_msg,
                                    "",
                                    false
                                );
                            }
                        })
                        .catch(error => {
                            if (error.response.data.response.error) {
                                vm.showAlert(
                                    response.data.response.error.error_msg,
                                    "",
                                    false
                                );
                            }
                        });
                }
            });
        },
        searchUsers(query, cb)
        {
            let vm = this;
            if (query !== "") {
                axios
                    .get("user/search", {
                        params: {
                            email: query
                        }
                    })
                    .then(response => {
                        clearTimeout(vm.timeout);
                        vm.searchedUsers = response.data.response.data.users;
                        cb(vm.searchedUsers);
                    })
                    .catch(error => {
                        vm.searchedUsers = [];
                        cb(vm.searchedUsers);
                    });
            } else {
                clearTimeout(vm.timeout);
                vm.searchedUsers = [];
                cb(vm.searchedUsers);
            }
        },
        showLocationPin(google)
        {
            // Custom Skin & Settings Map
            let latLng = this.project.project_location_lat_lng.split(",");
            const myLatlng = new google.maps.LatLng(latLng[0], latLng[1]);
            const mapOptions = {
                zoom: 7,
                center: myLatlng,
                scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
                disableDefaultUI: true, // a way to quickly hide all controls
                zoomControl: false,
                draggable: false,
                disableDoubleClickZoom: true
            };

            const map = new google.maps.Map(
                document.getElementById("projectLocation"),
                mapOptions
            );
            const marker = new google.maps.Marker({
                position: myLatlng,
                title: "Custom Skin & Settings Map!"
            });
            marker.setMap(map);
        },
        deleteProject(projectId)
        {
            swal({
                title: "Are you sure you want to delete this project?",
                text: `You won't be able to revert this!`,
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Delete Project",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    axios
                        .delete("project/delete/" + projectId)
                        .then(response => {
                            if (response.data.response.error.error_code == 0) {
                                window.location = "/projects";
                            }
                        })
                        .catch(error => {
                            alert(
                                "something went wrong while deleting project"
                            );
                        });
                }
            });
        },
        addVaccinationArea()
        {
            window.location = "/project/vaccination_area/add/" + this.projectId;
        },
        assignUsersOnArea()
        {
            window.location = "/project/assign_area_users/" + this.projectId;
        },
        outerVaccinations()
        {
            window.location = "/area/outer_vaccinations/" + this.projectId;
        },
        updateSurveyPercentage()
        {
            let vm = this;
            vm.isUpdatingSurveyPercentage = true;
            axios
                .put("/project/update/survey_percentage/" + this.projectId, {
                    survey_percentage: vm.project.survey_percentage
                })
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.showAlert(
                            "Updated",
                            response.data.response.error.msg,
                            true
                        );
                    }
                    vm.isUpdatingSurveyPercentage = false;
                })
                .catch(error => {
                    vm.showAlert(
                        "Error",
                        error.response.data.response.error.error_msg,
                        false
                    );
                    vm.isUpdatingSurveyPercentage = false;
                });
        },
        markersclick(marker, idx)
        {
            // this.infoWindowPos = marker.postion;
            // this.infoContent = marker.icon;
            // if (this.currentMidx == idx) {
            //   this.infoWinOpen = !this.infoWinOpen;
            // } else {
            //   this.infoWinOpen = true;
            //   this.currentMidx = idx;
            // }
        },
        // getAllKml(areaArr) {
        //     const vm = this;
        //     GoogleMapsLoader.load(google => {
        //         var map = new google.maps.Map(document.getElementById("map"), {
        //             center: vm.center
        //             //disableDefaultUI:true,
        //         });
        //         for (var i = 0; i < areaArr.length; i++) {
        //             var file_url = areaArr[i].file_url;
        //             var layers = [];
        //             layers[i] = new google.maps.KmlLayer({
        //                 url: file_url,
        //                 suppressInfoWindows: true,
        //                 map: map
        //             });
        //         }
        //         this.mvPinpoint(this.center, map);
        //         /*setTimeout(function(){
        //         },2500);*/
        //     });
        // },
        drawAreasFromKML()
        {
            const vm = this;
            const KMLurls = vm.projectOverview.areas_kml;
            GoogleMapsLoader.load(google => {
                var map = new google.maps.Map(document.getElementById("map"), {
                    zoom: 7,
                    center: vm.center,
                    styles: MAP_STYLE,
                    streetViewControl: false,
                    mapTypeControl: false
                });

                KMLurls.forEach(url => {
                    //console.log("url", url);
                    new google.maps.KmlLayer({
                        url: url,
                        suppressInfoWindows: true,
                        preserveViewport: true,
                        map: map,
                    });
                });

                vm.mvPinpoint(vm.center, map);
                vm.plotVaccinationPoints(map);

            });
        },
        mvPinpoint(location, map)
        {
            var vm = this;
            // let latLng = location.split(',');
            // const myLatlng = new google.maps.LatLng(latLng[0], latLng[1]);
            const marker = new google.maps.Marker({
                position: location,
                //icon: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                //label: "Project Location"
            });

            vm.mvmarkers.push(marker);
            marker.setMap(map);
        },
        plotVaccinationPoints(map)
        {
            let vm = this;
            let pinPoints = vm.projectOverview.vaccination_pins;

            for (let i = 0; i < pinPoints.length; i++) {
                let latLng = pinPoints[i].split(",");
                latLng = {lat: +latLng[0], lng: +latLng[1]};
                const marker = new google.maps.Marker({
                    position: latLng,
                    //icon: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                    //label: "Project Location"
                });
                vm.mvmarkers.push(marker);
                marker.setMap(map);
            }

        },
        setPieChart(data)
        {
            const pieData = [...data];
            // pieData.forEach(el => {
            //     el.color = '#' + el.color;
            // })
            this.PieChartOptions.series[0].data = pieData;
        },
        setLineChart(data)
        {
            // pieData.forEach(el => {
            //     el.color = '#' + el.color;
            // })
            this.LineChartOptions.series = [...data];
        },
        setStackedColumnChart(data)
        {
            this.StackedColumnChartOptions.series = [...data];
        }
    },
    computed: {
        //...mapGetters("user", ["userRoles"]),
        ...mapGetters("user", ["userRoles", "selectedProjectId"]),
        apiError: function () {
            return this.errorMsg !== null;
        },
        projectCategories: function () {
            if (this.project.category != null) {
                return this.project.category.split(",");
            } else {
                return [];
            }
        }
    },
    async mounted()
    {
        // this.geolocate();
        let vm = this;
        vm.$store.dispatch("user/setSelectedProjectId", vm.projectId);
        let response;
        try {
            response = await axios.get(
                "/stats/mv/project/dashboard/" + vm.projectId
            );
            vm.projectOverview = response.data.response.data;
            vm.setPieChart(vm.projectOverview.charting_details.animals_treated)
            vm.setLineChart(vm.projectOverview.charting_details.monthly_treated)
            vm.setStackedColumnChart(vm.projectOverview.charting_details.monthly_treated_by_gender_and_age)
            localStorage.project = JSON.stringify(vm.projectOverview.project_details);
            Bus.$emit("projectype", vm.projectOverview.project_details);
            vm.$store.dispatch("user/getUserRoles");

            const latLngArr = vm.projectOverview.project_location.split(",");
            vm.center = {lat: +latLngArr[0], lng: +latLngArr[1]};

            vm.drawAreasFromKML();
            // console.log("response", response.data.response.data);
        } catch (error) {
        }
        axios
            .get("/project/details", {
                params: {
                    //project_id: this.projectId
                    project_id: vm.projectId,
                }
            })
            .then(response => {
                vm.project = response.data.response.data.project;
                localStorage.project = JSON.stringify(vm.project);
                vm.callingApi = false;
                vm.projectOwnerName = vm.project.owner.first_name + " " + vm.project.owner.last_name;
                Bus.$emit("projectype", vm.project);
                this.$store.dispatch("user/getUserRoles");

                var location = vm.project.project_location_lat_lng;
                var latlang = location.split(",");
                var latLngObj = {lat: +latlang[0], lng: +latlang[1]};
                vm.center = latLngObj;

                // GoogleMapsLoader.load(google => {
                //   vm.showLocationPin(google);
                // });
                // if (vm.project.type == "MV") {
                // vm.getAllKml(vm.project.areas);
                // } else {
                //     GoogleMapsLoader.load(google => {
                //         vm.showLocationPin(google);
                //     });
                // }
            })
            .catch(error => {
                console.log(error.response.data.response);
                let errors = [];
                if (error.response.data.response.data !== null) {
                    for (let key in error.response.data.response.data) {
                        console.log("Key: " + key);
                        console.log("Value: " + error.response.data.response.data[key]);
                        errors.push(error.response.data.response.data[key]);
                    }
                    vm.errorMsg = errors.toString();
                } else {
                    vm.errorMsg = error.response.data.response.error.error_msg;
                    console.log(vm.errorMsg);
                }
                vm.callingApi = false;
            });
        // Pradeep Start
        // check organisation verified
        if (this.organisation == 2) {
            this.invitingUser = true;
        }
        // Pradeep End
        try {
            setTimeout(function () {
                document
                    .querySelectorAll(".highcharts-credits")
                    .forEach(function (el) {
                        el.innerHTML = "";
                    });
                document.querySelectorAll("tspan").forEach(function (element) {
                    element.style.fontWeight = "400";
                });
            }, 2000);
        } catch (e) {
        }
        console.log("this", this._data);
    }
};
</script>
<style scoped>
td,
th {
    padding-right: 10px;
}

.card .numbers p {
    font-size: 14px !important;
    color: #9a9a9a !important;
}

.card .number {
    /* font-size: 14px !important; */
    color: #9a9a9a !important;
    font-size: 1.5em;
    text-align: right;
}

.card .number p {
    margin: 0;
    font-size: 13px;
}

.setShadow {
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
}

.card .card-body {
    text-align: center;
}

.containBytitile h4 {
    font-size: 1.2em;
    line-height: 1.45em;
}

.projectdetail-desktop {
    margin-left: 20px !important;
    cursor: pointer;
}

.Set-floatright {
    float: right;
}

.setTitleDiv p {
    font-size: 15px;
    font-weight: 600;
    color: black;
}

.set_mvd_res_chart {
    height: 338px;
}

.img_marker_popup {
    /* max-width: 100% !important; */
    height: 70px;
    width: 70px;
}

.info_window_row {
    margin-right: -5px !important;
    margin-left: -5px !important;
}

@media only screen and (max-width: 640px) {
    .projectdetail-desktop {
        float: left;
        margin-top: -36px;
        margin-left: 247px;
        cursor: pointer;
    }
}

@media only screen and (max-device-width: 768px) {
    .set_mvd_res_chart {
        height: 50%;
    }
}

@media (min-width: 780px) {
    .mvd_details_class {
        height: 407px;
    }

    .set_res_activity_status {
        height: 600px;
    }
}

.set_b_font {
    font-size: 16px !important;
    color: #9a9a9a !important;
}

.set_b_font_card {
    font-size: 17px !important;
    color: #9a9a9a !important;
}

.text_left {
    text-align: left !important;
}

.set_numbers_div {
    padding: 5px 15px 10px;
    text-align: center;
}

.set_card_Operated {
    height: 124px;
}

.set_card_deaths {
    height: 150px;
}

@media (min-width: 780px) {
    .set_card_height_avg {
        height: 142px;
    }
}

.set_card_caught {
    margin-bottom: -27px;
}

.owner-name {
    font-size: 14px !important;
}
</style>
