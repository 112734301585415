<template>
    <div>
        <!-- pradeep -->
        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <h4 class="set_msg set_font_title">Project Settings</h4>
        <!-- End -->
        <hr>
        <!-- Pradeep Start -->
        <!-- set responsive -->
        <div class="row">
            <div class="col-md-12">

                <div class="col-sm-12">
                    <div class="row">
                        <label class="col-sm-12 control-label">Project Type</label>
                        <div class="col-sm-12">

                            <div v-if="model.activeTab=='MV'">
                                <img :src="mvlogo" class="logo_img"/>
                                <span>Mass Vaccination</span>
                            </div>

                            <!--<p-radio inline v-model="model.activeTab" value="Clinic" label="Clinic">Clinic</p-radio>-->
                        </div>
                    </div>
                </div>

                <br/>

                <fieldset>
                    <div class="form-group">
                        <div class="col-sm-12 col-md-4">
                            <fg-input
                                    type="text"
                                    label="Project Name*"
                                    name="projectName"
                                    v-validate="modelValidations.projectName"
                                    :error="getError('projectName')"
                                    v-model="model.projectName">
                            </fg-input>
                        </div>
                    </div>
                </fieldset>

                <br/>

                <fieldset>
                    <div class="form-group">
                        <label class="col-sm-12 control-label">Project Description</label>
                        <div class="col-sm-8">
                                        <textarea v-model="model.projectDescription" required class="form-control"
                                                  rows="3"></textarea>
                        </div>
                    </div>
                </fieldset>

                <br/>

                <fieldset>
                    <div class="form-group">
                        <label class="col-sm-12 control-label">Banner Message</label>
                        <div class="col-sm-8">
                            <textarea
                                    placeholder="This message will appear on the top of the project overview screen. You can use it for reminders or notes to yourself or other users."
                                    v-model="model.projectBannerMessage" required class="form-control"
                                    rows="3"></textarea>
                        </div>
                    </div>
                </fieldset>

                <br/>

                <div class="col-sm-12">
                    <div class="row">
                        <label class="col-sm-12 control-label">Visibility</label>
                        <div class="col-sm-2">
                            <p-radio v-model="model.projectAssociate" :disabled="!!responseProjectDetails.organisation"
                                     label="1">Private
                            </p-radio>
                            <p-radio v-model="model.projectAssociate" label="2">Organisation</p-radio>
                        </div>
                        <div class="col-sm-5">
                            <p>{{ projectAssociateInfo }}</p>
                        </div>
                        <template v-if="model.projectAssociate=='2' && userOrganisations.length>0">
                            <div class="col-sm-7">
                                <el-select class="select-info"
                                           size="large"
                                           @change="getOrgAnimalsAndDrugs"
                                           placeholder="Select Organisation"
                                           v-model="model.selectedOrganisation">
                                    <el-option v-for="org in userOrganisations"
                                               class="select-danger"
                                               :value="org.organisation.id"
                                               :label="org.organisation.name"
                                               :key="org.organisation.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </template>
                        <template v-if="model.projectAssociate=='2' && userOrganisations.length==0">
                            <div class="col-sm-4 text-center">
                                <a href="/settings/organisation">
                                    <button style="margin: 0;" type="button" class="btn btn-round btn-default">
                                        Apply for Organisation
                                    </button>
                                </a>
                                <br/>
                                <br/>
                                <a style="margin: 10px;" target="_blank" href="http://test.hsiapps.org/">
                                    List of Organisations
                                </a>
                            </div>
                        </template>
                    </div>
                </div>

                <br/>

                <template v-if="model.projectAssociate=='2' && userOrganisations.length>0">
                    <fieldset>
                        <div class="form-group">
                            <label class="col-sm-12 control-label">Species</label>
                            <div class="col-sm-12">
                                <el-checkbox-group v-model="model.selectedAnimals">
                                    <template v-for="(animal, key) in orgAnimals">
                                        <el-checkbox :label="animal.species_name" :key="'defaultAnimal' + key">
                                            {{ animal.species_name }}
                                        </el-checkbox>
                                    </template>
                                    <template v-for="(species, index) in allSpeciesName">
                                        <el-checkbox :label="species" :key="'recentlyAddedAnimal' + index">
                                            {{ species }}
                                        </el-checkbox>
                                        <i :label="species" :key="'recentlyAddedAnimalIcon' + index"
                                           class="nc-icon nc-simple-remove"
                                           style="font-size: 15px; padding: 5px; cursor: pointer; color: #51cbce"
                                           @click="removeFromAllSpeciesName(index, species)"></i>
                                    </template>
                                </el-checkbox-group>
                            </div>
                        </div>
                    </fieldset>
                    <div ref="addNewAnimalDialogBox" class="col-sm-4">
                        <template>
                            <a @click="addNewAnimal" style="color: #51cbce;"><b>{{ getAddAnimalLabel }}</b></a>
                        </template>
                        <template v-if="addingNewAnimal">
                            <fg-input
                                    label="Species Name"
                                    ref="speciesNameInput"
                                    type="text"
                                    name="Animal Name"
                                    v-model="animalName"
                                    v-on:keypress="isCharAllow($event)"
                            >
                            </fg-input>
                            <p>(Note: This will make new species type visible to entire organisation)</p>
                            <div class="row">
                                <div class="col-sm-12 text-right">
                                    <button type="button"
                                            name="Delete Project" v-on:click="addNewAnimal"
                                            class="btn btn-round btn-danger">Cancel
                                    </button>

                                    <button v-on:click="callAddNewAnimalApi" type="button"
                                            class="btn btn-round btn-info">Add
                                    </button>
                                </div>
                            </div>

                        </template>
                    </div>
                </template>
                <template v-else>
                    <fieldset>
                        <div class="form-group">
                            <label class="col-sm-12 control-label">Species</label>
                            <div class="col-sm-12">
                                <el-checkbox-group v-model="model.selectedAnimals">
                                    <template v-for="animal in animals">
                                        <el-checkbox :label="animal.species_name">{{ animal.species_name }}
                                        </el-checkbox>
                                    </template>
                                </el-checkbox-group>
                            </div>
                        </div>
                    </fieldset>
                </template>
                <br/>

                <fieldset>
                    <div class="form-group">
                        <label class="col-sm-12 control-label">Ownership</label>

                        <div class="col-sm-12">
                            <el-checkbox-group v-model="model.selectedCategories">
                                <template v-for="ownership in ownerships">
                                    <el-checkbox :label="ownership">{{ ownership }}</el-checkbox>
                                </template>
                            </el-checkbox-group>
                        </div>

                    </div>
                </fieldset>

                <br/>

                <fieldset>
                    <div class="form-group">
                        <label class="col-sm-12 control-label">Vaccine / Drug</label>
                        <div class="col-sm-10">
                            <!-- <div style="float: left; margin-right: 20px;" v-for="vaccination in vaccinationDrug" :key="vaccination.id">
                                <p-radio :label="vaccination.vaccination_drug"
                                        v-model="model.vaccinationDrug">{{vaccination.vaccination_drug}}
                                </p-radio>
                                </div>
                                <div style="float: left; margin-right: 20px;" v-for="(vaccineDrugName, index) in allVaccinationDrugName" :key="vaccineDrugName">
                                        <p-radio :label="vaccineDrugName" v-model="model.vaccinationDrug" :key="'recentlyAddedVaccinationDrug' + index">{{vaccineDrugName}} <i :label="vaccineDrugName" :key="'recentlyAddedVaccinationDrugIcon' + index" class="nc-icon nc-simple-remove" style="font-size: 15px; padding: 5px; cursor: pointer; color: #51cbce" @click="removeFromAllVaccinationDrugName(index, vaccineDrugName)"></i></p-radio>
                                </div> -->
                            <el-checkbox-group v-model="model.vaccinationDrug" :max="1">
                                <template v-for="(vaccination, key) in vaccinationDrug">
                                    <el-checkbox :label="vaccination.id" :key="'defaultVaccine' + key">
                                        {{ vaccination.vaccination_drug }}
                                    </el-checkbox>
                                </template>
                            </el-checkbox-group>
                            <el-checkbox-group v-model="model.vaccinationDrug" :max="1">
                                <template v-for="(vaccineDrugName, index) in allVaccinationDrugName">
                                    <el-checkbox :label="vaccineDrugName" :key="'recentlyAddedVaccine' + index">
                                        {{ vaccineDrugName }}
                                    </el-checkbox>
                                    <i :label="vaccineDrugName" :key="'recentlyAddedVaccineIcon' + index"
                                       class="nc-icon nc-simple-remove"
                                       style="font-size: 15px; padding: 5px; cursor: pointer; color: #51cbce"
                                       @click="removeFromAllVaccinationDrugName(index, vaccineDrugName)"></i>
                                </template>
                            </el-checkbox-group>
                        </div>
                    </div>
                </fieldset>
                <template v-if="model.projectAssociate=='2' && userOrganisations.length>0">

                    <div ref="addDrugVaccineDialogBox" class="col-sm-4">
                        <template>
                            <a @click="addVaccineDrug" style="color: #51cbce;"><b>{{ getAddVaccineLabel }}</b></a>
                        </template>
                        <template v-if="addingNewVaccine">
                            <fg-input
                                    label="Vaccine/Drug Name"
                                    type="text"
                                    v-model="vaccineName">
                            </fg-input>
                            <p>(Note: This will make new Drug/Vaccine visible to entire organisation)</p>
                            <div class="row">
                                <div class="col-sm-12 text-right">
                                    <button v-on:click="addVaccineDrug" type="button"
                                            class="btn btn-round btn-danger">Cancel
                                    </button>

                                    <button v-on:click="callAddVaccineApi" type="button"
                                            class="btn btn-round btn-info">Add
                                    </button>
                                </div>
                            </div>
                        </template>
                    </div>

                </template>
                <br/>


                <fieldset>
                    <div class="form-group">
                        <label class="col-sm-12 control-label">Vaccination Survey Percentage
                            <br>(The target percentage to consider vaccination efforts a success.)</label>
                        <div class="col-sm-6 col-lg-2 col-md-4">
                            <fg-input v-model="model.surveyPercentage"
                                      type="text"
                                      name="Survey Percentage"
                                      v-validate="modelValidations.surveyPercentage"
                                      :error="getError('Survey Percentage')"
                                      placeholder="Set Survey Percentage..."></fg-input>
                        </div>
                    </div>
                </fieldset>

                <br/>

                <div class="col-lg-12">
                    <div class="row">

                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <p class="card-title">Project Location</p>
                                </div>
                                <div class="card-body">
                                    <!--<div class="pac-card" id="pac-card">
                                        <div>
                                            <div id="title">
                                                Search Locations
                                            </div>
                                            <div id="type-selector" class="pac-controls">
                                                <input type="radio" name="type" id="changetype-all"
                                                       checked="checked">
                                                <label for="changetype-all">All</label>

                                                <input type="radio" name="type"
                                                       id="changetype-establishment">
                                                <label for="changetype-establishment">Establishments</label>

                                                <input type="radio" name="type" id="changetype-address">
                                                <label for="changetype-address">Addresses</label>
                                            </div>
                                        </div>
                                        <div id="pac-container">
                                            <input id="pac-input" type="text"
                                                   placeholder="Enter a location">
                                        </div>
                                    </div>-->
                                    <div id="regularMap" class="map"></div>
                                    <!--<div id="infowindow-content">
                                        <img src="" width="16" height="16" id="place-icon">
                                        <span id="place-name" class="title"></span><br>
                                        <span id="place-address"></span>
                                    </div>-->
                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <p>
                                To load Country, State and Province please select location pin point on Google Maps.
                            </p>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-lg-4">
                            <label class="control-label">Country</label>
                            <br/>
                            <el-select
                                    :disabled="fetchingAddress"
                                    class="select-info"
                                    size="large"
                                    :placeholder="selectCountryLabel"
                                    v-model="model.selectedCountry">
                                <el-option v-for="country in googleCountries"
                                           class="select-danger"
                                           :value="country"
                                           :label="country"
                                           :key="country">
                                </el-option>
                            </el-select>
                        </div>


                        <div class="col-lg-4">
                            <label class="control-label">State/Province</label>
                            <br/>
                            <el-select
                                    :disabled="fetchingAddress"
                                    class="select-info"
                                    size="large"
                                    :placeholder="selectStateLabel"
                                    v-model="model.selectedState">
                                <el-option v-for="state in googleStates"
                                           class="select-danger"
                                           :value="state"
                                           :label="state"
                                           :key="state">
                                </el-option>
                            </el-select>
                        </div>


                        <div class="col-lg-4">
                            <label class="control-label">City/District/Village</label>
                            <br/>
                            <el-select
                                    :disabled="fetchingAddress"
                                    class="select-info"
                                    size="large"
                                    :placeholder="selectLocalityLabel"
                                    v-model="model.selectedLocality">
                                <el-option v-for="locality in googleLocality"
                                           class="select-danger"
                                           :value="locality"
                                           :label="locality"
                                           :key="locality">
                                </el-option>
                            </el-select>
                        </div>

                    </div>

                </div>

                <br/>

                <label class="col-sm-12 control-label">Project Date Format</label>
                <div class="col-lg-3 col-md-5">
                    <el-select class="select-info"
                               size="large"
                               placeholder="Single Select"
                               v-model="model.selectedDateFormat">
                        <el-option v-for="dateFormat in model.dateFormats"
                                   class="select-danger"
                                   :value="dateFormat"
                                   :label="dateFormat"
                                   :key="dateFormat">
                        </el-option>
                    </el-select>
                </div>

                <br/>

                <label class="col-sm-12 control-label">Project Time Zone</label>
                <div class="col-lg-5">
                    <el-select class="select-info"
                               style="display: block;"
                               filterable
                               placeholder="Single Select"
                               v-model="model.selectedTimezone">
                        <el-option v-for="timezone in timezones"
                                   class="select-danger"
                                   :value="timezone.label"
                                   :label="timezone.label"
                                   :key="timezone.label">
                        </el-option>
                    </el-select>
                </div>

                <br/>

                <div class="col-sm-10">
                    <p>Change Project Image ({{ uploadingLabel }})</p>
                    <image-upload
                            :target="imageUploadApiUrl"
                            selector="example"
                            :auto-push="true">
                    </image-upload>
                    <br/>
                </div>

            </div>
            <div class="col-md-1"></div>
            <div class="col-md-12" style="text-align: center !important;">
                <button v-if="onLine" v-bind:disabled="isDisabled" v-on:click="validateAndSubmit" type="button"
                        class="btn btn-round btn-info">{{ getCreateUpdateLabel }}
                </button>
                <button v-else v-bind:disabled="true" v-on:click="validateAndSubmit" type="button"
                        class="btn btn-round btn-info">{{ getCreateUpdateLabel }}
                </button>
                <!--pradeep -->
                <button @click="showSwalByDelete('warning-message-and-confirmation')" type="button"
                        class="btn btn-round btn-danger">cancel
                </button>
                <!--End -->
            </div>

        </div>

    </div>
    <!--    Pradeep End   -->
</template>

<script>

import mixin from '../../mixins/GlobalMixin';

import {mapGetters} from 'vuex';

import {Card, Button, InfoSection, Tabs, TabPane} from '../../components/Template/UIComponents';

import ImageUpload from '../ImageUpload.vue';

import {Select, Option, CheckboxGroup, Checkbox} from 'element-ui';

import {API_KEY} from '../Template/Maps/API_KEY';
import GoogleMapsLoader from 'google-maps';
//pradeep
import swal from 'sweetalert2';
//End
GoogleMapsLoader.KEY = API_KEY;
//GoogleMapsLoader.LIBRARIES = ['places'];

import mvlogo from '../../assets/images/mv-logo.png';
import VOffline from 'v-offline';

import timezoneJson from '../../assets/json/timezones.json'

export default {
    components: {
        TabPane,
        Tabs,
        Card,
        Button,
        InfoSection,
        [Option.name]: Option,
        [Select.name]: Select,
        [CheckboxGroup.name]: CheckboxGroup,
        [Checkbox.name]: Checkbox,
        'image-upload': ImageUpload,
        VOffline
    },
    mixins: [mixin],
    props: {
        projectId: {
            type: Number,
        },
        timezones: {
            type: Array,
            default: timezoneJson
        },
        mvlogo: {
            type: String,
            default: mvlogo,
        },
    },
    data()
    {
        return {
            imageUploadApiUrl: window.API_BASE_URL + '/UploadToS3/upload/project_image',
            animals: [
                {
                    id: 1,
                    species_name: "Dog"
                }
            ],
            ownerships: [
                "Owned",
                "Owned free roaming",
                "Community",
                "Community free roaming",
                "Stray",
                "Wild"
            ],
            vaccinationDrug: [
                {
                    id: 0,
                    vaccination_drug: 'Rabies'
                },
            ],
            orgAnimals: [],
            model: {
                projectName: '',
                projectDescription: '',
                selectedAnimals: [],
                dateFormats: ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD'],
                selectedDateFormat: 'DD-MM-YYYY',
                selectedTimezone: 'UTC',
                activeTab: 'MV',
                projectAssociate: '1',
                selectedCategories: [],
                selectedOrganisation: null,
                selectedCountry: null,
                selectedState: null,
                selectedLocality: null,
                selectedLocation: null,
                vaccinationDrug: [],
                surveyPercentage: 70,
                projectBannerMessage: '',
            },
            modelValidations: {
                projectName: {
                    required: true,
                },
                animalType: {
                    required: true,
                },
                animalName: {
                    required: true,
                },
                surveyPercentage: {
                    required: true,
                }
            },
            isDisabled: false,
            //uploadURL: 'http://www.hsi.org/assets/images/logos/hsi_global.png',
            uploadURL: '',
            uploadingLabel: 'Optional',
            uploadPending: false,
            googleCountries: [],
            googleStates: [],
            googleLocality: [],
            fetchingAddress: true,
            selectCountryLabel: 'Select Country',
            selectStateLabel: 'Select State',
            selectLocalityLabel: 'Select Locality',
            animalName: '',
            animalType: '',
            addingNewAnimal: false,
            addingNewVaccine: false,
            vaccineName: '',
            map: null,
            selectedMarker: null,
            allSpeciesName: [],
            allVaccinationDrugName: [],
            responseProjectDetails: {},
        }
    },

    watch: {
        'model.projectAssociate': function (newVal, oldVal) {
            console.log('newVal', newVal, 'oldVal', oldVal);
            if (newVal === '2') {
                // this.model.selectedAnimals = []
                // this.model.vaccinationDrug = []
                this.getOrgAnimalsAndDrugs()
            } else {
                this.model.selectedAnimals = this.responseProjectDetails.project_animals.map(el => el.animal.species_name)
                this.model.vaccinationDrug = JSON.parse(this.responseProjectDetails.vaccination_drug_ids)
            }
        }
    },

    methods: {

        init()
        {

            let vm = this;

            vm.$store.dispatch('animal/getAnimals');

            //vm.$store.dispatch('project/vaccinationDrug');

            vm.$store.dispatch('user/getUserOrganisations');

            Bus.$on('example-did-upload', (error, data, response) => {
                if (error) {
                    toastr.error(error);
                } else {
                    vm.uploadURL = response.src;
                    vm.uploadingLabel = "Uploaded";
                    vm.uploadPending = false;
                }
            });

            Bus.$on('example-is-saving', () => {
                vm.uploadPending = true;
                vm.uploadingLabel = "Uploading Image...";
            });

            // GoogleMapsLoader.load((google) => {
            //     vm.initRegularMap(google)
            // });

            if (vm.projectId > 0) {
                vm.getProjectDetails();
            }

        },
        initRegularMap(google)
        {
            let vm = this;

            // Regular Map
            const myLatlng = new window.google.maps.LatLng(20.0532212, 64.4407944);
            const mapOptions = {
                zoom: 4,
                center: myLatlng, // we disable de scroll over the map, it is a really annoing when you scroll through page
            };

            vm.map = new window.google.maps.Map(document.getElementById('regularMap'), mapOptions);
            let map = vm.map;

        },
        validateAndSubmit: function (event) {

            if (this.uploadPending) {
                this.showAlert('Error', "Please wait image is being uploaded", false);
                return;
            }

            this.isDisabled = true;

            this.$validator.validateAll().then((result) => {
                let vm = this;
                if (this.uploadURL != '') {
                    this.uploadURL = this.uploadURL;
                } else
                    if (this.model.activeTab == 'MV') {
                        this.uploadURL = this.mvlogo;
                    }
                if (result) {
                    if (this.model.selectedAnimals.length === 0) {
                        this.isDisabled = false;
                        this.showAlert('Error', "Please select one or more species for this project.", false);
                        return;
                    } else
                        if (this.model.vaccinationDrug.length === 0) {
                            this.isDisabled = false;
                            this.showAlert('Error', "Please select one or more Vaccine / Drug for this project.", false);
                            return;
                        } else {
                            axios.put('project/update/' + this.projectId, {
                                name: this.model.projectName,
                                description: this.model.projectDescription,
                                timezone: this.model.selectedTimezone,
                                project_animals: this.model.selectedAnimals,
                                date_format: this.model.selectedDateFormat,
                                type: this.model.activeTab,
                                image_url: this.uploadURL,
                                location: this.model.selectedLocation,
                                country: this.model.selectedCountry,
                                google_admin_area_level1: this.model.selectedState,
                                google_locality: this.model.selectedLocality,
                                organisation_id: this.model.selectedOrganisation,
                                // vaccination_drug: this.model.vaccinationDrug,
                                vaccination_drug_ids: this.model.vaccinationDrug,
                                // vaccination_drug_ids: JSON.stringify(this.model.vaccinationDrug),
                                category: this.model.selectedCategories.toString(),
                                survey_percentage: this.model.surveyPercentage,
                                banner_message: this.model.projectBannerMessage,
                            }).then(response => {
                                if (response.data.response.error.error_code == 0) {
                                    vm.showAlert('Success', response.data.response.error.msg, true);
                                }
                            }).catch(error => {
                                if (error.response.data.response.error.error_code == 1) {
                                    vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                                }
                            })
                        }
                }
                this.isDisabled = false;
                return false;
            }).catch(() => {
                this.isDisabled = false;
                return false
            });
        },
        getError: function getError(fieldName) {
            return this.errors.first(fieldName);
        },
        addNewAnimal()
        {
            this.animalName = ''
            this.addingNewAnimal = !this.addingNewAnimal;
            if (this.addingNewAnimal) {
                this.$refs.addNewAnimalDialogBox.style = 'padding: 10px; box-shadow: 10px 10px 25px 0px #888888; border-radius: 5px;';
            } else {
                this.$refs.addNewAnimalDialogBox.style = '';
            }
        },
        callAddNewAnimalApi(event)
        {
            let vm = this;
            if (vm.animalName != '' && vm.model.selectedOrganisation != null) {
                // vm.addingNewAnimal = false;
                this.tempAddSpecies()
                // axios.post('animal/add/org', {
                //     species_name: vm.animalName,
                //     organisation_id: vm.model.selectedOrganisation,
                // }).then(response => {
                //     if (response.data.response.error.error_code == 0) {
                //         vm.animalName = '';
                //         vm.showAlert('Success', response.data.response.error.msg, true);
                //         vm.getOrgAnimals();
                //     }
                // }).catch(error => {
                //     vm.showAlert('Sorry', error.response.data.response.error.error_msg, false);
                //     vm.animalName = '';
                // });
            } else {
                vm.showAlert('Error', "please enter species name and select organisation", false);
            }
        },
        addVaccineDrug()
        {
            this.vaccineName = ''
            this.addingNewVaccine = !this.addingNewVaccine;
            if (this.addingNewVaccine) {
                this.$refs.addDrugVaccineDialogBox.style = 'padding: 10px; box-shadow: 10px 10px 25px 0px #888888; border-radius: 5px;';
            } else {
                this.$refs.addDrugVaccineDialogBox.style = '';
            }
        },
        callAddVaccineApi(event)
        {
            let vm = this;

            if (vm.vaccineName != '' && vm.model.selectedOrganisation != null) {
                this.tempAddVaccinationDrug()
                //     axios.post('/vaccination_drug/add', {
                //         drug_name: vm.vaccineName,
                //         organisation_id: vm.model.selectedOrganisation,
                //     }).then(response => {

                //         console.log(response);

                //         if (response.data.response.error.error_code == 0) {
                //             vm.vaccineName = '';
                //             vm.getOrgVaccinations();
                //         }
                //         vm.addingNewVaccine = false;


                //     }).catch(error => {
                //         vm.showAlert('Sorry', JSON.stringify(error), false);
                //         vm.vaccineName = '';
                //         vm.addingNewVaccine = false;
                //     });

            } else {
                vm.showAlert('Error', "Please enter vaccine/drug name and select organisation", false);
            }


        },
        getOrgVaccinations()
        {

            let vm = this;

            if (vm.model.selectedOrganisation != null) {

                axios.get('/vaccination_drug/get_all/' + vm.model.selectedOrganisation)
                    .then(response => {

                        if (response.data.response.error.error_code == 0) {

                            let vaccinationDrugs = response.data.response.data.vaccination_drugs;
                            vm.vaccinationDrug = [];
                            for (let i = 0; i < vaccinationDrugs.length; i++) {

                                let vaccine = {
                                    id: vaccinationDrugs[i].id,
                                    vaccination_drug: vaccinationDrugs[i].drug_name,
                                };
                                vm.vaccinationDrug.push(vaccine);

                            }


                        }

                    })
                    .catch(error => {


                    });

            }


        },
        getProjectDetails()
        {
            let vm = this;
            axios.get('project/details', {
                params: {
                    project_id: vm.projectId,
                }
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    let data = response.data.response.data;
                    this.responseProjectDetails = data.project
                    vm.model.activeTab = data.project.type;
                    vm.model.projectName = data.project.name;
                    vm.model.projectDescription = data.project.description;
                    vm.model.projectBannerMessage = data.project.banner_message;
                    vm.uploadURL = data.project.image_url;

                    vm.model.vaccinationDrug = JSON.parse(data.project.vaccination_drug_ids);

                    vm.model.selectedCountry = data.project.google_country;
                    vm.model.selectedState = data.project.google_admin_area_level1;
                    vm.model.selectedLocality = data.project.google_locality;
                    vm.model.selectedTimezone = data.project.timezone;
                    vm.model.selectedDateFormat = data.project.date_format;
                    vm.model.surveyPercentage = data.project.survey_percentage;

                    if (data.project.organisation) {
                        vm.model.projectAssociate = '2';
                        vm.model.selectedOrganisation = data.project.organisation.id;
                        vm.getOrgAnimalsAndDrugs();
                    }
                    for (let i = 0; i < data.project.project_animals.length; i++) {
                        vm.model.selectedAnimals.push(data.project.project_animals[i].animal.species_name);
                    }
                    let categories = data.project.category.split(',');
                    for (let i = 0; i < categories.length; i++) {
                        vm.model.selectedCategories.push(categories[i]);
                    }

                    vm.model.selectedLocation = data.project.project_location_lat_lng;
                    let latLng = data.project.project_location_lat_lng.split(',');
                    // let location = new google.maps.LatLng(parseFloat(latLng[0]), parseFloat(latLng[1]));
                    // vm.selectedMarker = new google.maps.Marker({
                    //     position: location,
                    //     map: vm.map
                    // });
                    // vm.map.setZoom(17);
                    // vm.map.panTo(vm.selectedMarker.position);
                    GoogleMapsLoader.load((google) => {
                        //vm.initRegularMap(google)
                        var myLatlng = new google.maps.LatLng(parseFloat(latLng[0]), parseFloat(latLng[1]));
                        var mapOptions = {
                            zoom: 14,
                            center: myLatlng
                        }
                        var maps = new google.maps.Map(document.getElementById("regularMap"), mapOptions);

                        var marker = new google.maps.Marker({
                            position: myLatlng
                        });

                        // To add the marker to the map, call setMap();
                        marker.setMap(maps);
                    });
                }
            }).catch(error => {
                console.log(error);
            })
        },

        //Pradeep
        showSwalByDelete(type)
        {
            swal({
                title: 'Are you sure?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Yes,cancel it!',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    swal({
                        title: 'Cancelled!',
                        text: '',
                        type: 'success',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        buttonsStyling: false,
                        //pradeep
                    }).then(function (result) {
                        window.location = '/projects';
                    });
                    //End
                }
            });
        },
        isCharAllow: function (evt) {
            this.$nextTick(() => {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
                    return true;
                } else {
                    evt.preventDefault();
                    this.showAlert('Error', "Only alphabets are allowed", false);
                }
            })
        },
        getOrgAnimals()
        {
            let vm = this;
            axios.get("/animal/organisation/" + vm.model.selectedOrganisation)
                .then(response => {
                    vm.orgAnimals = response.data.response.data.animals;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getOrgAnimalsAndDrugs()
        {
            if (this.model.selectedOrganisation === this.responseProjectDetails.organisation_id) {
                this.model.selectedAnimals = this.responseProjectDetails.project_animals.map(el => el.animal.species_name)
                this.model.vaccinationDrug = JSON.parse(this.responseProjectDetails.vaccination_drug_ids)
            } else {
                this.model.selectedAnimals = []
                this.model.vaccinationDrug = []
            }
            this.getOrgAnimals();
            this.getOrgVaccinations();

        },
        //end

        tempAddSpecies()
        {
            const isNameAlreadyExists = this.orgAnimals.find(el => el.species_name.toLowerCase() === this.animalName.toLowerCase())
            const isTempNameExists = this.allSpeciesName.find(el => el.toLowerCase() === this.animalName.toLowerCase())
            console.log('isNameAlreadyExists', isNameAlreadyExists)
            if (isNameAlreadyExists || isTempNameExists) {
                this.showAlert(
                    "Unable to Add",
                    'The species name you trying to add is already exists',
                    false
                );
            } else {
                // this.$nextTick(() => {
                this.allSpeciesName.push(this.animalName)
                console.log('this.$refs', this.$refs.speciesNameInput.$validator)
                // this.$refs.speciesNameInput.$validator.pause()
                this.addNewAnimal()

                // })
            }
        },
        removeFromAllSpeciesName(itemIndex, species)
        {
            this.allSpeciesName.splice(itemIndex, 1);
            // check if it's selected. if selected then remove from model.selectedAnimals as well.
            const elIndex = this.model.selectedAnimals.findIndex(el => el === species)
            if (elIndex !== -1) {
                this.model.selectedAnimals.splice(elIndex, 1)
            }
        },
        tempAddVaccinationDrug()
        {
            const isNameAlreadyExists = this.vaccinationDrug.find(el => el.vaccination_drug.toLowerCase() === this.vaccineName.toLowerCase())
            const isTempNameExists = this.allVaccinationDrugName.find(el => el.toLowerCase() === this.vaccineName.toLowerCase())

            if (isNameAlreadyExists || isTempNameExists) {
                this.showAlert(
                    "Unable to Add",
                    'The Vaccine/Drug name you trying to add is already exists',
                    false
                );
            } else {
                this.allVaccinationDrugName.push(this.vaccineName)
                this.addVaccineDrug()
            }
        },
        removeFromAllVaccinationDrugName(itemIndex, vaccinationDrug)
        {
            this.allVaccinationDrugName.splice(itemIndex, 1);
            const elIndex = this.model.vaccinationDrug.findIndex(el => el === vaccinationDrug)
            if (elIndex !== -1) {
                this.model.vaccinationDrug.splice(elIndex, 1)
                // this.model.vaccinationDrug = this.vaccinationDrug[0].vaccination_drug || null
            }
        },

    },

    computed: {
        ...mapGetters('user', ['userOrganisations']),
        //...mapGetters('project', ['vaccinationDrug']),
        projectAssociateInfo: function () {
            if (this.model.projectAssociate == '1') {
                this.model.selectedOrganisation = null;
                this.vaccinationDrug = [
                    {
                        id: 0,
                        vaccination_drug: 'Rabies'
                    },
                ];
                return 'Private projects are only visible to you, you can not invite others to the project.  Mainly intended for testing/setup purposes.  You can later change visibility to Organisation if you are affiliated with one.';
            } else
                if (this.model.projectAssociate == '2' && this.userOrganisations.length > 0) {
                    return 'Select organisation, The organisation will be notified for the new project and will have full rights to transfer and delete the project.';
                } else {
                    return 'You are not a registered member to any organisation; please registered your own organisation or send a personal request for the membership registration to the organisations - please visit the hsiapps.org for the list of the organisation'
                }
        },
        constructTitle: function () {
            let prefix = this.projectId > 0 ? 'Update ' : 'Create New ';
            if (this.model.projectAssociate == '1') {
                this.model.selectedOrganisation = null;
                return prefix + this.model.activeTab + ' Project for Self';
            } else
                if (this.model.projectAssociate == '2' && this.userOrganisations != null) {
                    return prefix + this.model.activeTab + ' Project for Organisation';
                }
        },
        getAddAnimalLabel: function () {
            if (this.addingNewAnimal) {
                return "";
            } else {
                return "+ Add New";
            }
        },
        getAddVaccineLabel: function () {
            if (this.addingNewVaccine) {
                return "";
            } else {
                return "+ Add New";
            }
        },
        getCreateUpdateLabel: function () {
            if (this.projectId > 0) {
                return 'Update';
            }
            return 'Create';
        },


    },

    mounted()
    {
        this.init();
        // Pradeep Start
        let vm = this;
        vm.project = JSON.parse(localStorage.project);
        Bus.$emit("projectype", vm.project);
        // Pradeep End
    },


}

</script>

<style scoped>

#description {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
}

#infowindow-content .title {
    font-weight: bold;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}

.pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-family: Roboto;
}

#pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
}

.pac-controls {
    display: inline-block;
    padding: 5px 11px;
}

.pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
}

#pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
}

#pac-input:focus {
    border-color: #4d90fe;
}

#title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
}

a {
    color: #51cbce;
}

a:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #51cbce;
}

.chechbox_lbl {
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-top: -14px;
}

.hideMvOptions {
    display: none;
}

.logo_img {
    height: 30px;
    border-radius: 0px !important;
}

/* Pradeep Start */
.set_msg {
    margin-top: 5%;
}

@media (max-width: 320px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 321px) and (max-width: 374px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .set_msg {
        margin-top: 15% !important;
    }
}

@media (min-width: 768px) and (max-width: 769px) {
    .set_msg {
        margin-top: 5% !important;
    }
}

.set_font_title {
    font-size: 20px;
    font-weight: 600;
    color: black;
    margin-left: -12px !important;
}

/* Pradeep End */
</style>
