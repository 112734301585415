<template>
    <div>

        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>

        <div class="row">
            <div class="col-sm-12">
                <h5 class="set_font_title">Out-of-Area Vaccinations
                    <a class="btn-rotate help-icon" style="cursor:pointer" @click="showUrl()">
                        <i class="fa fa-question-circle" aria-hidden="true"></i>
                    </a>
                </h5>
                <span>Vaccinations that occurred outside of the Area boundary (geofence)</span>
                <hr>
            </div>
        </div>

        <div class="row">

            <div class="col-sm-6">
                <div class="form-group">
                    <template>
                        <label>Select Area</label>
                        <el-select v-model="selectedArea" placeholder="All"
                                   @change="">
                            <el-option
                                    v-for="area in areas"
                                    :key="area.id"
                                    :label="area.name"
                                    :value="area.id">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group">
                    <template>
                        <label>Select City/District/Village</label>
                        <el-select v-model="selectedLocality" placeholder="All"
                                   @change="">
                            <el-option
                                    v-for="locality in allLocality"
                                    :key="locality.google_locality"
                                    :label="locality.google_locality"
                                    :value="locality.google_locality">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <button v-bind:disabled="!onLine" style="float: right;" type="button"
                            @click="generateData" name="generate"
                            class="btn btn-round btn-default">
                        Filter
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <h5>Total Out-Of-Area records by Area</h5>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <table id="example" class="display table table-striped table-bordered select"
                       cellspacing="0" width="100%">
                    <thead>
                    <tr>
                        <th>Area</th>
                        <th>Field Users</th>
                        <th>Country</th>
                        <th>State/Province</th>
                        <th>City/District/Village</th>
                        <th>Total</th>
                        <th>Created Date</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                </table>
            </div>
        </div>
        <help-modal ref="helpModal"></help-modal>
    </div>
</template>

<script>

import mixin from '../../mixins/GlobalMixin';

import {Select, Option,} from 'element-ui';
import VOffline from 'v-offline';

export default {


    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        VOffline
    },
    props: {
        projectId: {
            type: Number,
        },
    },
    mixins: [mixin],
    data()
    {
        return {
            teams: [],
            areas: [],
            allLocality: [],

            selectedArea: null,
            selectedTeam: null,
            selectedLocality: null,
            table: null,
        }
    },
    methods: {

        init()
        {
            this.getAllOuterAreas();
            this.getAllUsersAndTeams();
            this.getAllLocality();
            this.generateData();
        },
        showUrl()
        {
            this.$refs.helpModal.loadUrl(window.USER_MANUAL_URL + "#h.wpseezggdeln");
        },
        getAllOuterAreas()
        {
            let vm = this;
            axios.get('area/vaccination/outer/' + vm.projectId)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.areas = response.data.response.data.outer_areas;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getAllUsersAndTeams()
        {
            let vm = this;
            axios.get('/user/users_and_teams', {
                params: {
                    project_id: vm.projectId,
                }
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.users = response.data.response.data.users;
                    vm.teams = response.data.response.data.teams;
                }
            })
                .catch(error => {
                    console.log(error);
                });
        },
        getAllLocality()
        {
            let vm = this;
            axios.get('/area/locality', {
                params: {
                    project_id: vm.projectId,
                }
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.allLocality = response.data.response.data.locality;
                }
            })
                .catch(error => {
                    console.log(error);
                });
        },
        generateData()
        {
            let vm = this;

            if (vm.table != null) {
                vm.table.destroy();
            }

            vm.table = $('#example').DataTable({
                "scrollX": true,
                responsive: true,
                dom: 'lBfrtip',
                "bInfo": false,
                'columns': [
                    null,
                    {'searchable': false},
                    null,   // product code
                    null, // description
                    null,
                    {'searchable': false},
                    null,
                    {'searchable': false},
                ],
                buttons: [
                    {
                        extend: 'csv',
                        title: 'Outer Vaccinations',
                        messageTop: 'Report',
                        messageBottom: 'Human Society International HSIAPPS',
                        className: 'btn btn-default',
                    },
                    {
                        extend: 'pdf',
                        title: 'Outer Vaccinations',
                        messageTop: 'Report',
                        messageBottom: 'Human Society International HSIAPPS',
                        className: 'btn btn-default',
                    },
                ],
                'fnRowCallback': function (nRow, aData, iDisplayIndex) {
                    $('td:eq(7)', nRow).html('<a href="' + aData[7] + '" class="btn btn-success">' +
                        'EDIT' +
                        '</a>');
                    return nRow;
                },
                "processing": true,
                "serverSide": true,
                "ajax": {
                    "url": window.DATATABLES_BASE_URL + "outerVaccinationRecords",
                    //"url": "http://localhost:8888/api/datatables/outerVaccinationRecords",
                    "type": "POST",
                    "data": {
                        project_id: vm.projectId,
                        selected_area: vm.selectedArea,
                        selected_locality: vm.selectedLocality,
                    }
                    //"dataType": "jsonp"
                },

                lengthMenu: [[10, 30, 50], [10, 30, 50]],
                pageLength: 10,
            });
        },
        editRecords(areaId)
        {
            alert(areaId);
        }

    },
    computed: {},
    mounted()
    {
        this.init();
        // Pradeep Start
        let vm = this;
        vm.project = JSON.parse(localStorage.project);
        //Bus.$emit("projectype", vm.project);
        // Pradeep End
    }


}

</script>

<style lang="scss">

.help-icon {
  cursor: pointer;
  position: absolute;
  margin-left: 5px;
  margin-top: -15px;
  color: #51cbce !important;;
}

.set_font_title {
  font-size: 20px;
  font-weight: 600;
  color: black;
  margin-left: -12px !important;
}

.btn-group .btn + .btn {
  margin-left: 5px !important;
}

.dataTables_length {
  margin-right: 10px;
}

.pencil-a {
  color: #212529;
}

.pencil-a:hover {
  color: #212529;
}
</style>
