<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <!--<ul>
          <li>
            <a href="http://www.creative-tim.com">
              Creative Tim
            </a>
          </li>
          <li>
            <a href="http://blog.creative-tim.com">
              Blog
            </a>
          </li>
          <li>
            <a href="http://www.creative-tim.com/license">
              Licenses
            </a>
          </li>
        </ul>-->
      </nav>
      <div class="copyright pull-right">
        <a href="https://github.com/cristijora" target="_blank">Human Society International</a>
      </div>
      <!--<div class="copyright pull-right">
        &copy; Coded with
        <i class="fa fa-heart heart"></i> by
        <a href="https://github.com/cristijora" target="_blank">Cristi Jora</a>.
        <span>Designed by <a href="https://www.creative-tim.com/?ref=pdf-vuejs" target="_blank">Creative Tim</a>.</span>
      </div>-->
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>

</style>
