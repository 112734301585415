<template>
    <!--pradeep -->
    <nav :class="classes" class="navbar navbar-expand-lg setnavbarbgcolor"
         style="position: sticky; top: 0; background-color: white; margin: 0;">
        <div class="container-fluid">
            <slot></slot>
            <!--Add css class for set responsive toggleMenu -->
            <!-- praddep -->
            <slot name="toggle-button">
                <button aria-controls="navigation-index"
                        @click="toggleMenu"
                        aria-expanded="true" aria-label="Toggle navigation"
                        class="navbar-toggler set_navbar_togglerresponsive" data-toggle="collapse" type="button"><span
                        class="navbar-toggler-bar navbar-kebab"></span><span
                        class="navbar-toggler-bar navbar-kebab"></span><span
                        class="navbar-toggler-bar navbar-kebab"></span></button>
            </slot>
            <!--end praddep -->
            <!-- Pradeep -->
            <slot name="toggle-button" v-if="projecNavbar==0">
                <div @click="myFunction" aria-controls="navigation-index" aria-label="Toggle navigation"
                     class="navbar-toggler" data-toggle="collapse">
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                </div>
            </slot>
            <!-- End -->

            <CollapseTransition>
                <div class="collapse navbar-collapse justify-content-end show"
                     :class="navbarMenuClasses"
                     v-show="showNavbar"
                     id="navigation">
                    <ul class="navbar-nav">
                        <slot name="navbar-menu"></slot>
                    </ul>
                </div>
            </CollapseTransition>
        </div>
    </nav>
    <!--End -->
</template>
<script>
import {CollapseTransition} from 'vue2-transitions';

export default {
    name: 'navbar',
    props: {
        showNavbar: {
            type: Boolean,
            description: 'Whether navbar is visible',
        },
        navbarMenuClasses: {
            type: [String, Object],
            description: 'Navbar menu css classes',
        },
        transparent: {
            type: Boolean,
            default: true,
            description: 'Whether navbar is transparent',
        },
        position: {
            type: String,
            default: 'absolute',
            description: 'Navbar position (absolute|fixed|relative)',
        },
        type: {
            type: String,
            default: 'white',
            validator(value)
            {
                return ['white', 'default', 'primary', 'danger', 'success', 'warning', 'info'].includes(value);
            },
            description: 'Navbar type (primary|info|danger|default|warning|success)',
        },
    },
    model: {
        prop: 'showNavbar',
        event: 'change',
    },
    components: {
        CollapseTransition,
    },
    data()
    {
        return {
            projecNavbar: 0
        };
    },
    computed: {
        classes()
        {
            let color = `bg-${this.type}`;
            let navPosition = `navbar-${this.position}`;
            return [
                {'navbar-transparent': !this.showNavbar && this.transparent},
                {[color]: this.showNavbar || !this.transparent},
                navPosition
            ];
        },
    },
    methods: {
        toggleMenu()
        {
            this.$emit('change', !this.showNavbar);
            var x = document.getElementsByClassName("responsive_menu_item");
            x[0].style.display = "none";
            //document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "35%","important");

            if (window.innerWidth >= 768 && window.innerWidth <= 800) {
                document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "0px", "important");
                if (!this.showNavbar) {
                    document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "150px", "important");
                } else {
                    document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "65px", "important");
                }
            } else
                if (window.innerWidth >= 320 && window.innerWidth <= 768) {
                    document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "130px", "important");
                    if (!this.showNavbar) {
                        document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "150px", "important");
                    } else {
                        document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "130x", "important");
                    }
                } else {
                    document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "0px", "important");
                }
        },
        myFunction()
        {
            var x = document.getElementsByClassName("responsive_menu_item");

            if (x[0].style.display === "none" || x[0].style.display == '') {
                x[0].style.display = "block";
                this.$emit('change', false);
                //document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "0px","important");
                if (window.innerWidth >= 768 && window.innerWidth <= 800) {
                    document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "0px", "important");
                    if (!this.showNavbar) {
                        document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "0px", "important");
                    } else {
                        document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "0px", "important");
                    }
                } else
                    if (window.innerWidth >= 320 && window.innerWidth <= 768) {
                        document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "0px", "important");
                        if (!this.showNavbar) {
                            document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "0px", "important");
                        } else {
                            document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "0px", "important");
                        }
                    } else {
                        document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "0px", "important");
                    }
            } else {
                x[0].style.display = "none";
                //document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "35%","important");
                if (window.innerWidth >= 768 && window.innerWidth <= 800) {
                    document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "0px", "important");
                    if (!this.showNavbar) {
                        document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "65px", "important");
                    } else {
                        document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "150px", "important");
                    }
                } else
                    if (window.innerWidth >= 320 && window.innerWidth <= 768) {
                        document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "0px", "important");
                        if (!this.showNavbar) {
                            document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "130px", "important");
                        } else {
                            document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "150px", "important");
                        }
                    } else {
                        document.getElementsByClassName('set_msg')[0].style.setProperty("margin-top", "0px", "important");
                    }
            }
        },
    },
    mounted()
    {
        var vm = this;
        Bus.$on('projecNavbar', data => {
            vm.projecNavbar = data;
            console.log(vm.projecNavbar);
        });
        // if(window.innerWidth <= 768){
        //     document.getElementsByClassName("responsive_menu_item")[0].style.display = "none";
        // }
    }
};
</script>
<style lang="scss">
.navbar-relative {
  position: relative;
}

.navbar.bg-white {
  padding-top: 12px;
  //    background-color: #f4f3ef !important;
}

.bar1, .bar2, .bar3 {
  width: 23px !important;
  height: 2px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.type-header {
  font-size: 16px;
}

.set_navbar_togglerresponsive {
  position: relative;
  // right: -42px;
  top: 6px;
}


@media only screen and (min-device-width: 320px) and (max-device-width: 800px) and (orientation: portrait) {
  .set_navbar_togglerresponsive {
    position: relative;
    right: 0;
    top: 6px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 800px) {
  .navbar-collapse {
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15) !important;
  }
  .navbar.navbar-transparent.nav_link_list {
    margin-top: -20px;
  }
}

@media only screen and (max-device-width: 320px) {
  .set_navbar_togglerresponsive {
    position: relative;
    right: 0;
    top: 7px;
  }
}

@media only screen and (max-width: 800px) {
  .responsive_menu_item {
    display: none;
  }
}

</style>
