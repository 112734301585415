<template>
    <div class="set_self_project_res">
        <div class="navbar navbar-expand-lg navbar-transparent nav_link_list setWhite desktopnavbar boxshadowsetheader responsive_menu_item"
             id="scrolldiv">
            <div class="col-md-6">
                <ul class="navbar-nav" style="margin-left: 16px;">
                    <li class="nav-item">
                        <div class="hoverclick">
                            <router-link custom
                                         v-slot="{ href, route, navigate,isActive, isExactActive}" to="projects"
                                         tag="a">
                                <a :href="href" @click="navigate"
                                   :class="[isActive && 'nav-link nav-link-Projects', isExactActive && 'nav-link nav-link-Projects']">Projects</a>
                            </router-link>
                        </div>
                    </li>
                    <li class="nav-item">
                        <router-link to="fieldteams" class="nav-link">
                            Field Teams
                        </router-link>
                    </li>
                </ul>
                <!-- pradeep -->
            </div>
            <div class="col-md-6">
                <template v-if="ownProjects.length>0">
                    <div class="top-bar set_search_textbox">
                        <template>
                            <h5></h5>
                        </template>
                        <div>
                            <template>
                           <span class="set_textbox_left">
                              <el-autocomplete
                                      v-model="selectedProject"
                                      :fetch-suggestions="remoteMethod"
                                      placeholder="Search Projects..."
                                      @select="showProjectDetails"
                              ></el-autocomplete>
                           </span>
                            </template>
                            <router-link
                                    custom
                                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                                    to="newproject">
                                <a :href="href" @click="navigate" :class="isActive">
                                    <button
                                            type="button" class="btn btn-round btn-default">+ New Project
                                    </button>
                                </a>
                            </router-link>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="top-bar set_search_textbox">
                        <h5 style="margin: 0;"></h5>
                        <div>
                            <template>
                           <span class="set_textbox_left">
                              <el-autocomplete
                                      v-model="selectedProject"
                                      :fetch-suggestions="remoteMethod"
                                      placeholder="Search Projects..."
                                      disabled="disabled"
                              ></el-autocomplete>
                           </span>
                            </template>
                            <a href="/new/project">
                                <button type="button" class="btn btn-round btn-default">+ New Project
                                </button>
                            </a>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <!-- pradeep -->
        <div class="set_msg" v-if="this.value && (this.value!=2)">
            <div class="row">
                <h5 class="setTitleDiv" style="margin-left: 29px;margin-right: 11px;margin-top: 25px;">
                    Your Projects
                    <span v-if="filteredOwnProjects.length!=0">({{ filteredOwnProjects.length }})</span>
                </h5>
            </div>
            <div class="row" style="margin-left: 11px;margin-right: 11px;">
                <div class="col-lg-4 col-md-6 col-sm-12" v-for="project in filteredOwnProjects">
                    <project-card
                            :project-id="project.id"
                            :project-type="project.type"
                            :is-org-project="project.organisation_id!=null"
                            :org-name="project.organisation_id!=null?project.organisation.name:null"
                            :title="project.name"
                            :field-users="project.field_users"
                            :animals-treated="project.animals_treated"
                            :created-on="convertDate(project.created_at,project.date_format,project.timezone)"
                            :last-active="activeAgo(project.updated_at,project.timezone)"
                            :project-image="project.image_url!=null?project.image_url:'https://vuejs.org/images/logo.png'"
                            :user-role="owner"
                    ></project-card>
                </div>
            </div>
            <div class="row" style="margin-left: 11px;margin-right: 11px;"
                 v-if="filteredOwnProjects.length==0">
                <h5 style="font-size: 19px;margin-left: 73px;">No Projects found</h5>
            </div>
        </div>
        <div v-else>
            <div class="row set_msg" v-if="filteredOwnProjects.length!=0">
                <h5 class="setTitleDiv" style="margin-left: 29px;margin-right: 11px;margin-top: 25px;">Your
                    Projects
                    ({{ filteredOwnProjects.length }})</h5>
            </div>
            <div class="row" style="margin-left: 11px;margin-right: 11px;">
                <div class="col-lg-4 col-md-6 col-sm-12" v-for="project in filteredOwnProjects">
                    <project-card
                            :project-id="project.id"
                            :project-type="project.type"
                            :is-org-project="project.organisation_id!=null"
                            :org-name="project.organisation_id!=null?project.organisation.name:null"
                            :title="project.name"
                            :field-users="project.field_users"
                            :animals-treated="project.animals_treated"
                            :created-on="convertDate(project.created_at,project.date_format,project.timezone)"
                            :last-active="activeAgo(project.updated_at,project.timezone)"
                            :project-image="project.image_url!=null?project.image_url:'https://vuejs.org/images/logo.png'"
                            :user-role="owner"
                    ></project-card>
                </div>
            </div>
        </div>
        <template
                v-if="assignedProjects.length==0 && ownProjects.length==0 && this.value=='' && fetchProjectsApiExecuted">
            <p style="text-align: center;margin-top: 79px;">
                You don't have any project. Start creating one by clicking
                <b>
                    <span class="text-primary">+ NEW PROJECT</span>
                </b> Button.
            </p>
            <p style="text-align: center;">
                If you are a field user, The next steps are:
                <br>
                <br>
                1) Download the Field User app to your phone by clicking <a
                    href="https://drive.google.com/file/d/1TUkS7OOX4yzIv1fErZHMYQE-V2NYTDHN/view?usp=sharing">here</a>.<br><br>
                2) Open the Field User app and app and login with your HSApps account to make sure you can
                login
                successfully.
                <br>
                <br>
                Note: You may see the message “You have not been assigned any projects” if you are not yet
                assigned<br>
                to a project. That is normal, you will get an email when you are assigned to a project and
                you
                can<br>
                continue then.
            </p>
        </template>
        <div v-if="this.value && (this.value!=1)">
            <div class="row" style="margin-left: 29px;margin-right: 11px;margin-top: 52px;">
                <h5>
                    Assigned Projects
                    <span v-if="filteredAssignedProjects.length!=0">({{
                            filteredAssignedProjects.length
                        }})</span>
                </h5>
            </div>
            <div class="row" style="margin-left: 11px;margin-right: 11px;">
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                     v-for="project in filteredAssignedProjects">
                    <project-card
                            :project-id="project.id"
                            :project-type="project.type"
                            :is-org-project="project.organisation_id!=null"
                            :org-name="project.organisation_id!=null?project.organisation.name:null"
                            :title="project.name"
                            :field-users="project.field_users"
                            :animals-treated="project.animals_treated"
                            :created-on="convertDate(project.created_at,project.date_format,project.timezone)"
                            :last-active="activeAgo(project.updated_at,project.timezone)"
                            :project-image="project.image_url!=null?project.image_url:'https://vuejs.org/images/logo.png'"
                            :user-role="project.roles"
                    ></project-card>
                </div>
            </div>
            <div class="row" style="margin-left: 11px;margin-right:11px;margin-left: 74px;"
                 v-if="filteredAssignedProjects.length==0">
                <h5 class="setTitleDiv" style="font-size: 19px;">No Projects Found</h5>
            </div>
        </div>
        <div v-else>
            <!-- Pradeep Start -->
            <div class="row set_assignProject" style="margin-left: 29px; margin-right: 11px;"
                 v-if="filteredAssignedProjects.length!=0">
                <h5>Assigned Projects({{ filteredAssignedProjects.length }})</h5>
            </div>
            <!-- Pradeep End -->
            <div class="row" style="margin-left: 11px;margin-right: 11px;">
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                     v-for="project in filteredAssignedProjects">
                    <project-card
                            :project-id="project.id"
                            :project-type="project.type"
                            :is-org-project="project.organisation_id!=null"
                            :org-name="project.organisation_id!=null?project.organisation.name:null"
                            :title="project.name"
                            :field-users="project.field_users"
                            :animals-treated="project.animals_treated"
                            :created-on="convertDate(project.created_at,project.date_format,project.timezone)"
                            :last-active="activeAgo(project.updated_at,project.timezone)"
                            :project-image="project.image_url!=null?project.image_url:'https://vuejs.org/images/logo.png'"
                            :user-role="project.roles"
                    ></project-card>
                </div>
            </div>
        </div>
        <!-- End -->
    </div>
</template>
<script>
import CircleChartCard from "./UIComponents/Cards/CircleChartCard.vue";
import ChartCard from "./UIComponents/Cards/ChartCard";
import StatsCard from "./UIComponents/Cards/StatsCard";
import ProjectCard from "./UIComponents/Cards/ProjectCard.vue";
import {Badge, Button} from "./UIComponents";
import UserNavbar from "./Layout/UserNavbar";
import TaskList from "./Widgets/TaskList";
import {mapGetters} from "vuex";
import FgInput from "./UIComponents/Inputs/formGroupInput";
import {Autocomplete} from "element-ui";
import Menubar from "./Layout/Menubar";

export default {
    components: {
        'user-navbar': UserNavbar,
        'menu-bar': Menubar,
        FgInput,
        StatsCard,
        ChartCard,
        ProjectCard,
        CircleChartCard,
        Badge,
        TaskList,
        [Autocomplete.name]: Autocomplete,
    },

    data()
    {
        return {
            fetchProjectsApiExecuted: false,
            info: "Loading...",
            statsCards: [
                {
                    type: "warning",
                    icon: "nc-icon nc-globe",
                    title: "Capacity",
                    value: "105GB",
                    footerText: "Updated now",
                    footerIcon: "nc-icon nc-refresh-69"
                },
                {
                    type: "success",
                    icon: "nc-icon nc-money-coins",
                    title: "Revenue",
                    value: "$1,345",
                    footerText: "Last day",
                    footerIcon: "nc-icon nc-calendar-60"
                },
                {
                    type: "danger",
                    icon: "nc-icon nc-vector",
                    title: "Errors",
                    value: "23",
                    footerText: "In the last hour",
                    footerIcon: "nc-icon nc-bell-55"
                },
                {
                    type: "info",
                    icon: "nc-icon nc-favourite-28",
                    title: "Followers",
                    value: "+45",
                    footerText: "Updated now",
                    footerIcon: "nc-icon nc-refresh-69"
                }
            ],
            owner: "OWNER",
            imageUrl: "",
            searchQuery: "",
            value: "",
            searchedProjects: [],
            selectedProject: "",
            timeout: null,
            //isOrgDeleted:false,
        };
    },
    props: {
        organisation: String,
    },
    /*computed: {
                 demoPrefix() {
                 return window.demoPrefix;
                 }
                 },*/
    methods: {
        remoteMethod(query, cb)
        {
            let vm = this;
            if (query !== "") {
                axios
                    .get("project/search", {
                        params: {
                            name: query
                        }
                    })
                    .then(response => {
                        clearTimeout(vm.timeout);
                        vm.searchedProjects = query ? response.data.response.data.projects.filter(this.createFilter(query)) : response.data.response.data.projects;
                        if (vm.ownProjects.length == 0 && vm.assignedProjects.length == 0) {
                            var errMsg = [{id: 1, type: "errMsg", value: "You don't have any project"}]
                            cb(errMsg);
                        } else {
                            cb(vm.searchedProjects);
                        }
                    })
                    .catch(error => {
                        vm.searchedProjects = [];
                        cb(vm.searchedProjects);
                    });
            } else {
                clearTimeout(vm.timeout);
                vm.searchedProjects = [];
                cb(vm.searchedProjects);
            }
        },

        createFilter(query)
        {
            return searchedProjects => {
                return (searchedProjects.value.toLowerCase().indexOf(query.toLowerCase()) === 0);
            };
        },
        showProjectDetails(project)
        {
            //alert(JSON.stringify(project));
            //window.location.href = "project/details/" + project.id;
            this.$router.push({path: '/project/' + project.id + '/overview'});
        },

        //start pradeep
        onclick: function (project_type, value) {
            this.searchQuery = project_type;
            this.value = value;
        },

        myFunction_test()
        {
            var header = document.getElementById("scrolldiv");
            var sticky = header.offsetTop;
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        },
        test()
        {
            var self = this
            window.onscroll = function () {
                self.myFunction_test()
            };
        },
        //pradeep End
    },

    computed: {
        ...mapGetters("user", ["ownProjects", "assignedProjects"]),
        filteredOwnProjects: function () {
            return this.ownProjects.filter(item => {
                //pradeep
                if (this.value == 1) {
                    return item;
                } else
                    if (this.value == 2) {
                        return "";
                    } else
                        if (this.value == 3) {
                            return (
                                item.type.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
                            );
                        } else
                            if (this.value == 4) {
                                return (
                                    item.type.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
                                );
                            } else
                                if (this.value == 5) {
                                    return item;
                                } else {
                                    return (
                                        item.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
                                    );
                                }
                //end
            });
        },
        filteredAssignedProjects: function () {
            return this.assignedProjects.filter(item => {
                //pradeep
                if (this.value == 1) {
                    return "";
                } else
                    if (this.value == 2) {
                        return item;
                    } else
                        if (this.value == 3) {
                            return (
                                item.type.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
                            );
                        } else
                            if (this.value == 4) {
                                return (
                                    item.type.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
                                );
                            } else
                                if (this.value == 5) {
                                    return item;
                                } else {
                                    return (
                                        item.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
                                    );
                                }
                //End
            });
        }
    },
    created()
    {
        let vm = this;
        vm.test()
        vm.$store.dispatch('user/getProjects').then(() => {
            vm.fetchProjectsApiExecuted = true;
        })
    },
    mounted()
    {
        let vm = this;
        //vm.getUsersDetails();
        Bus.$on("search.query", payLoad => {

        });
        var data = localStorage.getItem('projectDropData');
        if (data != null) {
            data = JSON.parse(data);
            vm.searchQuery = data.project_type;
            vm.value = data.value;
            localStorage.removeItem('projectDropData');
        }
        document.getElementsByClassName("nav-link-Projects")[0].style.color = "#f58220";
    }
};
</script>
<style scoped>

.nav-link-Projects {
    color: #f58220;
}

</style>
