<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox ">
                    <div class="ibox-content">
                        <div class="panel-heading">
                            <h2 class="set_font_title">
                                Report by City/District/Village
                            </h2>
                        </div>
                    </div>
                    <el-row>
                        <div class="card p-3">
                            <el-row :gutter="20">
                                <el-col
                                        :xs="24"
                                        :sm="24"
                                        :md="8"
                                        :lg="8"
                                        :xl="8"
                                >
                                    <label>Country</label>
                                    <multiselect
                                            v-model="selectedCountry"
                                            :options="allCountries"
                                            :show-labels="false"
                                            placeholder="Select Country"
                                            :multiple="true"
                                            :close-on-select="false"
                                            @remove="countryRemoved"
                                            @select="countrySelected"
                                    ></multiselect>
                                </el-col>
                                <el-col
                                        :xs="24"
                                        :sm="24"
                                        :md="8"
                                        :lg="8"
                                        :xl="8"
                                >
                                    <label>State</label>
                                    <multiselect
                                            v-model="selectedState"
                                            :options="allStates"
                                            :show-labels="false"
                                            placeholder="Select State"
                                            :disabled="!selectedCountry"
                                            :multiple="true"
                                            :close-on-select="false"
                                            @remove="stateRemoved"
                                            @select="stateSelected"
                                    ></multiselect>
                                </el-col>
                                <el-col
                                        :xs="24"
                                        :sm="24"
                                        :md="8"
                                        :lg="8"
                                        :xl="8"
                                >
                                    <label>City/District/Village</label>
                                    <multiselect
                                            v-model="selectedCity"
                                            :options="allCities"
                                            :show-labels="false"
                                            placeholder="Select City"
                                            :multiple="true"
                                            :close-on-select="false"
                                            :disabled="!selectedState"
                                    ></multiselect>
                                </el-col>
                            </el-row>
                            <div>
                                <el-button
                                        @click="generatePDF"
                                        :loading="isGenerating"
                                        class="btn btn-primary float-right text-white mt-3 mr-1"
                                >
                                    Generate PDF
                                </el-button>
                            </div>
                        </div>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '../../mixins/GlobalMixin';
import Multiselect from "vue-multiselect";
import {Row, Col, Button} from "element-ui";

export default {
    name: "ReportByCity",
    data: () => ({
        allCountries: [],
        allStates: [],
        allCities: [],
        selectedCountry: [],
        selectedState: [],
        selectedCity: [],
        isGenerating: false,
    }),
    mixins: [mixin],
    props: {
        projectId: {
            type: [Number, String],
            required: true
        }
    },
    components: {
        Multiselect,
        [Row.name]: Row,
        [Col.name]: Col,
        [Button.name]: Button,
    },
    async mounted()
    {
        let vm = this;
        vm.project = JSON.parse(localStorage.project);
        Bus.$emit("projectype", vm.project);
        await this.getAllData();
    },
    methods: {
        async getAllData()
        {
            await this.getAllCountries();
        },
        stateSelected(state)
        {
            this.getAllCities(state, false);
        },
        stateRemoved(state)
        {
            this.getAllCities(state, true);
        },
        async getAllCities(state, isRemoved)
        {
            this.resetCity();

            for (let i = 0; i < this.selectedState.length; i++) {

                let state2 = this.selectedState[i];

                try {
                    const {data} = await axios.get(
                        `/project/cities/by/state/${this.projectId}`,
                        {
                            params: {
                                state: state2
                            }
                        }
                    );
                    console.log("city", data.response.data);
                    for (let j = 0; j < data.response.data.cities.length; j++) {
                        let newItem = data.response.data.cities[j];
                        this.allCities.indexOf(newItem) === -1 ? this.allCities.push(newItem) : true;
                    }
                } catch (error) {
                    console.error(error.response);
                }

            }

            try {
                const {data} = await axios.get(
                    `/project/cities/by/state/${this.projectId}`,
                    {
                        params: {
                            state
                        }
                    }
                );
                console.log("city", data.response.data);
                for (let j = 0; j < data.response.data.cities.length; j++) {
                    let newItem = data.response.data.cities[j];
                    if (isRemoved) {
                        this.allCities.splice(this.allCities.indexOf(newItem), 1)
                    } else {
                        this.allCities.indexOf(newItem) === -1 ? this.allCities.push(newItem) : true;
                    }
                }
            } catch (error) {
                console.error(error.response);
            }
        },
        countrySelected(country)
        {
            this.getAllStates(country, false);
        },
        countryRemoved(country)
        {
            this.getAllStates(country, true);
        },
        async getAllStates(country, isRemoved)
        {
            this.resetAllOtherFields()

            for (let i = 0; i < this.selectedCountry.length; i++) {

                let country2 = this.selectedCountry[i];

                try {
                    const {data} = await axios.get(
                        `/project/states/by/country/${this.projectId}`,
                        {
                            params: {
                                country: country2
                            }
                        }
                    );
                    console.log("state", data.response.data);
                    for (let j = 0; j < data.response.data.states.length; j++) {
                        let newItem = data.response.data.states[j];
                        this.allStates.indexOf(newItem) === -1 ? this.allStates.push(newItem) : true;
                    }

                    //this.allStates = data.response.data.states;
                } catch (error) {
                    console.error(error.response);
                }

            }

            try {
                const {data} = await axios.get(
                    `/project/states/by/country/${this.projectId}`,
                    {
                        params: {
                            country: country
                        }
                    }
                );
                console.log("state", data.response.data);
                for (let j = 0; j < data.response.data.states.length; j++) {
                    let newItem = data.response.data.states[j];
                    if (isRemoved) {
                        this.allStates.splice(this.allStates.indexOf(newItem), 1)
                    } else {
                        this.allStates.indexOf(newItem) === -1 ? this.allStates.push(newItem) : true;
                    }
                }

                //this.allStates = data.response.data.states;
            } catch (error) {
                console.error(error.response);
            }

        },
        async getAllCountries()
        {
            try {
                const {data} = await axios.get(
                    `/project/countries/${this.projectId}`
                );
                console.log("countries", data.response.data.countries);
                this.allCountries = data.response.data.countries;
            } catch (error) {
                console.error(error.response);
            }
        },
        async generatePDF()
        {

            if (this.selectedCountry.length == 0) {
                alert("You must select a country");
                return;
            }

            this.isGenerating = true;
            const URL = `/pm/reports/generate/report/${this.projectId}`;
            const body = {
                report_type: 2,
                countries: this.selectedCountry,
                states: this.selectedState,
                cities: this.selectedCity,
            }
            try {
                const {data} = await axios.post(URL, body);
                const downloadURL = `/pm/reports/download/pdf/${data.response.data.report_id}`
                // const downloadURL = `/pm/reports/download/pdf/1`

                // window.location.href = downloadURL
                const pdfResponse = await axios.get(downloadURL)
                this.isGenerating = false;
                console.log('pdf response', pdfResponse.data.response);
                if (pdfResponse.data.response.data === null) {
                    this.showAlert('Oops!', pdfResponse.data.response.error.msg, false)
                } else {
                    window.location.href = pdfResponse.data.response.data.src
                }
            } catch (error) {
                this.isGenerating = false;
                console.error('pdf error', error.response)
            }
        },
        resetAllOtherFields()
        {
            this.selectedState = [];
            this.selectedCity = []
        },
        resetCity()
        {
            this.selectedCity = []
        },
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.set_font_title {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: black !important;
    margin-left: -12px !important;
}

.multiselect__input:disabled {
    cursor: not-allowed;
}
</style>
