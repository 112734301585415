window.DefaultUserImage = "https://s3.amazonaws.com/hsi-platform/defaultuser.png";

window._ = require('lodash');
window.Popper = require('popper.js').default;

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

window.$ = window.jQuery = require('jquery');

require('bootstrap');

window.API_BASE_URL = 'https://api.test.hsapps.org/api';
//window.API_BASE_URL = 'http://localhost:8888/api';
window.DATATABLES_BASE_URL = window.API_BASE_URL + '/datatables/'
window.USER_MANUAL_URL = 'https://docs.google.com/document/d/e/2PACX-1vSSPC3TE2kdZtllnltC9yst4L8IEUGdh_G-oQ2Bd4di4NV2lPgsX7UhClvq-ThYfCq15MYP7YLa-XJg/pub?embedded=true'

window.axios = require('axios');

//window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.defaults.baseURL = window.API_BASE_URL;

window.appConfig = require("./config").default;

import Vue from 'vue'

window.Vue = Vue;
window.Bus = new Vue();

//let appName = document.head.querySelector('meta[name="app-name"]');
//window.axios.defaults.headers.common['Hsi-App'] = appName.content;

window.moment = require('moment-timezone');
window.toastr = require('toastr');
window.toastr.options = {
    "positionClass": "toast-bottom-right"
};

window.cdnPrefix = 'https://s3.amazonaws.com/hsi-platform';
window.demoPrefix = `${window.cdnPrefix}/demo`;


import jsZip from 'jszip';

//require('pdfmake/build/pdfmake');

require('imports-loader?this=>window!pdfmake/build/vfs_fonts');

require('imports-loader?define=>false!datatables.net')(window, $);
require('imports-loader?define=>false!datatables.net-bs')(window, $);
require('imports-loader?define=>false!datatables.net-buttons')(window, $);
require('imports-loader?define=>false!datatables.net-buttons/js/buttons.print')(
    window, $);
require('imports-loader?define=>false!datatables.net-buttons/js/buttons.html5')(
    window, $);
require('imports-loader?define=>false!datatables.net-buttons-bs4')(window, $);
require('imports-loader?define=>false!datatables.net-fixedheader')(window, $);
require('imports-loader?this=>window!pdfmake/build/vfs_fonts');

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import 'datatables.net-buttons-bs/css/buttons.bootstrap.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.css';
import 'datatables.net-fixedheader-dt/css/fixedHeader.dataTables.css';
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.css';

window.JSZip = jsZip;


import VueRouter from 'vue-router'

Vue.use(VueRouter)

import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster';

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAubsQI9BF5agFCdw3i3oysyxLakHdlRuE',
        libraries: '',
    }
});
Vue.component('GmapCluster', GmapCluster)

import GlobalMixin from "./mixins/GlobalMixin";

Vue.mixin(GlobalMixin)

const App = () => import('./App.vue');

import PaperDashboard from "./plugins/paperDashboard";
import "vue-notifyjs/themes/default.css";
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(PaperDashboard);


const routes = [
    {
        path: '/',
        redirect: '/platform/projects',
        component: App,
    },
    {
        path: '/login',
        name: 'login',
        component: require('./components/Template/AppLogin.vue').default,
        meta: {guest: true},
    },
    {
        path: '/user/verify/:confirmationCode',
        component: require('./components/Template/VerifyUser.vue').default,
        props: route => ({confirmationCode: route.params.confirmationCode}),
        meta: {guest: true},
    },
    {
        path: '/forgot',
        component: require('./components/Template/ForgotPassword.vue').default,
        props: route => ({confirmationCode: route.params.confirmationCode}),
        meta: {guest: true},
    },
    {
        path: '/reset/:verificationCode',
        component: require('./components/Template/ResetPassword.vue').default,
        props: route => ({confirmationCode: route.params.confirmationCode}),
        meta: {guest: true},
    },
    {
        path: '/signup',
        name: 'signup',
        component: require('./components/Template/AppSignup.vue').default,
        meta: {guest: true},
    },
    {
        path: '/register/:invitationCode',
        name: 'signup',
        component: require('./components/Template/AppSignup.vue').default,
        props: route => ({invitationCode: route.params.invitationCode}),
        meta: {guest: true},
    },
    {
        path: '/platform',
        name: 'platform',
        component: require('./components/Template/Platform.vue').default,
        meta: {requiresAuth: true},
        redirect: '/platform/projects',
        children: [
            {
                path: 'projects',
                component: require('./components/Template/Projects.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'fieldteams',
                component: require('./components/Template/FieldTeams.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'newproject',
                component: require('./components/Template/NewProject.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'notifications',
                component: require('./components/Template/UserNotifications.vue').default,
                meta: {requiresAuth: true},
                name: 'userNotifications'
            },
            {
                path: 'user/profile',
                component: require('./components/Template/UserProfile.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'user/organisation',
                component: require('./components/Template/CreateOrganisation.vue').default,
                meta: {requiresAuth: true},
                name: 'organisationSettings'
            },
        ]
    },
    {
        path: '/project/join/:invitationCode/:email',
        component: require('./components/Template/InvitationJoiningScreen.vue').default,
        props: route => ({
            invitationType: 'project',
            invitationCode: route.params.invitationCode,
            email: route.params.email
        }),
        meta: {requiresAuth: true},
    },
    {
        path: '/field_team/join/:invitationCode/:email',
        component: require('./components/Template/InvitationJoiningScreen.vue').default,
        props: route => ({
            invitationType: 'field_team',
            invitationCode: route.params.invitationCode,
            email: route.params.email
        }),
        meta: {requiresAuth: true},
    },
    {
        path: '/organisation/join/:invitationCode/:email',
        component: require('./components/Template/InvitationJoiningScreen.vue').default,
        props: route => ({
            invitationType: 'organisation',
            invitationCode: route.params.invitationCode,
            email: route.params.email
        }),
        meta: {requiresAuth: true},
    },
    {
        path: '/project/mv/:projectId',
        component: require('./components/Template/UIComponents/SidebarPlugin/SideBarNew.vue').default,
        props: route => ({projectId: route.params.projectId}),
        meta: {requiresAuth: true},
        children: [
            {
                path: 'overview',
                component: require('./components/Template/mvdProjectOverview.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'areas',
                component: require('./components/Template/AddVaccinationArea.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'assignments',
                component: require('./components/Template/AssignAreaUsers.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'vaccination',
                component: require('./components/Template/VaccinationRecord.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'survey',
                component: require('./components/Template/Surveys.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'vaccination_area/upload',
                name: 'uploadAreaKml',
                component: require('./components/Template/UploadVaccinationArea.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'area/details/:areaId',
                name: 'viewAreaDetails',
                component: require('./components/Template/AreaDetail.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'area/edit/:areaId',
                name: 'editArea',
                component: require('./components/Template/EditArea.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'area/vaccination/list/:areaId',
                name: 'areaVaccinationList',
                component: require('./components/Template/RecordDetailList.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'area/vaccination/mapview/:areaId',
                name: 'areaVaccinationMapview',
                component: require('./components/Template/ViewOnMap.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'vaccination/list',
                name: 'projectVaccinationList',
                component: require('./components/Template/RecordDetailList.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'vaccination/mapview',
                name: 'projectVaccinationMapview',
                component: require('./components/Template/ViewOnMap.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'vaccination/outer',
                name: 'projectOuterVaccinations',
                component: require('./components/Template/OuterVaccinations.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'vaccination/outer/move/:areaId',
                name: 'projectMoveOuterVaccinations',
                component: require('./components/Template/MoveOuterVaccinationArea.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'archived',
                name: 'projectOuterVaccinations',
                component: require('./components/Template/ArchivedRecords.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/timeperiod',
                name: 'reportByTimeperiod',
                component: require('./components/Template/ReportByDates.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/location',
                name: 'reportByLocation',
                component: require('./components/Template/ReportByCity.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/area',
                name: 'reportByLocation',
                component: require('./components/Template/ReportsByArea.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/survey',
                name: 'reportBySurvey',
                component: require('./components/Template/ReportBySurvey.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/lactating',
                name: 'reportByLactating',
                component: require('./components/Template/ReportByLactating.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/young',
                name: 'reportByYoung',
                component: require('./components/Template/ReportByYoung.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'settings',
                name: 'projectSettings',
                component: require('./components/Template/ProjectSettings.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'area/survey/list/:areaId',
                name: 'areaSurveyList',
                component: require('./components/Template/SurveyRecords.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'area/survey/mapview/:areaId',
                name: 'areaSurveyMapview',
                component: require('./components/Template/SurveysViewOnMap.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'survey/list',
                name: 'projectSurveyList',
                component: require('./components/Template/SurveyRecords.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'survey/mapview',
                name: 'projectSurveyMapview',
                component: require('./components/Template/SurveysViewOnMap.vue').default,
                meta: {requiresAuth: true},
            },
        ]
    },
    {
        path: '/project/pm/:projectId',
        component: require('./components/Template/UIComponents/SidebarPlugin/PmSideBar.vue').default,
        props: route => ({projectId: route.params.projectId}),
        meta: {requiresAuth: true},
        children: [
            {
                path: 'overview',
                component: require('./components/Template/Dashboard/DpmContent.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'areas',
                component: require('./components/Template/Pm/AddPmArea.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'assignments',
                component: require('./components/Template/AssignAreaUsers.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'area/upload',
                name: 'uploadAreaKml',
                component: require('./components/Template/UploadVaccinationArea.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'area/details/:areaId',
                name: 'viewAreaDetails',
                component: require('./components/Template/AreaDetail.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'area/edit/:areaId',
                name: 'editArea',
                component: require('./components/Template/EditArea.vue').default,
                props: route => ({areaId: route.params.areaId}),
                meta: {requiresAuth: true},
            },
            {
                path: 'archived',
                name: 'projectOuterVaccinations',
                component: require('./components/Template/ArchivedRecords.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/timeperiod',
                name: 'reportByTimeperiod',
                component: require('./components/Template/ReportByDates.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/location',
                name: 'reportByLocation',
                component: require('./components/Template/ReportByCity.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/area',
                name: 'reportByLocation',
                component: require('./components/Template/ReportsByArea.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/survey',
                name: 'reportBySurvey',
                component: require('./components/Template/ReportBySurvey.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/lactating',
                name: 'reportByLactating',
                component: require('./components/Template/ReportByLactating.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'report/young',
                name: 'reportByYoung',
                component: require('./components/Template/ReportByYoung.vue').default,
                meta: {requiresAuth: true},
            },
            {
                path: 'settings',
                name: 'projectSettings',
                component: require('./components/Template/ProjectSettings.vue').default,
                meta: {requiresAuth: true},
            },
        ]
    },
]

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition)
    {
        return {x: 0, y: 0}
    }
})

import store from "./store";

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.getJwt;
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.getJwt;
            next("/platform/projects");
            return;
        }
        next();
    } else {
        next();
    }
});

window.axios.interceptors.response.use(undefined, async function (error) {
    if (error) {
        const originalRequest = error.config;
        if ((error.response.status === 400 || error.response.status === 401) && !originalRequest._retry) {
            originalRequest._retry = true;
            await store.dispatch('SetAuthError', error.response.data.response.error)
            await store.dispatch('LogOut')
            return router.push('/login')
        }
    }
})

let app = new Vue({
    router,
    render: h => h(App),
    methods: {
        navigateToMoveOuterRecords(areaId)
        {
            router.push({name: 'projectMoveOuterVaccinations', params: {areaId: areaId}})
        }
    }
}).$mount('#app');

window.moveOuterVaccinationRecords = function (areaId) {
    app.$options.methods.navigateToMoveOuterRecords(areaId);
};
