<template>
    <div class="set_self_project_res">
        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row setMarginTop">
            <div class="col-sm-12 col-md-6">
                <div class="card setShadow">
                    <div class="card-body text_left row">
                        <div class="col-sm-7">
                            <b class="set_b_font_card">Animals Counted</b>
                            <div style="font-size: 16px; font-weight: 600;">
                                {{ surveyStats.total_animal_count }}
                            </div>
                        </div>
                        <div class="col-sm-5">
                            <button v-bind:disabled="!onLine" type="submit"
                                    class="btn btn-primary" @click="surveyListView">List
                            </button>
                            <button v-bind:disabled="!onLine" type="submit"
                                    class="btn btn-primary" @click="viewProjectSurveyOnMap">Map
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row setMarginTop">
            <div class="col-xs-6 col-sm-6 col-md-2 col-xl-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Vaccinated</p>
                                        <!-- 599 -->
                                        {{ surveyStats.total_vaccinated }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2 col-xl-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Un-Vaccinated</p>
                                        <!-- 599 -->
                                        {{ surveyStats.total_unvaccinated }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2 col-xl-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Unknown</p>
                                        <!-- 599 -->
                                        {{ surveyStats.total_unknown }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2 col-xl-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Field Users</p>
                                        <!-- 599 -->
                                        {{ surveyStats.total_field_users }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2 col-xl-3">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>% Coverage overall</p>
                                        <!-- 599 -->
                                        {{ surveyStats.coverage_overall }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row setMarginTop">
            <div class="col-xs-6 col-sm-6 col-md-4 col-xl-4">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Surveyed Areas</p>
                                        {{ surveyStats.total_surveyed_area }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-4 col-xl-4">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Un-Surveyed Areas</p>
                                        {{ surveyStats.total_unsurveyed_area }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="row setTitleDiv">
                    <p>Summary by Surveyor</p>
                </div>
            </div>
            <div class="col-sm-12 col-xs-12 col-md-12 col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row set_row">
                            <div class="col-sm-12 col-xl-12 set_table_center">
                                <table id="example" class="display table-striped table-bordered select" cellspacing="0"
                                       width="100%">
                                    <thead>
                                    <tr>
                                        <th>Area</th>
                                        <th>Assigned By</th>
                                        <th>Assigned To</th>
                                        <th>Assigned On</th>
                                        <th>Status</th>
                                        <th>Vaccinated</th>
                                        <th>Un-Vaccinated</th>
                                        <th>Unknown</th>
                                        <th>% Coverage</th>
                                        <th>Note</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Edit Record Modal Satrt -->
        <div id="editRecordModal" class="modal survey-modal fade" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header edit-header">
                        <h4 class="modal-title">Edit Record</h4>
                    </div>
                    <div class="modal-body survey-record-body">
                        <form class="form-horizontal" enctype="multipart/form-data">
                            <div class="form-group row">
                                <label class="col-md-6">Area</label>
                                <label class="col-md-6">Surveyed By</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <input readonly class="form-control" id="Area" type="text" name="Area">
                                    <input class="form-control" id="record_id" type="hidden" name="record_id">
                                </div>
                                <div class="col-md-6">
                                    <input readonly class="form-control" id="surveyed_by" type="text"
                                           name="surveyed_by">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-6">Assigned On Date</label>
                                <label class="col-md-6">Vaccinated</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <input readonly class="form-control" id="Date" type="text" name="Date">
                                </div>
                                <div class="col-md-6">
                                    <input readonly class="form-control" id="Vaccinated" type="text" name="Vaccinated">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-6">Un-Vaccinated</label>
                                <label class="col-md-6">Unknown</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <input readonly class="form-control" id="unVaccinated" type="text"
                                           name="unVaccinated">
                                </div>
                                <div class="col-md-6">
                                    <input readonly class="form-control" id="unknown" type="text" name="Unknown">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-6">Assigned By</label>
                                <label class="col-md-6">Coverage</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <input readonly class="form-control" id="User" type="text" name="User">
                                </div>
                                <div class="col-md-6">
                                    <input readonly class="form-control" id="Coverage" type="text" name="Coverage">
                                </div>
                            </div>
                            <hr>
                            <div class="form-group row">
                                <label class="col-md-6">Note</label>

                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <input class="form-control" id="note" type="text" name="note" v-model="note">
                                </div>

                            </div>
                        </form>
                    </div>
                    <hr>
                    <div class="row set_footer">
                        <div class="col-md-4" style="">
                            <!--                            <button type="button" class="btn btn-default delete_btn" data-dismiss="modal"
                                                                @click="deleteRecordDetails">
                                                            <span class="ti-trash" style="cursor: pointer;"></span>
                                                        </button>-->
                        </div>
                        <div class="col-md-8" style="text-align:right">
                            <button type="button" class="btn btn-default" style="background-color: #eb6532 !important;"
                                    id="close-record" data-dismiss="modal">Close
                            </button>
                            <button type="button" class="btn btn-default" style="background-color: #2a9055 !important;"
                                    id="save-record" data-dismiss="modal" @click="updateRecordDetails">Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import mixin from '../../mixins/GlobalMixin';
import swal from 'sweetalert2';
import {Select, Option} from 'element-ui';
import VOffline from 'v-offline';

export default {
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        VOffline
    },
    props: {
        projectId: {
            type: Number,
        }
    },
    data()
    {
        return {
            surveyStats: {
                total_vaccinated: 0,
                total_unvaccinated: 0,
                total_animal_count: 0,
                coverage_overall: "0",
                total_surveyed_area: 0,
                total_unsurveyed_area: 0,
                total_field_users: 0
            },
            projectName: "",
            table: null,
            project_id: "",
            note: ''
        }
    },
    mixins: [mixin],
    methods: {
        surveyListView()
        {
            localStorage.setItem("SurveyAreaName", '')
            localStorage.setItem("SurveyAreaId", 0)
            localStorage.setItem("selectedArea", 0)
            localStorage.setItem("surveyProjectLevelViewList", 1)
            this.$router.push({name: 'projectSurveyList', params: {projectId: this.projectId}})
        },
        viewProjectSurveyOnMap()
        {
            localStorage.setItem('surveyProjectLevelViewMap', 1);
            //window.location = '/project/surveys_view_on_map/' + this.projectId;
            this.$router.push({name: 'projectSurveyMapview', params: {projectId: this.projectId}})
        },
        backVaccination()
        {
            window.location = '/project/surveys/' + this.project_id;
        },
        init()
        {
            this.getSurveyStats();
            this.getSurveyRecords();
        },
        getSurveyRecords()
        {
            let vm = this;
            let jwt = document.head.querySelector('meta[name="sesjwt"]');

            if (vm.table != null) {
                vm.table.destroy();
            }

            vm.table = $('#example').DataTable({
                "scrollX": true,
                responsive: true,
                dom: 'lBfrtip',
                "bInfo": false,
                'columns': [
                    null,
                    null,
                    null,
                    null,
                    null,
                    {'searchable': false},
                    {'searchable': false},
                    {'searchable': false},
                    {'searchable': false},
                    null,
                    null
                ],
                buttons: [
                    {
                        extend: 'csv',
                        text: 'csv',
                        className: 'btn btn-default',
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                        }
                    },
                    {
                        extend: 'pdf',
                        title: 'pdf',
                        className: 'btn btn-default',
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                        }
                    },
                ],
                'fnRowCallback': function (nRow, aData, iDisplayIndex) {
                    var lastTd = $('td:eq(10)', nRow)[0];
                    $('td:eq(10)', nRow).html('<span class="ti-pencil editRecord" style="padding: 10px;cursor: pointer;"></span>');
                    console.log(nRow);
                    //console.log(lastTd);
                    console.log(aData[10]);
                    lastTd.lastChild.dataset.data = aData;
                    //vm.table.column(6).visible(false);
                    return nRow;
                },
                "processing": true,
                "serverSide": true,
                "ajax": {
                    "url": window.DATATABLES_BASE_URL + "survey/details",
                    "type": "POST",
                    "headers": {
                        "Authorization": "Bearer " + vm.$store.getters.getJwt
                    },
                    "data": {
                        project_id: vm.project_id,
                        selected_area: vm.areaId,
                    },
                    "dataSrc": function (json) {
                        var rowData = json.data;
                        /*console.log("rowData: " + rowData);
                        for (var i = 0; i < rowData.length; i++) {
                            //rowData[i][9] = '<span class="ti-eye editRecord" style="padding: 10px;cursor: pointer;"></span>';
                            rowData[i][10] = '<span class="ti-pencil editRecord" style="padding: 10px;cursor: pointer;"></span>';
                        }*/
                        return rowData;
                    }
                },
                lengthMenu: [[5, 10, 25], [5, 10, 25]],
                pageLength: 5,
            });
        },
        getSurveyStats()
        {
            let vm = this;
            axios.get('/stats/survey/' + vm.project_id).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.surveyStats = response.data.response.data.stats;
                }
            }).catch(error => {

            });

        },
        downloadExcel()
        {
            this.showAlert('Error', "Under Construction", false);
        },
        downloadPdf()
        {
            this.showAlert('Error', "Under Construction", false);
        },
        updateRecordDetails()
        {
            var recordId = document.getElementById("record_id").value;
            axios.post('/survey/update/record/' + recordId, {
                note: this.note,
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    var editRecordModal = document.getElementById("editRecordModal");
                    editRecordModal.style.display = "none";
                    this.showAlert('Success', response.data.response.error.msg, true);
                    setTimeout(function () {
                        window.location.reload();
                    }, 3000);
                }
            }).catch(error => {
                this.showAlert('Error', error.response.data.response.error.error_msg, false);
            });
        },
        deleteRecordDetails()
        {
            let vm = this;
            swal({
                title: 'Alert!',
                text: 'Are you sure you want to archive record ? ',
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'yes',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    var recordId = document.getElementById("record_id").value;
                    axios.post('/survey/archive/' + recordId, {}).then(response => {
                        if (response.data.response.error.error_code == 0) {
                            var editRecordModal = document.getElementById("editRecordModal");
                            editRecordModal.style.display = "none";
                            vm.showAlert('Success', response.data.response.error.msg, true);
                        }
                    }).catch(error => {
                        vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                    });
                }
            });
        },
    },
    created()
    {
        var self = this;
        setTimeout(function () {
            var editIcon = document.querySelectorAll('.editRecord');
            var editRecordModal = document.getElementById("editRecordModal");
            for (var i = 0; i < editIcon.length; i++) {
                editIcon[i].addEventListener('click', function () {
                    editRecordModal.style.display = "block";
                    let data = this.getAttribute("data-data");
                    var res = data.split(",");
                    document.getElementById("surveyed_by").value = res[2];
                    document.getElementById("Date").value = res[3];
                    document.getElementById("Vaccinated").value = res[5];
                    document.getElementById("unVaccinated").value = res[6];
                    document.getElementById("unknown").value = res[7];
                    document.getElementById("Coverage").value = res[8];
                    document.getElementById("note").value = res[9];
                    document.getElementById("Area").value = res[0];
                    document.getElementById("User").value = res[1];
                    document.getElementById("record_id").value = res[10];
                }, false);
            }
            let closeRecords = document.getElementById("close-record")
            closeRecords.onclick = function () {
                editRecordModal.style.display = "none";
            }
        }, 2000);
    },
    mounted()
    {
        let vm = this;
        vm.project = JSON.parse(localStorage.project);
        Bus.$emit("projectype", vm.project);
        vm.projectName = vm.project.name;
        vm.project_id = vm.project.id;
        vm.init();
    }
}

</script>
<style lang="scss" scoped>

.setWhite {
  background-color: white;
}

.card .card-body {
  text-align: center;
}

.setMarginTop {
  margin-top: 11px;
  /* margin-bottom: 11px; */
}

.setTitleDiv {
  height: 40px;
  margin-left: calc(10% - 10%) !important;
  border-radius: 25px;
  text-align: left;
  display: block !important;
  padding: 6px 0 0 0px;
  margin-bottom: 20px;
}

.setTitleDiv p {
  font-size: 17px;
  font-weight: 600;
  color: black;
}

.setShadow {
  box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
}

.card .numbers p {
  color: #9A9A9A;
  font-size: 14px;
  margin: 0;
}

.card .numbers {
  font-size: 1.5em !important;
}

.set_row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.survey-modal {
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  padding: 80px;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.66); /* Black w/ opacity */
  display: none;
  opacity: 1 !important;
}

.survey-record-body {
  padding: 1rem !important;
  color: #000;
  margin-bottom: -60px !important;
}

.modal-footer {
  border-top: 1px solid #DDDDDD;
  padding: 10px !important;
}

.modal-title {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.close-edit-records {
  position: absolute;
  top: 15px;
  right: 35px;
  font-size: 40px;
  font-weight: bold;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 900px !important;
    margin: 1.75rem auto;
  }
}

.delete_btn {
  background-color: red !important;
}

.set_footer {
  padding: 15px;
}

.edit-header {
  height: 60px !important;
  margin-top: -13px !important;;
}

.form-group {
  margin-bottom: 0px !important;
  position: relative;
}

label {
  display: inline-block;
  margin-bottom: 0.3rem !important;
}

.form-control {
  margin-bottom: 14px !important;
}

.btn-group .btn + .btn {
  margin-left: 5px !important;
}

.set_b_font_card {
  font-size: 17px !important;
  color: #9a9a9a !important;
}

</style>
