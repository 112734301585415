<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox ">
                <div class="ibox-content">
                    <div class="panel-heading">
                        <h2 class="set_font_title">
                            Report By Lactating
                        </h2>
                    </div>
                </div>
                <el-row>
                    <div class="card p-3">
                        <div>
                            <el-button
                                @click="generatePDF"
                                :loading="isGenerating"
                                class="btn btn-primary text-white mt-3 mr-1"
                            >
                                Generate PDF
                            </el-button>
                        </div>
                    </div>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import { Row, Col, Button } from "element-ui";

export default {
    name: "ReportByLactating",
    props: {
        projectId: {
            type: [Number, String],
            required: true
        }
    },
    data: () => ({
        isGenerating: false
    }),
    components: {
        [Row.name]: Row,
        [Col.name]: Col,
        [Button.name]: Button,
    },
    mounted() {
        let vm = this;
        vm.project = JSON.parse(localStorage.project);
        Bus.$emit("projectype", vm.project);
    },
    methods: {
        async generatePDF() {
            this.isGenerating = true;
            const URL = `/pm/reports/generate/report/${this.projectId}`;
            const body = {
                report_type: 5
            };
            try {
                const { data } = await axios.post(URL, body);
                const downloadURL = `/pm/reports/download/pdf/${data.response.data.report_id}`;
                const pdfResponse = await axios.get(downloadURL);
                this.isGenerating = false;
                if (pdfResponse.data.response.data === null) {
                    this.showAlert(
                        "Oops!",
                        pdfResponse.data.response.error.msg,
                        false
                    );
                } else {
                    window.location.href = pdfResponse.data.response.data.src;
                }
            } catch (error) {
                this.isGenerating = false;
                console.error("pdf error", error.response);
            }
        }
    }
};
</script>

<style scoped>
.set_font_title {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: black !important;
    margin-left: -12px !important;
}
.multiselect__input:disabled {
    cursor: not-allowed;
}
</style>