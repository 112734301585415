<template>
    <div class="card img-left"
         :data-background-color="color">

        <div class="card-header">
            <div class="project-img"
                 v-bind:style="{width: '100px', height: '100px', backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat', backgroundImage : 'url('+getProjectIcon+')', backgroundSize: 'contain'}">
                <!--                <img :src="projectImage"/>-->
            </div>
            <div class="project-details">
                <router-link :to="projectDetails" tag="p" replace>
                    <p class="project-title">
                        {{ title }}
                    </p>
                </router-link>
                <p class="project-info-text">Created On: {{ createdOn }}</p>
                <p class="project-info-text">Role: {{ getUserRole }}</p>
                <p class="project-info-text">Last Active: {{ lastActive }}</p>
                <p class="project-info-text">Animals Treated: {{ animalsTreated }}</p>
            </div>
            <div class="project-properties" v-if="projectType=='MV' || projectType=='PM'">
                <p style="text-align: center;" v-if="projectType=='MV'"><img :src="mvdlogo" class="logo_img"/></p>
                <p style="text-align: center;" v-if="projectType=='PM'"><img :src="dpmlogo" class="logo_img"/></p>
                <el-tooltip v-if="isOrgProject" :content="orgName"
                            :open-delay="300"
                            placement="right">
                    <p class="blue-tag" v-if="isOrgProject">ORG</p>
                </el-tooltip>
                <!--                <a href="#" class="green-tag">LIVE</a>-->
            </div>
            <div class="project-properties" v-else>
                <p class="brown-tag">{{ projectType }}</p>
                <el-tooltip v-if="isOrgProject" :content="orgName"
                            :open-delay="300"
                            placement="right">
                    <p class="blue-tag" v-if="isOrgProject">ORG</p>
                </el-tooltip>
                <!--                <a href="#" class="green-tag">LIVE</a>-->
            </div>
        </div>

        <hr>
        <div class="project-card-footer">
            <p class="project-contributors">Field Users: {{ fieldUsers }}</p>
            <!--            <p class="project-contributors">Contributors: {{contributors}}</p>
                        <p class="project-viewers">Viewers: {{viewers}}</p>-->
        </div>
    </div>
</template>
<script>

import {Tooltip} from 'element-ui';
import dpmlogo from '../../../../assets/images/pm-logo.png';
import mvdlogo from '../../../../assets/images/mv-logo.png';

import mvLogo from '../../../../assets/images/mv_logo.png';
import pmLogo from '../../../../assets/images/pm_logo.png';

export default {
    components: {
        [Tooltip.name]: Tooltip
    },
    name: 'card',
    props: {
        projectId: {
            type: Number,
        },
        animalsTreated: {
            type: Number,
        },
        fieldUsers: {
            type: Number,
        },
        title: {
            type: String,
            description: 'Card title',
            default: 'Project Title'
        },
        createdOn: {
            type: String,
            default: 'N/A',
        },
        userRole: {},
        lastActive: {
            type: String,
            default: 'N/A',
        },
        contributors: {
            type: String,
            default: '0',
        },
        viewers: {
            type: String,
            default: '0',
        },
        color: {
            type: String,
            description: 'Card color (orange|yellow|red|green|blue|"")'
        },
        isOrgProject: {
            type: Boolean,
            default: false
        },
        orgName: {
            type: String,
        },
        projectImage: {
            type: String,
        },
        projectType: {
            type: String,
        },
        dpmlogo: {
            type: String,
            default: dpmlogo,
        },
        mvdlogo: {
            type: String,
            default: mvdlogo,
        },

    },
    computed: {
        getUserRole: function () {
            if (this.$props.userRole != 'OWNER') {
                let rolesString = '';
                for (let i = 0; i < this.$props.userRole.length; i++) {
                    rolesString = rolesString + this.$props.userRole[i].role_name + ' | ';
                }
                return rolesString.substring(0, rolesString.length - 2);
            }
            return this.$props.userRole;
        },
        projectDetails: function () {
            if (this.$props.projectType == 'MV') {
                return '/project/mv/' + this.projectId + '/overview';
            } else {
                return '/project/pm/' + this.projectId + '/overview';
            }
        },
        getProjectIcon: function () {
            if (this.$props.projectImage.includes("mv_logo.png")) {
                return mvLogo;
            } else
                if (this.$props.projectImage.includes("pm_logo.png")) {
                    return pmLogo;
                } else {
                    return this.$props.projectImage;
                }
        }
    }
}
</script>


<style scoped>

p {
    cursor: default;
}

.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.logo_img {
    height: 30px;
    border-radius: 0px !important;
}

.project-img {
    margin-right: 15px;
}

.project-details {
    margin-bottom: 15px;
}

.project-title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
    cursor: pointer;
}

.project-info-text {
    margin: 0;
}

.project-contributors {
    float: left;
}

.project-viewers {
    float: right;
}

.project-card-footer {
    margin-left: 15px;
    margin-right: 15px;
}

.project-properties {
    align-self: end;
}

/* Pradeep Start */
@media only screen  and (max-width: 320px) {
    .project-properties { /* css add for responsive image MVD and DPM  */
        margin-left: -15px;
    }
}

/* Pradeep End */
</style>
