import defaultLogo from './assets/images/HSIApps-Trnprnt-green.png';
export default {
    appName: 'HSAPPS',
    //logo: 'https://s3.amazonaws.com/hsi-platform/HSII_LogoV_India_3435_4Cc-1.png',
    logo: defaultLogo,
    title: 'MVD App',
    project: 'mvd',
    nav: [
        {
            name: 'Dashboard',
            icon: 'ti-dashboard',
            path: '/dashboard',

        },
        {
            name: 'Projects',
            icon: 'nc-icon nc-bank',
            path: '/projects',

        },
        {
            name: 'Assignments',
            icon: 'ti-check-box',
            id: 'third-party',
            children: [
                {
                    name: 'Manage Teams',
                    path: '/fieldteams',
                },
                {
                    name: 'Assign Teams',
                    path: '/project/assign_area_users',
                },
            ],
        },
        {
            name: 'Organisation',
            icon: 'nc-icon nc-book-bookmark',
            id: 'third-party',
            children: [
                {
                    name: 'Manage',
                    path: '/settings/organisation',
                },
                {
                    name: 'Invitations',
                    path: '/settings/organisation/invitations',
                },
            ],
        },
        {
            name: '3rd Party Libraries',
            icon: 'nc-icon nc-book-bookmark',
            id: 'third-party',
            children: [
                {
                    name: 'DataTable',
                    path: '/demo/datatable',
                },
                {
                    name: 'Vue Multiselect',
                    path: '/demo/multiselect',
                },
                {
                    name: 'Image Upload to S3',
                    path: '/demo/imageupload'
                }
            ],
        },
        {
            name: 'Pages',
            id: 'pages',
            icon: 'nc-icon nc-book-bookmark',
            children: [
                {
                    name: 'Timeline',
                    path: '/demo/pages/timeline',
                },
                {
                    name: 'User Profile',
                    path: '/demo/pages/userprofile',


                },
                {
                    name: 'Login',
                    path: '/login',

                },
                {
                    name: 'Register',
                    path: '/register',

                },
                {
                    name: 'Lock',
                    path: '/demo/pages/lock',

                },
            ],
        },
        {
            name: 'Components',
            id: 'components',
            icon: 'nc-icon nc-layout-11',
            children: [
                {
                    name: 'Buttons',
                    path: '/demo/components/buttons',

                },
                {
                    name: 'Grid System',
                    path: '/demo/components/gridsystem',

                },
                {
                    name: 'Panels',
                    path: '/demo/components/panels',

                },
                {
                    name: 'Sweet Alert',
                    path: '/demo/components/sweetalert',

                },
                {
                    name: 'Notifications',
                    path: '/demo/components/notifications',

                },
                {
                    name: 'Icons',
                    path: '/demo/components/icons',

                },
                {
                    name: 'Typography',
                    path: '/demo/components/typography',

                }],
        },
        {
            name: 'Forms',
            id: 'forms',
            icon: 'nc-icon nc-ruler-pencil',
            children: [
                {
                    name: 'Regular Forms',
                    path: '/demo/forms/regular',

                },
                {
                    name: 'Extended Forms',
                    path: '/demo/forms/extended',

                },
                {
                    name: 'Validation Forms',
                    path: '/demo/forms/validation',

                },
                {
                    name: 'Wizard',
                    path: '/demo/forms/wizard',

                },
            ],
        },
        {
            name: 'Table List',
            id: 'table-list',
            icon: 'nc-icon nc-single-copy-04',
            collapsed: true,
            children: [
                {
                    name: 'Regular Tables',
                    path: '/demo/tables/regular',

                },
                {
                    name: 'Extended Tables',
                    path: '/demo/tables/extended',


                },
                {
                    name: 'Paginated Tables',
                    path: '/demo/tables/paginated',


                },
            ],
        },
        {
            name: 'Maps',
            id: 'maps',
            icon: 'nc-icon nc-pin-3',
            children: [
                {
                    name: 'Google Maps',
                    path: '/demo/maps/google',


                },
                {
                    name: 'Full Screen Maps',
                    path: '/demo/maps/full-screen',


                },
                {
                    name: 'Vector Maps',
                    path: '/demo/maps/vector-map',


                },
            ],
        },
        {
            name: 'Widgets',
            icon: 'nc-icon nc-box',
            path: '/demo/widgets',


        },
        {
            name: 'Charts',
            icon: 'nc-icon nc-chart-bar-32',
            path: '/demo/charts',


        },
        {
            name: 'Applications',
            id: 'table-list',
            icon: 'nc-icon nc-chart-bar-32',
            collapsed: true,
            path: '/admin/application',

        },
        {
            name: 'Accounts',
            icon: 'nc-icon nc-chart-bar-32',
            path: '/admin/account',

        },
        /*{
         name: 'Calendar',
         icon: 'nc-icon nc-calendar-60',
         path: '/demo/calendar',


         },*/
        /* praadeep */
        {
            name: 'Add & Assign',
            icon: 'nc-icon nc-book-bookmark',
            id: 'third-party',
            children: [
                {
                    name: 'Catch Release Users',
                    path: '#',
                },
                {
                    name: 'Clinic Users',
                    path: '#',
                },
                {
                    name: 'Users',
                    path: '#',
                },
            ],
        },
        {
            name: 'Dog Records',
            icon: 'ti-view-list-alt',
            id: 'third-party',
            children: [
                {
                    name: 'Not Operated',
                    path: '#',
                },
                {
                    name: 'Operated in Clinic',
                    path: '#',
                },
                {
                    name: 'Operated in Clinic',
                    path: '#',
                },
                {
                    name: 'Post Op Comp/Treatment',
                    path: '#',
                },
                {
                    name: 'Release Fate',
                    path: '#',
                },
            ],
        },
        {
            name: 'Overall Records',
            icon: 'ti-view-list-alt',
            id: 'third-party',
            children: [
                {
                    name: 'Dog per locality',
                    path: '#',
                },
                {
                    name: 'Dog on Map',
                    path: '#',
                },
                {
                    name: 'Microchip ID',
                    path: '#',
                },

            ],
        },
        {
            name: 'Setting',
            icon: 'nc-icon nc-settings',
            id: 'third-party',
            children: [
                {
                    name: 'General',
                    path: '#',
                },
                {
                    name: 'Deleted Records',
                    path: '#',
                },

            ],
        },
        //MVD Start
        {
            name: 'Overview',
            icon: 'ti-dashboard',
            path: '/overview',
            p:'/overview',
        },
        {
            name: 'Areas',
            icon: 'ti-map-alt',
            path: 'areas',
            p:'/areas',
        },
        {
            name: 'Assignments',
            icon: 'ti-check-box',
            path: 'assignments',
            p:'/assignments',
        },
        {
            name: 'Records',
            icon: 'ti-view-list-alt',
            id: 'third-party',
            children: [
                {
                    name: 'Vaccination',
                    path: 'vaccination',
                    p:'/vaccination',
                },
                {
                    name: 'Out-of-Area Vaccinations',
                    path: 'vaccination/outer',
                    p:'/vaccination/outer',
                },
                {
                    name: 'Surveys',
                    path: 'survey',
                    p:'/survey',
                },
                {
                    name: 'Archived',
                    path: 'archived',
                    p:'/archived',
                },
            ],
        },
        {
            name: 'Reports',
            icon: 'ti-receipt',
            id: 'third-party',
            children: [
                {
                    name: 'Date Range',
                    path: 'report/timeperiod',
                    p:'/report/timeperiod',
                },
                {
                    name: 'City/District/Village',
                    path: 'report/location',
                    p:'/report/location',
                },
                {
                    name: 'Area',
                    path: 'report/area',
                    p:'/report/area',
                },
                {
                    name: 'Survey',
                    path: 'report/survey',
                    p:'/report/survey',
                },
                {
                    name: 'Lactating',
                    path: 'report/lactating',
                    p:'/report/lactating',
                },
                {
                    name: 'Young',
                    path: 'report/young',
                    p:'/report/young',
                },
            ],
        },
        {
            name: 'Settings',
            icon: 'nc-icon nc-settings',
            path: 'settings',
            p:'/settings',
        },
        //MVD End
        //DPM Srart
        {
            name: 'Overview',
            icon: 'ti-dashboard',
            path: '/project/details',

        },
        {
            name: 'Assignments',
            icon: 'ti-check-box',
            path: '/project/catch_rlease_user',

        },
        {
            name: 'Records',
            icon: 'ti-view-list-alt',
            id: 'third-party',
            children: [
                {
                    name: 'Not Operated',
                    path: '/project/not_operated',
                },
                {
                    name: 'Operated',
                    path: '/project/operated',
                },
                {
                    name: 'Treatment Status',
                    path: '/project/treatment_status',
                },
                {
                    name: 'Ready for Release',
                    path: '/project/ready_for_release',
                },
                {
                    name: 'Released',
                    path: '/project/released',
                },
                {
                    name: 'Archive',
                    path: '/catch_release/archived/records',
                }
            ],
        },
        {
            name: 'Reports',
            icon: 'ti-bar-chart-alt',
            id: 'third-party',
            children: [
                {
                    name: 'Locality',
                    path: '/project/locality',
                },
                {
                    name: 'Map',
                    path: '/project/dpm_report_map',
                },
                {
                    name: 'Microchip',
                    path: '/project/microchip',
                },
            ],
        },
        {
            name: 'Settings',
            icon: 'nc-icon nc-settings',
            path: '/pm/edit/project',
        }
        //DPM End

        /* End */
    ],
};
