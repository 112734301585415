<template>
    <div>
        <div class="top-bar set_msg">
            <h5 style="margin: 0;margin-left:40px;margin-top: 12px;">
                Organisation
                <template v-if="organisation!=null">
                    <span v-if="organisation.verified==0"> (Pending Approval)</span>
                </template>
                <template v-if="organisation!=null"><span v-if="organisation.verified==1"> (Verified)</span>
                </template>
                <a class="btn-rotate help-icon" @click="showUrl()">
                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                </a>
            </h5>
        </div>
        <hr>
        <template v-if="organisation!=null">
            <div class="alert alert-info" v-if="organisation.verified==0">
                <span><b>Thank you! </b>Your organisation request has been submitted to the super admin successfully and is waiting for approval from <b>HSI</b>.</span><br>
                <span>for further detail and query contact <a target="_blank"
                                                              href="mailto:admin@hsiapps.org"><b>admin@hsiapps.org</b></a></span>
            </div>
            <div class="alert alert-success" v-if="organisation.verified==1">
                <span>Your organisation is verified by <b>HSI</b>.</span>
            </div>
            <div class="alert alert-danger" v-if="organisation.verified==2">
                <span><b>Sorry! </b>Your organisation request has been rejected by the super admin or <b>HSI</b>.</span><br>
                <span>for further detail and query contact <a target="_blank"
                                                              href="mailto:admin@hsiapps.org"><b>admin@hsiapps.org</b></a></span>
            </div>
        </template>
        <div class="card">
            <div class="card-body">
                <div class="row set_row">
                    <div class="col-md-4 col-sm-8" style="margin-bottom: 25px;">
                        <template v-if="organisation!=null && !uploadNew">
                            <div class="project-img">
                                <img :src="organisation.logo_url"/>
                            </div>
                            <template v-if="organisation.verified==1">
                                <button type="button" class="btn btn-primary btn-sm" v-on:click="toggleUploadNew">Change
                                </button>
                            </template>
                        </template>
                        <template v-if="uploadNew">
                            <p>Logo image ({{ uploadingLabel }})</p>
                            <image-upload
                                    :target="imageUploadApiUrl"
                                    selector="example"
                                    :auto-push="true">
                            </image-upload>
                            <template v-if="organisation!=null">
                                <template v-if="organisation.logo_url!=''">
                                    <button v-if="organisation!=null" type="button" class="btn btn-primary btn-sm"
                                            v-on:click="toggleUploadNew">Cancel
                                    </button>
                                </template>
                            </template>
                            <br/>
                        </template>
                    </div>
                </div>

                <div class="row set_row">
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                        :disabled="model.nameDisabled"
                                        type="text"
                                        label="Name*"
                                        name="Name"
                                        v-validate="modelValidations.orgName"
                                        :error="getError('Name')"
                                        v-model="model.orgName">
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                        :disabled="model.inputDisabled"
                                        type="textarea"
                                        label="Website"
                                        name="Website"
                                        placeholder="http://example.com"
                                        v-validate="modelValidations.orgWebsite"
                                        :error="getError('Website')"
                                        v-model="model.orgWebsite">
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                        :disabled="model.inputDisabled"
                                        type="text"
                                        label="Email"
                                        name="Email"
                                        v-validate="modelValidations.orgEmail"
                                        :error="getError('Email')"
                                        v-model="model.orgEmail">
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="row set_row">
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                        :disabled="model.inputDisabled"
                                        type="text"
                                        label="Country"
                                        v-model="model.orgCountry">
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                        :disabled="model.inputDisabled"
                                        type="text"
                                        label="State"
                                        v-model="model.orgState">
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                        :disabled="model.inputDisabled"
                                        type="text"
                                        label="City"
                                        v-model="model.orgCity">
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="row set_row">
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                        :disabled="model.inputDisabled"
                                        textarea
                                        type="text"
                                        placeholder="Enter some description here..."
                                        label="About*"
                                        name="About"
                                        v-validate="modelValidations.orgAbout"
                                        :error="getError('About')"
                                        v-model="model.orgAbout">
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                        :disabled="model.inputDisabled"
                                        textarea
                                        type="text"
                                        label="Address"
                                        name="Address"
                                        v-model="model.orgAddress">
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                        :disabled="model.inputDisabled"
                                        type="text"
                                        label="Zip Code"
                                        name="Zip Code"
                                        v-validate="modelValidations.orgZipCode"
                                        :error="getError('Zip Code')"
                                        v-model="model.orgZipCode">
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="row set_row">
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                        :disabled="model.inputDisabled"
                                        type="text"
                                        label="Contact"
                                        name="Contact"
                                        v-validate="modelValidations.orgContact"
                                        :error="getError('Contact')"
                                        v-model="model.orgContact">
                                </fg-input>
                            </div>
                        </fieldset>
                        <div v-if="organisation == null  || organisation.verified==2">
                            <span>Upload Documents</span><br/>
                            <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
                            <button :disabled="isUploadingDocument" v-on:click="submitFile()" class="btn btn-info">
                                {{ getUploadStatus }}
                            </button>
                        </div>
                        <div v-if="attachments.documents.length>0">
                            <h5 style="margin: 0;">Uploaded Documents</h5>
                            <div v-for="(doc, index) in attachments.documents" :key="index">
                                <i class="nc-icon nc-check-2"></i>
                                <a class="a_color" :href="doc.src">{{ doc.file_name }}</a><br/>
                            </div>
                        </div>
                    </div>
                </div>
                <!--    Pradeep End   -->
                <div class="row">
                    <div class="col-sm-12">
                        <template v-if="organisation!=null">
                            <template v-if="organisation.verified==0">
                                <button disabled type="button"
                                        class="btn btn-round btn-info">Already Submitted
                                </button>
                            </template>
                            <template v-else-if="organisation.verified==1">
                                <button v-if="onLine" v-bind:disabled="model.isDisabled" v-on:click="validateAndSubmit"
                                        type="button"
                                        class="btn btn-round btn-info">Save Changes
                                </button>
                                <button v-else v-bind:disabled="true" v-on:click="validateAndSubmit" type="button"
                                        class="btn btn-round btn-info">Save Changes
                                </button>
                            </template>
                            <template v-else-if="organisation.verified==2">
                                <button v-if="onLine" v-bind:disabled="model.isDisabled" v-on:click="validateAndSubmit"
                                        type="button"
                                        class="btn btn-round btn-info">Submit
                                </button>
                                <button v-else v-bind:disabled="true" v-on:click="validateAndSubmit" type="button"
                                        class="btn btn-round btn-info">Submit
                                </button>
                            </template>
                        </template>
                        <template v-else>
                            <button v-if="onLine" v-bind:disabled="model.isDisabled" v-on:click="validateAndSubmit"
                                    type="button"
                                    class="btn btn-round btn-info">Submit
                            </button>
                            <button v-else v-bind:disabled="true" v-on:click="validateAndSubmit" type="button"
                                    class="btn btn-round btn-info">Submit
                            </button>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body mt-2">
                <div class="row set_row">
                    <div class="col-md-6 col-sm-12 right-border left-contain">
                        <h5 class="set_font_title">Drugs/Vaccines</h5>
                        <div class="row" v-for="(item, index) in drugData" :key="index">
                            <div class="col-md-6 col-sm-6">
                                <p class="animal_name">{{ item.vaccination_drug_name }}</p>
                            </div>
                            <div class="col-md-6 col-sm-6 icon-main">
                                <span class="ti-pencil icon-pencil" @click="updateDrug(item)"></span>
                                <span class="ti-trash icon-trash" @click="deleteDrug(item.id)"></span>
                            </div>
                        </div>
                        <div class="add-drugs-animals-button">
                            <button type="button" class="btn btn-default" style="background-color: #2a9055 !important;"
                                    @click="addVaccinationDrug">Add Vaccination/Drug
                            </button>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 left-contain">
                        <h5 class="set_font_title">Species</h5>
                        <div class="row" v-for="(item, index) in animalsData" :key="index">
                            <div class="col-md-6 col-sm-6">
                                <p class="animal_name">{{ item.species_name }}</p>
                            </div>
                            <div class="col-md-6 col-sm-6 icon-main">
                                <span class="ti-pencil icon-pencil" @click="updateAnimal(item)"></span>
                                <span class="ti-trash icon-trash" @click="deleteAnimal(item.id)"></span>
                            </div>
                        </div>
                        <div class="add-drugs-animals-button">
                            <button type="button" class="btn btn-default" style="background-color: #2a9055 !important;"
                                    @click="showAnimalModal">Add Species
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="animalModal" class="modal animal-modal fade" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content animle-modal-content">
                    <div class="modal-header animal-header">
                        <h4 class="modal-title"> {{ allowEditAnimal ? 'Edit' : 'Add' }} Species</h4>
                    </div>
                    <div class="modal-body animal-modal-body">
                        <div class="form-group row animal-row">
                            <label>Species Name</label><br>
                            <input class="form-control col-sm-12" type="text" name="animal-name" v-model="animal_name">
                            <br>
                            <p>(Note: This will make new species type visible to entire organisation)</p>
                        </div>
                    </div>
                    <div class="animal-footer">
                        <button type="button" class="btn btn-default" style="background-color: #eb6532 !important;"
                                data-dismiss="modal" @click="hideAnimalModal">Close
                        </button>
                        <button v-bind:disabled="!onLine" type="button" class="btn btn-default"
                                style="background-color: #2a9055 !important;" data-dismiss="modal"
                                @click="updateAnimalData"> {{ allowEditAnimal ? 'Edit' : 'Add' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="durgModal" class="modal animal-modal fade" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content animle-modal-content">
                    <div class="modal-header animal-header">
                        <h4 class="modal-title">Edit Vaccination/Drug</h4>
                    </div>
                    <div class="modal-body animal-modal-body">
                        <div class="form-group row animal-row">
                            <label>Drug/Vaccine Name</label><br>
                            <input class="form-control col-sm-12" type="text" name="animal-name" v-model="vaccination_drug_name">
                            <br>
                            <p>(Note: This will make new Drug/Vaccine visible to entire organisation)</p>
                        </div>
                    </div>
                    <div class="animal-footer">
                        <button type="button" class="btn btn-default" style="background-color: #eb6532 !important;"
                                data-dismiss="modal" @click="hideDrugModal">Close
                        </button>
                        <button v-bind:disabled="!onLine" type="button" class="btn btn-default"
                                style="background-color: #2a9055 !important;" data-dismiss="modal" @click="editDrug">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="addDrugModal" class="modal animal-modal fade" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content animle-modal-content">
                    <div class="modal-header animal-header">
                        <h4 class="modal-title">Add Vaccination/Drug</h4>
                    </div>
                    <div class="modal-body animal-modal-body">
                        <div class="form-group row animal-row">
                            <label>Drug/Vaccine Name</label><br>
                            <input class="form-control col-sm-12" type="text" name="animal-name" v-model="add_drug_name">
                            <br>
                            <p>(Note: This will make new Drug/Vaccine visible to entire organisation)</p>
                        </div>
                    </div>
                    <div class="animal-footer">
                        <button type="button" class="btn btn-default" style="background-color: #eb6532 !important;"
                                data-dismiss="modal" @click="hideAddDrugModal">Close
                        </button>
                        <!--  This save button code
                           v-bind:disabled="!onLine"
                        -->
                        <button type="button" class="btn btn-default" style="background-color: #2a9055 !important;"
                                data-dismiss="modal" @click="addDrug">Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <help-modal ref="helpModal"></help-modal>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';

import mixin from '../../mixins/GlobalMixin';
import VOffline from 'v-offline';
import ImageUpload from '../ImageUpload.vue';
import swal from 'sweetalert2';

export default {
    components: {
        'image-upload': ImageUpload,
        VOffline
    },
    data: function () {
        return {
            model: {
                orgName: '',
                orgWebsite: '',
                orgAbout: '',
                orgAddress: '',
                orgCountry: '',
                orgState: '',
                orgCity: '',
                orgZipCode: '',
                orgContact: '',
                orgEmail: '',
                isDisabled: false,
                inputDisabled: false,
                nameDisabled: false
            },
            modelValidations: {
                orgName: {
                    required: true,
                },
                orgAbout: {
                    required: true,
                },
                orgWebsite: {
                    url: true,
                },
                orgZipCode: {
                    numeric: true,
                },
                orgContact: {
                    numeric: true,
                    min: 10,
                    max: 15,
                },
                orgEmail: {
                    required: true,
                    email: true,
                }
            },
            imageUploadApiUrl: window.API_BASE_URL + '/UploadToS3/upload/organisation_logo',
            uploadURL: '',
            uploadingLabel: 'None',
            uploadPending: false,
            uploadNew: true,

            file: null,
            attachments: {
                documents: [],
            },
            isUploadingDocument: false,
            organisationData: '',
            organisationId: "",
            animalsData: [],
            animal_name: '',
            allowEditAnimal: false,
            updateAnimalId: '',
            animalModal: false,
            durgModal: false,
            drugData: [],
            vaccination_drug_name: '',
            drugId: '',
            addDrugModal: false,
            add_drug_name: "",
        }
    },
    mixins: [mixin],
    methods: {
        handleFileUpload()
        {
            this.file = this.$refs.file.files[0];
        },
        addVaccinationDrug()
        {
            this.addDrugModal = true;
        },
        hideAddDrugModal()
        {
            this.addDrugModal = false;
        },
        addDrug()
        {
            var vm = this;
            axios.post("/vaccination_drug/add?vaccination_drug_name=" + vm.add_drug_name + "&organisation_id=" + vm.organisationId)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.showAlert('Success', response.data.response.error.msg, true);
                        vm.getVaccinationDrug(vm.organisationId);
                        this.addDrugModal = false;
                    }
                })
                .catch(error => {
                });
        },
        submitFile()
        {
            if (this.file == null) {
                alert('Please select file');
                return;
            }
            let vm = this;
            vm.isUploadingDocument = true;
            let formData = new FormData();
            formData.append('document', this.file);
            axios.post('/UploadToS3/upload/upload_organisation_document',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(response => {
                vm.isUploadingDocument = false;
                if (response.data.response.error.error_code == 0) {
                    vm.file = null;
                    vm.attachments.documents.push(response.data.response.data);
                    vm.showAlert('DONE', response.data.response.error.msg, true);
                }
            }).catch(error => {
                vm.isUploadingDocument = false;
                console.log(error);
            });
        },
        getOrganisation()
        {
            let vm = this;
            this.$store.dispatch('user/getOrganisation').then(() => {
                vm.setModelFromOrganisation();
            });
        },
        load()
        {
            let vm = this;
            vm.$store.dispatch('user/getOrganisation').then(() => {
                vm.setModelFromOrganisation();
            });

            Bus.$on('example-did-upload', (error, data, response) => {
                if (error) {
                    toastr.error(error);
                } else {
                    vm.uploadURL = response.src;
                    vm.uploadingLabel = "Uploaded";
                    vm.uploadPending = false;
                }
            });

            Bus.$on('example-is-saving', () => {
                vm.uploadPending = true;
                vm.uploadingLabel = "Uploading Image...";
            });
        },
        validateAndSubmit: function () {
            let vm = this;

            if (vm.uploadPending) {
                vm.showAlert('Alert', 'Please wait image is being uploaded', false);
                return;
            }

            if (vm.isUploadingDocument) {
                vm.showAlert('Alert', 'Please wait document is being uploaded', false);
                return;
            }

            if (vm.model.orgName == '' || vm.model.orgAbout == '' || vm.model.orgEmail == '') {
                vm.showAlert('Alert', 'Organisation Name, About and Email fields are required', false);
                return;
            }

            vm.$validator.validateAll().then((result) => {
                if (result) {
                    vm.model.isDisabled = true;
                    axios.post('/organisation/create', {
                        name: vm.model.orgName,
                        about: vm.model.orgAbout,
                        address: vm.model.orgAddress,
                        country: vm.model.orgCountry,
                        state: vm.model.orgState,
                        city: vm.model.orgCity,
                        zip_code: vm.model.orgZipCode,
                        website: vm.model.orgWebsite,
                        email: vm.model.orgEmail,
                        contact: vm.model.orgContact,
                        logo_url: vm.uploadURL != '' ? vm.uploadURL : 'http://www.hsi.org/assets/images/logos/hsi_global.png',
                        attachments: JSON.stringify(vm.attachments.documents),
                    }).then(response => {
                        if (response.data.response.error.error_code == 0) {
                            vm.getUsersDetails();
                            vm.showAlert('Success', response.data.response.error.msg, true);
                            vm.getOrganisation();
                        }
                        vm.model.isDisabled = false;
                    }).catch(error => {
                        if (error.response.status === 400 || error.response.status === 422) {
                            let errors = [];
                            if (error.response.data.response.data !== null) {
                                for (let key in error.response.data.response.data) {
                                    console.log("Key: " + key);
                                    console.log("Value: " + error.response.data.response.data[key]);
                                    errors.push(error.response.data.response.data[key]);
                                }
                                let errorMsg = errors.toString();
                                vm.showAlert('Error', errorMsg, false);
                            } else {
                                let errorMsg = error.response.data.response.error.error_msg;
                                console.log(vm.errorMsg);
                                vm.showAlert('Error', errorMsg, false);
                            }
                        } else {
                            console.log(error);
                        }
                        vm.model.isDisabled = false;
                    });
                }
            });
        },
        setModelFromOrganisation()
        {
            if (this.organisation != null) {
                if (this.organisation.logo_url != '') {
                    this.uploadNew = false;
                }
                if (this.organisation.verified != 1) {
                    //Pradeep Start
                    // if organisation rejected then all input and button enable
                    if (this.organisation.verified == 2) {
                        this.model.inputDisabled = false;
                        this.model.nameDisabled = false;
                        this.$validator.reset();
                    } else {
                        this.model.inputDisabled = true;
                        this.model.nameDisabled = true;
                        this.$validator.reset();
                    }
                    //Pradeep End
                } else {
                    this.model.nameDisabled = true;
                }
                console.log(this.organisation.name);
                this.model.orgName = this.organisation.name;
                this.model.orgAbout = this.organisation.about;
                this.model.orgAddress = this.organisation.address;
                this.model.orgCountry = this.organisation.country;
                this.model.orgState = this.organisation.state;
                this.model.orgCity = this.organisation.city;
                this.model.orgZipCode = this.organisation.zip_code;
                this.model.orgWebsite = this.organisation.website;
                this.model.orgEmail = this.organisation.email;
                this.model.orgContact = this.organisation.contact;

                if (this.organisation.attachments != null) {
                    this.attachments.documents = JSON.parse(this.organisation.attachments);
                } else {
                    this.attachments.documents = [];
                }
            } else {
                console.log('Organisation is null');
            }
        },
        toggleUploadNew()
        {
            this.uploadNew = !this.uploadNew;
        },
        getUsersDetails()
        {
            var vm = this;
            axios.get("/user")
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        this.organisationData = response.data.response.data.organisation;
                        var orgId = this.organisationData.id;
                        vm.organisationId = orgId;
                        this.getVaccinationDrug(orgId);
                        this.getAnimal(orgId);
                    }
                })
                .catch(error => {
                });
        },
        getVaccinationDrug(id)
        {
            var vm = this;
            axios.get("/vaccination_drug/get_all/" + id)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        var drugList = response.data.response.data.vaccination_drugs;
                        vm.drugData = drugList;
                    }
                })
                .catch(error => {
                });
        },
        showAnimalModal()
        {
            this.animal_name = '';
            this.allowEditAnimal = false;
            this.animalModal = true;
            // this.updateAnimalId = data.id;
        },
        updateAnimalData()
        {
            let vm = this;
            if (this.allowEditAnimal) {
                this.editAnimal();
                return;
            } else {
                if (vm.animal_name != '') {
                    //   vm.addingNewAnimal = false;
                    axios.post('animal/add/org', {
                        animal_name: vm.animal_name,
                        organisation_id: vm.organisationId,
                    }).then(response => {
                        if (response.data.response.error.error_code == 0) {
                            vm.showAlert('Success', response.data.response.error.msg, true);
                            vm.getAnimal(vm.organisationId);
                            vm.animalModal = false;
                        }
                    }).catch(error => {
                        vm.animalModal = false;
                        vm.showAlert('Sorry', error.response.data.response.data.animal_name[0], false);
                        // vm.animal_name = '';
                    });
                } else {
                    vm.showAlert('Error', "please enter animal name", false);
                }
            }
        },
        getAnimal(id)
        {
            var vm = this;
            axios.get("/animal/organisation/" + id)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        var animalsList = response.data.response.data.animals;
                        vm.animalsData = animalsList;
                    }
                })
                .catch(error => {
                });
        },
        deleteAnimal(animalId)
        {
            let vm = this;
            swal({
                title: 'Alert!',
                text: 'Are you sure you want to delete animal ? ',
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'yes',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    axios.delete("/animal/remove/org/" + vm.organisationId + "?animal_id=" + animalId).then(response => {
                        if (response.data.response.error.error_code == 0) {
                            vm.showAlert('Success', response.data.response.error.msg, true);
                            vm.getAnimal(vm.organisationId);
                        }
                    }).catch(error => {
                        vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                    });
                }
            });
        },
        updateAnimal(data)
        {
            this.animalModal = true;
            this.allowEditAnimal = true;
            this.updateAnimalId = data.id;
            this.animal_name = data.species_name;
        },
        hideAnimalModal()
        {
            this.animalModal = false;
        },
        editAnimal()
        {
            var vm = this;
            if (vm.animal_name != '') {
                axios.put("/animal/edit/org/" + vm.organisationId + "?animal_id=" + vm.updateAnimalId + "&edited_animal_name=" + vm.animal_name)
                    .then(response => {
                        if (response.data.response.error.error_code == 0) {
                            vm.showAlert('Success', response.data.response.error.msg, true);
                            vm.getAnimal(vm.organisationId);
                            this.animalModal = false;
                        }
                    })
                    .catch(error => {
                        vm.animalModal = false;
                        vm.showAlert('Sorry', error.response.data.response.data.edited_animal_name[0], false);
                        // vm.animal_name = '';
                    });
            } else {
                vm.showAlert('Error', "please enter animal name", false);
            }
        },
        hideDrugModal()
        {
            this.durgModal = false;
        },
        updateDrug(data)
        {
            this.durgModal = true;
            this.vaccination_drug_name = data.vaccination_drug_name;
            this.drugId = data.id;
        },
        deleteDrug(durgId)
        {
            let vm = this;
            swal({
                title: 'Alert!',
                text: 'Are you sure you want to delete vaccination drug ? ',
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'yes',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    axios.delete("/vaccination_drug/remove/" + vm.organisationId + "?vaccination_drug_id=" + durgId).then(response => {
                        if (response.data.response.error.error_code == 0) {
                            vm.showAlert('Success', response.data.response.error.msg, true);
                            vm.getVaccinationDrug(vm.organisationId);
                        }
                    }).catch(error => {
                        vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                    });
                }
            });
        },
        editDrug()
        {
            var vm = this;
            axios.put("/vaccination_drug/edit/" + vm.organisationId + "?vaccination_drug_id=" + vm.drugId + "&edited_drug_name=" + vm.vaccination_drug_name)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.showAlert('Success', response.data.response.error.msg, true);
                        vm.getVaccinationDrug(vm.organisationId);
                        this.durgModal = false;
                    }
                })
                .catch(error => {
                });
        },
        showUrl()
        {
            this.$refs.helpModal.loadUrl(window.USER_MANUAL_URL + "#h.bswdx03ab2pw");
        }
    },
    computed: {
        ...mapGetters('user', ['organisation']),
        getUploadStatus: function () {
            if (this.isUploadingDocument) {
                return 'Please Wait...'
            } else {
                return 'Upload';
            }
        }
    },
    mounted()
    {
        this.getUsersDetails();
        this.load();
    },


}
</script>
<style scoped>

.card-body{
    text-align: left !important;
}

a {
    color: white;
}

.a_color {
    color: black !important;
}

/* Pradeep Start */
@media (min-width: 320px) {
    .set_row {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
}

@media (max-width: 320px) {
    .set_msg {
        margin-top: 53% !important;
    }
}

@media (min-width: 321px) and (max-width: 374px) {
    .set_msg {
        margin-top: 43% !important;
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .set_msg {
        margin-top: 43% !important;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .set_msg {
        margin-top: 35% !important;
    }
}

@media (min-width: 768px) and (max-width: 769px) {
    .set_msg {
        margin-top: 19% !important;
    }
}

/* Pradeep end */
.set_font_title {
    font-size: 20px;
    font-weight: 600;
    color: black;
}

.animal_name {
    margin-top: 5px !important;
    text-align: left;
}

.icon-main {
    margin-top: 5px !important;
    text-align: left;
}

.icon-pencil {
    cursor: pointer;
}

.icon-trash {
    cursor: pointer;
    margin-left: 10px;
}

.animal-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    padding: 80px;
    height: 100%;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.66); /* Black w/ opacity */
    display: block;
    opacity: 1 !important;
}

.animal-modal-body {
    color: #000;
}

.modal-footer {
    border-top: 1px solid #DDDDDD;
    padding: 10px !important;
}

.modal-title {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.animal-footer {
    text-align: right;
    margin-right: 4%;
    margin-bottom: 1%;
}

.add-drugs-animals-button {
    text-align: left;
    margin-bottom: 15px;
    /* margin-left: 12px; */
}

.help-icon {
    cursor: pointer;
    position: absolute;
    margin-left: 5px;
    margin-top: -15px;
    color: #51cbce !important;;
}

@media (min-width: 769px) {
    .right-border {
        border-right: 1px solid #DDDDDD;
    }
}

.left-contain {
    text-align: left;
}

@media (min-width: 400px) and (max-width: 600px) {
    .animle-modal-content {
        width: 330px;
        margin-left: -50px;
    }
}

@media (min-width: 361px) and (max-width: 399px) {
    .animle-modal-content {
        width: 330px;
        margin-left: -66px;
    }
}

@media (min-width: 320px) and (max-width: 360px) {
    .animle-modal-content {
        width: 330px;
        margin-left: -73px;
    }
}
</style>
