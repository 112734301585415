<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox ">
                <div class="ibox-content">
                    <div class="panel-heading">
                        <h2 class="set_font_title">
                            Report By Area
                        </h2>
                    </div>
                </div>
                <el-row>
                    <div class="card p-3">
                        <el-row :gutter="20">
                            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                <label>Areas</label>
                                <multiselect
                                        v-model="selectedArea"
                                        :options="allAreas"
                                        :show-labels="false"
                                        placeholder="Select Area"
                                        track-by="id"
                                        label="name"
                                        :multiple="true"
                                        :close-on-select="false"
                                ></multiselect>
                            </el-col>
                        </el-row>
                        <div>
                            <el-button
                                    :disabled="!selectedArea.length"
                                    @click="generatePDF"
                                    :loading="isGenerating"
                                    class="btn btn-primary float-right text-white mt-3 mr-1"
                            >
                                Generate PDF
                            </el-button>
                        </div>
                    </div>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '../../mixins/GlobalMixin';
import Multiselect from "vue-multiselect";
import {Row, Col, Button} from "element-ui";

export default {
    name: "ReportsByArea",
    props: {
        projectId: {
            type: [Number, String],
            required: true
        }
    },
    data: () => ({
        allAreas: [],
        selectedArea: "",
        isGenerating: false
    }),
    mixins: [mixin],
    components: {
        Multiselect,
        [Row.name]: Row,
        [Col.name]: Col,
        [Button.name]: Button
    },
    async mounted()
    {
        let vm = this;
        vm.project = JSON.parse(localStorage.project);
        Bus.$emit("projectype", vm.project);
        await this.getAllAreas();
    },
    methods: {
        async getAllAreas()
        {
            try {
                const {data} = await axios.get(
                    `/project/areas/${this.projectId}`
                );
                console.log("all Areas", data.response.data.areas);
                const response = data.response.data;
                // this.allAreas = [
                //     ...response.assigned_survey_areas,
                //     ...response.assigned_vaccination_areas
                // ];
                this.allAreas = response.areas;
            } catch (error) {
                console.error(error.response);
            }
        },
        async generatePDF()
        {
            this.isGenerating = true;
            const URL = `/pm/reports/generate/report/${this.projectId}`;
            const body = {
                report_type: 3,
                area_ids: this.selectedArea.map(el => el.id)
            };
            try {
                const {data} = await axios.post(URL, body);
                const downloadURL = `/pm/reports/download/pdf/${data.response.data.report_id}`
                const pdfResponse = await axios.get(downloadURL)
                this.isGenerating = false;
                console.log("pdf response", pdfResponse, data.response.data, pdfResponse.data.response.error.error_code, pdfResponse.data.response.data);
                if (pdfResponse.data.response.data === null) {
                    this.showAlert('Oops!', pdfResponse.data.response.error.msg, false)
                } else {
                    window.location.href = pdfResponse.data.response.data.src
                }
            } catch (error) {
                this.isGenerating = false;
                console.error("pdf error", error.response);
            }
        }
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.multiselect__input:disabled {
    cursor: not-allowed;
}
.set_font_title {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: black !important;
    margin-left: -12px !important;
}

</style>
