<template>

    <div>


    </div>

</template>

<script>

export default {

    props: {
        invitationCode: {
            type: String,
        },
        email: {
            type: String,
        },
        invitationType: {
            type: String,
        }
    },
    data()
    {
        return {}
    },
    methods: {

        joinProject()
        {
            let vm = this;
            axios.get("/project/join/" + vm.invitationCode)
                .then(response => {
                    if (response.data.response.error.error_code === 0) {
                        vm.showAlert("success", "Project joined successfully", true, function (confirm) {
                            vm.$router.replace({name: 'platform'})
                        })
                    }
                })
                .catch(error => {
                    vm.showAlert("error", error.data.response.error.error_msg, false, function (confirm) {
                        vm.$router.replace({name: 'platform'})
                    })
                });
        },

        joinFieldTeam()
        {
            let vm = this;
            axios.get("/field_team/join/" + vm.invitationCode)
                .then(response => {
                    if (response.data.response.error.error_code === 0) {
                        vm.showAlert("success", "Field team joined successfully", true, function (confirm) {
                            vm.$router.replace({name: 'platform'})
                        })
                    }
                })
                .catch(error => {
                    vm.showAlert("error", error.data.response.error.error_msg, false, function (confirm) {
                        vm.$router.replace({name: 'platform'})
                    })
                });
        },

        joinOrganisation()
        {
            let vm = this;
            axios.get("/organisation/join/member/" + vm.invitationCode)
                .then(response => {
                    if (response.data.response.error.error_code === 0) {
                        vm.showAlert("success", "Organisation joined successfully", true, function (confirm) {
                            vm.$router.replace({name: 'platform'})
                        })
                    }
                })
                .catch(error => {
                    vm.showAlert("error", error.data.response.error.error_msg, false, function (confirm) {
                        vm.$router.replace({name: 'platform'})
                    })
                });
        }

    },
    created()
    {
        if (this.invitationType === 'project') {
            this.joinProject()
        } else
            if (this.invitationType === 'field_team') {
                this.joinFieldTeam()
            } else
                if (this.invitationType === 'organisation') {
                    this.joinOrganisation()
                }
    }


}

</script>

<style scoped>

</style>
