<template>
 <div id="scrolldiv">
        <div class="navbar navbar-expand-lg navbar-transparent nav_link_list setWhite desktopnavbar boxshadowsetheader page responsive_menu_item" >
            <div class="col-md-6">
                <ul class="navbar-nav border-right-set" style="margin-left: 16px;">
                    <!-- <li class="nav-item"><a class="nav-link" href="#"> <i class="ti-panel"></i>  Dashboard</a></li>-->
                    <!--pradeep-->
                    <li class="nav-item">
                        <div class="hoverclick">
                            <a class="nav-link nav-link-Projects" v-bind:href="'/projects'"> <!-- <i class="nc-icon nc-bank"></i> -->Projects</a>
                            <div>
<!--                            <ul id="menu-header-menu-left">
                                <div class="dropdown-content">
                                    <div class="showdfilterdropdowndiv">
                                        <ul x-placement class="dropdown-menu dropdown-menu-right show setdropdownfont boxshadow">
                                        <a href="/projects" class="dropdown-item">
                                            <span class="sidebar-normal Setmarginleft">All</span>
                                        </a>
                                        <button v-on:click="onclick('ownerproject',1)" class="dropdown-item">
                                        <span class="sidebar-normal Setmarginleft">My Projects</span>
                                        </button>
                                        <button v-on:click="onclick('assigned',2)" class="dropdown-item">
                                        <span class="sidebar-normal Setmarginleft">Shared with me</span>
                                        </button>
                                        <button v-on:click="onclick('MVD',3)" class="dropdown-item">
                                            <span class="sidebar-normal Setmarginleft">MV Projects</span>
                                        </button>
                                        &lt;!&ndash; <button v-on:click="onclick('DPM',4)" class="dropdown-item">
                                        <span class="sidebar-normal Setmarginleft">PM Projects</span>
                                        </button> &ndash;&gt;
                                        </ul>
                                    </div>
                                </div>
                            </ul>-->
                            </div>
                        </div>
                    </li>
                    <!--End-->
                    <!-- <li class="nav-item navbarmargintop">
                        <div class="hoverclick">
                            <a class="nav-link nav-link-dashboard" href="/dashboard">
                                <i class="nc-icon nc-bank"></i>Dashboard</a>
                            <div>
                                <ul id="menu-header-menu-left">
                                    <div class="dropdown-content">
                                        <div class="showdropdowndiv">
                                            <ul x-placement="" class="dropdown-menu dropdown-menu-right show setdropdownfont boxshadow">
                                                <a href="/dashboard" class="dropdown-item"><span class="sidebar-normal Setmarginleft">All</span></a>
                                                <a href="/mvd" class="dropdown-item"><span class="sidebar-normal Setmarginleft">Mass Vaccination</span></a>
                                                <a href="/dpm" class="dropdown-item"><span class="sidebar-normal Setmarginleft">Population Management</span></a>
                                            </ul>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </li> -->
                    <!-- <li class="nav-item">
                        <div class="hoverclick">
                            <a class="nav-link" href="/generatereport"><i class="fa fa-file" aria-hidden="true"></i>Reports</a>
                            <div>
                                <ul id="menu-header-menu-left">
                                    <div class="dropdown-content">
                                        <div class="showdropdowndivReports">
                                            <ul x-placement class="dropdown-menu dropdown-menu-right show setdropdownfont boxshadow">
                                                <a href="/reportdataexport" class="dropdown-item">
                                                <span class="sidebar-normal Setmarginleft">Data Export</span>
                                                </a>
                                                <a href="/reportmap" class="dropdown-item">
                                                <span class="sidebar-normal Setmarginleft">Map</span>
                                                </a>
                                                <a href="/reportchart" class="dropdown-item">
                                                <span class="sidebar-normal Setmarginleft">Charts</span>
                                                </a>
                                            </ul>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </li> -->
                    <li class="nav-item"><a class="nav-link nav-link-field-users" href="/fieldteams"><!--i class="ti-user"></i--> Field Users</a></li>
                    <!-- <li class="nav-item"><a class="nav-link" href="#"><i class="ti-user"></i> Admin Users</a></li> -->
                </ul>
            </div>
            <div class="col-md-3">
                <ul class="navbar-nav desktopnavbar-nav">
                    <!-- <li class="nav-item"><a class="nav-link" href="#"><i class="fa fa-car" aria-hidden="true"></i> Vehicles</a></li>
                    <li class="nav-item"><a class="nav-link" href="#"><i class="fa fa-car" aria-hidden="true"></i>Inventory</a></li> -->
                    <!-- Pradeep Start -->
                    <!-- Remove Vehicles and Inventory page  in menubar -->
                    <!-- <li class="nav-item"><a class="nav-link" href="#"> Vehicles</a></li>
                    <li class="nav-item"><a class="nav-link" href="#">Inventory</a></li> -->
                    <!-- Pradeep End -->
                </ul>
            </div>
            <div class="col-md-3">
                <ul class="navbar-nav border-left-set">
                    <li class="nav-item" style="width: calc(100% - 90%);"></li>
                    <!-- <li class="nav-item"><a class="nav-link" href="#">HSI.ORG</a></li>
                        <li class="nav-item"><a class="nav-link" href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <li class="nav-item"><a class="nav-link" href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                    <li class="nav-item"><a class="nav-link" href="#"><i class="fa fa-rss" aria-hidden="true"></i></a></li>-->
                    <!--<li class="nav-item">
                        <a class="nav-link" href="#">
                            <form>
                                <div class="input-group no-border text-search">
                                    <input type="text" value="" placeholder="Search..." class="form-control">
                                    <div class="input-group-append">
                                        <div class="input-group-text"><i class="nc-icon nc-zoom-split" style="margin-top: -8px;"></i></div>
                                    </div>
                                </div>
                            </form>
                        </a>
                    </li>-->
                </ul>
            </div>
            </div>
        </div>

</template>
  <!--pradeep -->
<script>
import {mapGetters} from 'vuex';
//import FixedHeader from 'vue-fixed-header';
 export default{

        components: {
            //FixedHeader,
          /*  FgInput,
            StatsCard,
            ChartCard,
            ProjectCard,
            CircleChartCard,
            Badge,
            TaskList,
            [Autocomplete.name]: Autocomplete,*/
        },

        data(){
            return {
                info: 'Loading...',
                statsCards: [
                    {
                        type: 'warning',
                        icon: 'nc-icon nc-globe',
                        title: 'Capacity',
                        value: '105GB',
                        footerText: 'Updated now',
                        footerIcon: 'nc-icon nc-refresh-69',
                    },
                    {
                        type: 'success',
                        icon: 'nc-icon nc-money-coins',
                        title: 'Revenue',
                        value: '$1,345',
                        footerText: 'Last day',
                        footerIcon: 'nc-icon nc-calendar-60',
                    },
                    {
                        type: 'danger',
                        icon: 'nc-icon nc-vector',
                        title: 'Errors',
                        value: '23',
                        footerText: 'In the last hour',
                        footerIcon: 'nc-icon nc-bell-55',
                    },
                    {
                        type: 'info',
                        icon: 'nc-icon nc-favourite-28',
                        title: 'Followers',
                        value: '+45',
                        footerText: 'Updated now',
                        footerIcon: 'nc-icon nc-refresh-69',
                    },
                ],
                owner: 'OWNER',
                imageUrl: '',
                searchQuery: '',

                searchedProjects: [],
                selectedProject: '',
                timeout: null,

            }
        },

        props: {
            projectid: {
                type: Number,
                default: 0,
            },
        },

        computed: {
           ...mapGetters('user', ['ownProjects', 'assignedProjects']),
            filteredOwnProjects: function () {
                return this.ownProjects.filter(item => {
                    var projectid=this.ownProjects[0].id;
                    if(this.ownProjects.length==1)
                    {
                       // var projectid=this.ownProjects[0].id;
                       // window.location.href = 'project/details/'+projectid;*/

                    }
                    return item.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
                });
            },
            filteredAssignedProjects: function () {
                return this.assignedProjects.filter(item => {
                    return item.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
                });
            }
        },
        methods: {
            remoteMethod(query, cb) {
                let vm = this;
                if (query !== '') {
                    axios.get('project/search', {
                        params: {
                            name: query,
                        }
                    }).then(response => {
                        clearTimeout(vm.timeout);
                        vm.searchedProjects = response.data.response.data.projects;
                        cb(vm.searchedProjects);
                    }).catch(error => {
                        vm.searchedProjects = [];
                        cb(vm.searchedProjects);
                    });
                } else {
                    clearTimeout(vm.timeout);
                    vm.searchedProjects = [];
                    cb(vm.searchedProjects);
                }
            },

            showProjectDetails(project){
                //alert(JSON.stringify(project));
                window.location.href = 'project/details/' + project.id;
            },
            myFunction_test() {
                var header = document.getElementById("scrolldiv");
                var sticky = header.offsetTop;
                if (window.pageYOffset > sticky) {
                    header.classList.add("sticky");
                } else {
                    header.classList.remove("sticky");
                }
            },
            test(){
                var self=this
                window.onscroll = function() {self.myFunction_test()};
            },
            onclick: function(project_type, value) {
                var obj = {project_type:project_type,value:value};
                localStorage.setItem('projectDropData',JSON.stringify(obj));
                window.location = '/projects';
            },
        },
        created(){
            this.test()
        }
    }

</script>

 <!--End -->

<style>
    .nav_link_list ul {
        margin: 10px 0 10px 0;
        padding: 3px 0 3px 0;
     }

    .nav_link_list li {
       display: inline-block;
       margin: 7px 6px 7px 6px;
       padding: 4px 4px 1px 8px;
       color: black;
       font-size: 18px;
       font-weight: 500;
       height: 25px;

     }
    .nav_link_list a {
        padding: 0 !important;
        margin: 0 !important;
    }
    .nav_link_list .input-group-text {
        padding: 0 !important;
        margin: 0 !important;
    }

    .nav_link_list .nav-icons>li>a i {
        margin-bottom: 0px;
    }

    .nav_link_list .input-group-append i {
        font-size: 18px;
    }

    .nav_link_list .input-group-append {
        padding: 0px 11px 0px 0px;
        width: 33px;
    }

    .navbar.navbar-transparent.setGery {
        background-color: #e4d9d9 !important;
    }

    .navbar.navbar-transparent.setWhite {
        background-color: white !important;
    }

    .navbar.navbar-transparent.nav_link_list {
        padding: 0;
        z-index: 1;
    }

    .nav_link_list .nav-link {
        font-size: 13px !important;
    }

    .dropdown-content {
        background-color: #FFFFFF;
        border: 0 none;
        border-radius: 12px;
        margin-top: -10px;
        padding: 0px;
        display: none;
    }

    .hoverclick:hover .dropdown-content {
        display: block;
    }

    .showdropdowndiv {
        position: absolute;
        margin-left: calc(100% - 70%);

    }
    .showdropdowndivReports{
        position: absolute;
        margin-left: calc(100% - 81%);
    }
    .setdropdownfont::after {
        left: 10 !important;
        top: -12px;
        right: unset !important;
    }

    .setdropdownfont:before {
        left: 10 !important;
        top: -12px;
        right: unset !important;
    }

    .dropdown-menu .dropdown-item {
        padding: 10px 45px 10px 15px !important;
    }

    .nav_link_list {
        background: white;
        margin-bottom: 0px !important;
    }
    .text-search {
        margin: -9px !important;
        margin-left: 11px !important;
    }
    /*.border-left-set {
        border-left: 1px solid;
    }
    .border-right-set {
        border-right: 1px solid;
    }*/
    .boxshadow{
        box-shadow: 0px 5px 7px 5px rgba(0, 0, 0, 0.15) !important;
    }
    .sticky{
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000000000000;
    }
    .boxshadowsetheader{
         box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15) !important;

    }
    .showdfilterdropdowndiv {
        position: absolute;
        margin-left: calc(100% - 79%);
    }
    /* Pradeep Start */
    /* Dashboard dropdown Responsive */
    @media (min-width: 320px) and (max-width: 425px) {
      .showdropdowndiv {
          position: absolute;
          margin-left: calc(100% - 93%) !important;
      }
      .showdfilterdropdowndiv {
            z-index: 1 !important;
            margin-top: -20px !important;
            margin-left: 42px !important;
      }
      .showdropdowndivReports{
        position: absolute;
        margin-left: calc(100% - 70%);
      }
    }
    @media (min-width: 768px) and (max-width: 769px) {
      .showdropdowndiv {
          position: absolute;
          margin-left: calc(100% - 96%) !important;
      }
      .showdfilterdropdowndiv {
            z-index: 1 !important;
            margin-top: -20px !important;
            margin-left: 40px !important;
      }
      .showdropdowndivReports{
        position: absolute;
        margin-left: calc(100% - 75%);
      }
    }
    @media (min-width: 1024px) and (max-width: 1025px) {
      .showdropdowndiv {
          position: absolute;
          margin-left: calc(100% - 48%) !important;
      }
      .nav_link_list .nav-link {
            font-size: 11px !important;
      }
      .showdfilterdropdowndiv {
            position: absolute;
            margin-left: calc(100% - 65%);
      }
      .showdropdowndivReports{
        position: absolute;
        margin-left: calc(100% - 66%);
      }
    }
     @media (min-width: 1439px) and (max-width: 1440px) {
      .showdropdowndiv {
          position: absolute;
          margin-left: calc(100% - 63%) !important;
      }
    }
     @media (min-width: 1026px) and (max-width: 1440px) {
        .showdropdowndivReports{
            position: absolute;
            margin-left: calc(100% - 71%);
        }
     }
    /* Pradeep End */
</style>

