<template>
    <div class="col-md-12">

        <div class="row">
            <div class="col-sm-8 col-xs-6 col-md-6 col-xl-6  set_pagination_content_resp">
                <el-select
                        class="select-default"
                        v-model="pagination.perPage"
                        placeholder="Per page">
                    <el-option
                            class="select-default"
                            v-for="item in pagination.perPageOptions"
                            :key="item"
                            :label="item"
                            :value="item">
                    </el-option>
                </el-select>
            </div>
            <div class="col-sm-8 col-xs-6 col-md-6 col-xl-6 set_searchbox_responsive search_bar_mv">
                <div class="pull-right">
                    <fg-input class="input-sm set_Margintop_textbox new_input_search"
                              placeholder="Search"
                              v-model="searchQuery"
                              addon-right-icon="nc-icon nc-zoom-split">
                    </fg-input>
                </div>
            </div>
            <div class="col-sm-12 mt-2">
                <el-table class="table-striped"
                          :data="queriedData"
                          style="width: 100%">
                    <el-table-column v-for="column in tableColumns"
                                     :key="column.label"
                                     :min-width="column.minWidth"
                                     :prop="column.prop"
                                     :label="column.label">
                    </el-table-column>
                    <!--					<el-table-column
                                  :min-width="120"
                                  fixed="right"
                                  class-name="td-actions"
                                  label="Actions">
                                <template slot-scope="props">
                                  <p-button type="danger" size="sm" icon @click="handleDelete(props.$index, props.row,$event)" style="width:64%; margin: 3px; text-transform:capitalize;">
                                    Freeze
                                  </p-button>
                                </template>
                              </el-table-column>-->
                </el-table>
            </div>
            <div class="col-sm-12 col-md-6 pagination-info">
                <p class="category set_margintop">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
            </div>
<!--            <div class="col-sm-6" v-if="showPaginationInfo">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
            </div>-->
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import {Table, TableColumn, Select, Option} from 'element-ui';
import PPagination from '../UIComponents/Pagination.vue';
//import users from './users';
import swal from 'sweetalert2';

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);

export default {
    props: {
        showPaginationInfo: {
            type: Boolean,
            default:false,
        },
        users: {
            type: Array,
        },

    },
    watch: {
        users: function (newVal, oldVal) { // watch it
            this.tableData = newVal;
        }
    },
    components: {
        PPagination,
    },
    computed: {
        pagedData()
        {
            return this.tableData.slice(this.from, this.to);
        },
        /***
         * Searches through table data and returns a paginated array.
         * Note that this should not be used for table with a lot of data as it might be slow!
         * Do the search and the pagination on the server and display the data retrieved from server instead.
         * @returns {computed.pagedData}
         */
        queriedData()
        {
            if (this.tableData == undefined) {
                return;
            }
            if (!this.searchQuery) {
                this.pagination.total = this.tableData.length;
                return this.pagedData;
            }
            let result = this.tableData.filter((row) => {
                let isIncluded = false;
                for (let key of this.propsToSearch) {
                    let rowValue = row[key].toString();
                    // Pradeep Start
                    // Activity Status section case sensitive character search in datatable
                    if (rowValue.toLowerCase().includes && rowValue.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                        isIncluded = true;
                    }
                    // Pradeep End
                }
                return isIncluded;
            });
            this.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },
        to()
        {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from()
        {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total()
        {
            if (this.tableData == undefined) {
                return;
            }
            this.pagination.total = this.tableData.length;
            return this.tableData.length;
        },
    },
    data()
    {
        return {
            pagination: {
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
            },
            searchQuery: '',
            propsToSearch: ['name', 'status', 'area', 'date'],
            tableColumns: [
                {
                    prop: 'name',
                    label: 'Field User',
                    minWidth: 100,
                },
                {
                    prop: 'area',
                    label: 'Area',
                    minWidth: 100,
                },
                {
                    prop: 'status',
                    label: 'Status',
                    minWidth: 60,
                },
                {
                    prop: 'date',
                    label: 'Date',
                    minWidth: 100,
                },
            ],
            tableData: this.users,
        };
    },
    methods: {
        handleLike(index, row)
        {
            alert(`Your want to like ${row.name}`);
        },
        handleEdit(index, row)
        {
            alert(`Your want to edit ${row.name}`);
        },
        handleDelete(index, row, event)
        {
            this.showSwal('warning-message-and-confirmation', event)
        },
        showSwal(type, event)
        {
            swal({
                title: 'Are you sure?',
                text: `You won't be able to change this!`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Yes, change it!',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    let text = event.target.innerHTML;
                    if (text.indexOf('UnFreeze') >= 0) {
                        event.target.innerHTML = 'Freeze'
                        event.target.className = 'btn btn-icon btn-danger btn-sm'
                        swal({
                            title: 'UnFreeze!',
                            text: 'This account has been unfreezed.',
                            type: 'success',
                            confirmButtonClass: 'btn btn-success btn-fill',
                            buttonsStyling: false,
                        });
                    } else {
                        event.target.innerHTML = 'UnFreeze'
                        event.target.className = 'btn btn-icon btn-success btn-sm'
                        swal({
                            title: 'Freeze!',
                            text: 'This account has been freezed.',
                            type: 'success',
                            confirmButtonClass: 'btn btn-success btn-fill',
                            buttonsStyling: false,
                        });
                    }
                }
            });
        },
    },
};
</script>
<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}

.search_datatable {
  border-left: 0 none !important;

}

.set_margintop {
  margin-top: 9px !important;
}

.el-table__fixed-body-wrapper {
  background-color: white !important;
}

.el-table__fixed-header-wrapper {
  background-color: white !important;
}

.search_bar_mv {
  padding-right: 0px;
  padding-left: 0px;
}

@media (min-width: 241px) and (max-width: 320px) {
  .pull-right {
    float: none;
  }
  .set_data_table_textbox_width {
    width: 82% !important;
  }
  .set_Margintop_textbox {
    margin-top: 10px;
  }
  .setHeader {
    z-index: 0;
  }

}

@media only screen and (max-width: 411px) {
  .pull-right {
    float: none;
  }
  .set_data_table_textbox_width {
    width: 82% !important;
  }
  .set_Margintop_textbox {
    margin-top: 10px;
  }
  .setHeader {
    z-index: 0;
  }

}

@media (min-width: 341px) and (max-width: 375px) {
  .pull-right {
    float: none;
  }
  .set_data_table_textbox_width {
    width: 86% !important;
  }
  .set_Margintop_textbox {
    margin-top: 10px;
  }
  .setHeader {
    z-index: 0;
  }

}

@media (min-width: 376px) and (max-width: 425px) {
  .pull-right {
    float: none;
  }
  .set_data_table_textbox_width {
    width: 88% !important;
  }
  .set_Margintop_textbox {
    margin-top: 10px;
  }
  .setHeader {
    z-index: 0;
  }


}

@media (min-width: 426px) and (max-width: 768px) {
  .pull-right {
    float: none;
  }
  .set_data_table_textbox_width {
    width: 89% !important;
  }
  .setHeader {
    z-index: 0;
  }
}

@media only screen and (max-device-width: 1024px) {
  .set_data_table_textbox_width {
    width: 83%;
  }
  .new_input_search .set_data_table_textbox_width {
    width: 66%;
  }
}

@media (min-width: 800px) and (max-width: 1280px) {
  .new_input_search input {
    width: 155px;
  }
}

</style>
