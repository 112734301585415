<template>

    <div>
        <div class="wrapper wrapper-full-page">
            <div class="full-page login-page section-image">
                <div class="container">

                    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
                        <card color="red">
                            <!--                        <span class="text-white">{{$error}}</span><br>-->
                        </card>
                        <card color="red">
                            <!--                        <span class="text-white">{{session('err_msg')}}</span><br>-->
                        </card>
                        <card color="green">
                            <!--                        <span class="text-white">{{session('msg')}}</span><br>-->
                        </card>
                        <card type="login" footer-line>
                            <h4 class="header text-center">HSApps</h4>
                            <hr>
                            <div class="form-group">
                                <fg-input type="email"
                                          required
                                          label="Email"
                                          name="email"
                                          v-validate="modelValidations.email"
                                          :error="getError('email')"
                                          v-model="model.email">
                                </fg-input>
                            </div>
                            <div slot="footer">
                                <button v-on:click="validateforgotSubmit" class="btn mb-3 btn-round btn-block btn-info">
                                    Reset Password
                                </button>
                            </div>

                        </card>
                    </div>
                </div>
                <div class="full-page-background"></div>
            </div>
        </div>
    </div>

</template>

<script>

import {Button, Card, InfoSection} from "./UIComponents";

export default {
    components: {
        Card,
        [Button.name]: Button,
        InfoSection,
    },
    data()
    {
        return {
            model: {
                email: '',
                password: '',
                isLoggingIn: false,
            },
            modelValidations: {
                email: {
                    required: true,
                    email: true,
                },
                password: {
                    required: true,
                    min: 6,
                },
            },

            reset_email: '',
            form: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                password: '',
                password_confirmation: '',
                acceptTerms: false,
                isEmailDisabled: false,
            },
            formValidations: {
                firstName: {
                    alpha_spaces: true,
                },
                lastName: {
                    alpha_spaces: true,
                },
                email: {
                    required: true,
                    email: true,
                },
                acceptTerms: {
                    required: true
                },
                password: {
                    required: true,
                    min: 6,
                },
                phone: {
                    required: true,
                    min: 10,
                    max: 15,
                },
            }

        }
    },
    methods: {

        validateforgotSubmit()
        {

            let vm = this;

            this.$validator.validateAll().then((result) => {
                if (result) {
                    axios.post('user/request/password/reset', {
                        email: vm.model.email
                    })
                        .then(response => {
                            if (response.data.response.error.error_code == 0) {
                                vm.showAlert('Success', response.data.response.error.msg, true, function (confirm) {
                                    vm.$router.push({name: 'forgot'});
                                });
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                        })
                }
                return false;
            }).catch(() => {
                return false
            });

        }

    },


}

</script>

<style scoped>

</style>
