<template>
    <div>

        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-sm-12">
                <h5>Records > Surveys > Map {{ activeAreaName }}</h5>
                <hr>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-md-6 col-xl-3">
                <v-md-date-range-picker v-show="filterByDate" :start-date="startDatePast" :end-date="endDatePast"
                                        @change="onDateChanged"
                                        style="width:100% !important"></v-md-date-range-picker>
                <el-checkbox v-model="filterByDate">Filter By Date Range</el-checkbox>
            </div>
        </div>

        <div class="row set_div_map">
            <div class="col-xs-12 col-md-3 col-xl-2">
                <p>Country</p>
                <div class="form-group">
                    <template>
                        <el-select v-model="selectFilterCountry" filterable placeholder="Country"
                                   class="el_select_width">
                            <el-option
                                    label="All"
                                    value="All">
                            </el-option>
                            <el-option
                                    v-for="item in countries"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>
            <div class="col-xs-12 col-md-3 col-xl-2">
                <p>State/Province</p>
                <div class="form-group">
                    <template>
                        <el-select v-model="selectFilterState" filterable placeholder="State/Province"
                                   class="el_select_width">
                            <el-option
                                    label="All"
                                    value="All">
                            </el-option>
                            <el-option
                                    v-for="item in states"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>
            <div class="col-xs-12 col-md-3 col-xl-2">
                <p>City/District/Village</p>
                <div class="form-group">
                    <template>
                        <el-select v-model="selectFilterCity" filterable placeholder="City/District/Village"
                                   class="el_select_width">
                            <el-option
                                    label="All"
                                    value="All">
                            </el-option>
                            <el-option
                                    v-for="item in cities"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>
            <div v-if="projectLevelView" class="col-xs-12 col-md-3 col-xl-3">
                <p>Area</p>
                <div class="form-group">
                    <template>
                        <el-select v-model="selectFilterArea" filterable placeholder="Vaccination Area"
                                   class="el_select_width">
                            <el-option
                                    label="All"
                                    value="All">
                            </el-option>
                            <el-option
                                    v-for="item in project.areas"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-md-3 col-xl-1">
                <button type="button" class="btn btn-primary" @click="getFilterRecordsBySearch()">Filter</button>
            </div>
            <div class="col-xs-12 col-md-3 col-xl-2">
                <button type="button" class="btn btn-warning"
                        @click="clearFilters()">Clear Filters
                </button>
            </div>
        </div>

        <br>

        <div class="col-sm-12 card setShadow set_div_maps">
            <div class="card-body" style="text-align: center;">
                <div class="set_p_btn">
                    <span class="span_font">Total Count</span>
                    <p class="p_font">{{ statsData.total_count }}</p>
                </div>
                <div class="set_p_btn">
                    <span class="span_font">Vaccinated</span>
                    <p class="p_font">{{ statsData.vaccinated }}</p>
                </div>
                <div class="set_p_btn">
                    <span class="span_font">Not Vaccinated</span>
                    <p class="p_font">{{ statsData.un_vaccinated }}</p>
                </div>
                <div class="set_p_btn">
                    <span class="span_font">Unsure</span>
                    <p class="p_font">{{ statsData.unsure }}</p>
                </div>
                <div class="set_p_btn">
                    <span class="span_font">Average Coverage</span>
                    <p class="p_font">{{
                            statsData.average_percentage_covered > 10 ? statsData.average_percentage_covered : 'Not enough data'
                        }}</p>
                </div>
                <div class="set_p_btn">
                    <span class="span_font">Survey Users</span>
                    <p class="p_font" v-for="item in statsData.users">{{
                            item.length != 0 ?
                                    item.first_name + '' + item.last_name : "-NA-"
                        }}</p>
                </div>
            </div>
        </div>

        <div class="row setMarginTop">
            <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12">
                <div class="card setShadow">
                    <div class="card-body" style="padding: 10px;">
                        <div class="legend-div">

                            <label class="label-color" style="background-color: #008000;"></label>
                            <span class="type-span">Vaccinated</span>

                            <label class="label-color" style="background-color: #ff0000;"></label>
                            <span class="type-span">Not Vaccinated</span>

                            <label class="label-color" style="background-color: grey;"></label>
                            <span class="type-span">Unsure</span>

                        </div>
                        <GmapMap
                                ref="mapRef"
                                gestureHandling="greedy"
                                :center="getMapCenter"
                                :options="googleMapOptions"
                                :zoom="3"
                                style="width: 100%; height: 700px">
                            <GmapCluster :zoom-on-click="true">
                                <gmap-info-window
                                        :options="infoOptions"
                                        :position="infoWindowPos"
                                        :opened="infoWinOpen"
                                        @closeclick="infoWinOpen=false">
                                    <div v-html="infoContent"></div>
                                </gmap-info-window>
                                <gmap-marker
                                        :key="index"
                                        v-for="(m, index) in markers"
                                        :position="m.postion"
                                        :icon="m.icon"
                                        @click="markerClick(m)">
                                </gmap-marker>
                            </GmapCluster>
                        </GmapMap>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mixin from '../../mixins/GlobalMixin';
import {Select, Option, Checkbox} from 'element-ui';
import Vue from 'vue';
import VOffline from 'v-offline';
import VMdDateRangePicker from "v-md-date-range-picker";

import {MAP_STYLE} from "./Maps/MAP_STYLE";

Vue.use(VMdDateRangePicker);

export default {
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        [Checkbox.name]: Checkbox,
        VOffline
    },
    props: {
        projectId: {
            type: Number,
        }
    },
    data()
    {
        return {
            googleMapOptions: {
                scrollwheel: true,
                disableDefaultUI: true,
                styles: MAP_STYLE
            },
            layers: [],
            filterByDate: false,
            title: '',
            createdOn: '',
            infoContent: '',
            countries: [],
            cities: [],
            states: [],
            fieldUsers: '',
            areaImage: '',
            selectFilter: "",
            selectFilterVal: "",
            project: null,
            markers: [],
            center: {lat: 33.8291523, lng: -116.8004315},
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            infoWindowPos: null,
            infoWinOpen: false,
            statsData: {
                total_count: 0,
                vaccinated: 0,
                un_vaccinated: 0,
                average_percentage_covered: 0,
                unsure: 0,
                users: ['']
            },
            startDatePast: '',
            endDatePast: '',
            selectFilterCountry: 'All',
            selectFilterState: 'All',
            selectFilterCity: 'All',
            selectFilterArea: 'All',
            activeAreaName: '',
            projectLevelView: false,
        }
    },
    mixins: [mixin],
    methods: {
        setMapCenter(location)
        {
            let latLng = location.split(',');
            var latitude = latLng[0];
            var longitude = latLng[1];

            this.center = {
                lat: parseFloat(latitude),
                lng: parseFloat(longitude)
            };
        },
        markerClick(marker)
        {
            this.infoWindowPos = marker.postion;
            this.infoContent = '<div class="row">\n' +
                '                                  <div class="col-md-12" style="text-align: left;">\n' +
                '                                    <p style="margin-top: 10px;"><b>' + marker.vaccinated + '</b></br>Date:' + marker.created_at + '</p>\n' +
                '                                  </div>\n' +
                '                              </div>';

            this.infoWinOpen = true;

        },
        onDateChanged(values)
        {
            var sDate = this.formatDate(values[0]._d);
            var eDate = this.formatDate(values[1]._d);
            this.startDatePast = sDate;
            this.endDatePast = eDate;
        },
        formatDate(date)
        {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [month, day, year].join('/');
        },
        getFilterRecordsBySearch()
        {
            let vm = this;
            vm.markers = [];

            var finalStartDate = null;
            var finalEndDate = null;

            if (vm.filterByDate) {
                var dateStart = vm.startDatePast.split("/");
                var dateEnd = vm.endDatePast.split("/");

                finalStartDate = dateStart[1] + '/' + dateStart[0] + '/' + dateStart[2];
                finalEndDate = dateEnd[1] + '/' + dateEnd[0] + '/' + dateEnd[2];
            }

            let country = [];
            if (vm.selectFilterCountry != 'All') {
                country.push(vm.selectFilterCountry)
            }
            let city = [];
            if (vm.selectFilterCity != 'All') {
                city.push(vm.selectFilterCity)
            }
            let state = [];
            if (vm.selectFilterState != 'All') {
                state.push(vm.selectFilterState)
            }
            let areas = [];
            if (vm.selectFilterArea != 'All') {
                areas.push(vm.selectFilterArea)
            }

            if (!vm.projectLevelView) {
                areas.push(localStorage.getItem("SurveyAreaId"))
            }

            axios.post('/survey/get_records/filter/' + vm.projectId, {
                start_date: finalStartDate,
                end_date: finalEndDate,
                area: areas,
                country: country,
                city: city,
                state: state
            }).then(response => {
                this.processSurveyRecords(response.data.response.data);
            });

        },
        processSurveyRecords(data)
        {
            let records = data.survey_records;
            let vm = this;
            if (records.length > 0) {
                vm.setMapCenter(records[records.length - 1].survey_location_lat_lng);
            }
            records.map((item) => {
                let latLng = item.survey_location_lat_lng.split(',');
                var latitude = latLng[0];
                var longitude = latLng[1];
                var iconImage = "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=US|808080";
                if (item.is_vaccinated == 0) {
                    iconImage = "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=NV|FF0000";
                } else
                    if (item.is_vaccinated == 1) {
                        iconImage = "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=V|008000";
                    }
                var marker = {
                    postion: {lat: parseFloat(latitude), lng: parseFloat(longitude)},
                    icon: iconImage,
                    vaccinated: item.is_vaccinated == 0 ? 'Not Vaccinated' : item.is_vaccinated == 1 ? 'Vaccinated' : 'Unsure',
                    created_at: item.created_at
                }
                vm.markers.push(marker);
            });
            vm.statsData = data.stats;
            vm.plotAllKmlLayers();
        },
        getCities()
        {
            axios.get("/project/list/area/cities?project_id=" + this.projectId)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        this.cities = response.data.response.data.cities;
                    }
                })
                .catch(error => {
                    if (error.response.data.response.error) {

                    }
                });
        },
        getStates()
        {
            axios.get("/project/list/area/states?project_id=" + this.projectId)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        this.states = response.data.response.data.states;
                    }
                })
                .catch(error => {
                    if (error.response.data.response.error) {

                    }
                });
        },
        getCountries()
        {
            axios.get("/project/list/area/countries?project_id=" + this.projectId)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        this.countries = response.data.response.data.countries;
                    }
                })
                .catch(error => {
                    if (error.response.data.response.error) {

                    }
                });
        },
        clearFilters()
        {
            let vm = this;
            vm.selectFilterCountry = 'All';
            vm.selectFilterCity = 'All';
            vm.selectFilterState = 'All';
            vm.selectFilterArea = 'All';
            vm.filterByDate = false;
            vm.getFilterRecordsBySearch();
        },
        plotAllKmlLayers()
        {
            var vm = this;
            for (var l = 0; l < vm.layers.length; l++) {
                vm.layers[l].setMap(null);
            }
            vm.layers = [];
            this.$refs.mapRef.$mapPromise.then((map) => {
                for (var i = 0; i < vm.project.areas.length; i++) {
                    var layer = null;
                    if (vm.selectFilterArea == 'All') {
                        layer = new google.maps.KmlLayer({
                            url: vm.project.areas[i].file_url,
                            suppressInfoWindows: true,
                            preserveViewport: true,
                            clickable: false,
                            visibility: "visible",
                        });
                        layer.setMap(map);
                        vm.layers.push(layer);
                    } else
                        if (vm.selectFilterArea == vm.project.areas[i].id) {
                            layer = new google.maps.KmlLayer({
                                url: vm.project.areas[i].file_url,
                                suppressInfoWindows: true,
                                preserveViewport: true,
                                clickable: false,
                                visibility: "visible",
                            });
                            layer.setMap(map);
                            vm.layers.push(layer);
                        }

                }
            });
        },
    },
    computed: {
        getMapCenter: function () {
            return this.center;
        },
    },
    mounted()
    {
        let vm = this;
        if (localStorage.getItem('surveyProjectLevelViewMap') == 1) {
            vm.activeAreaName = "> Project Level View";
            vm.projectLevelView = true;
        } else {
            vm.activeAreaName = "> Area (" + localStorage.getItem('SurveyAreaName') + ")";
            vm.projectLevelView = false;
        }
        vm.getCities();
        vm.getStates();
        vm.getCountries();
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        vm.startDatePast = firstDay.toLocaleDateString('en-US');
        vm.endDatePast = lastDay.toLocaleDateString('en-US');
        vm.project = JSON.parse(localStorage.project);

        Bus.$emit("projectype", vm.project);
        try {
            setTimeout(function () {
                var x = document.getElementsByTagName("label")[0].textContent = "";
                var dateSize = document.getElementsByClassName("text-field__filled")[0];
                dateSize.style.width = '100%';
                document.querySelectorAll('.mdrp__panel')[0].style.zIndex = '10111111';
            }, 50);
        } catch (e) {
        }
        vm.getFilterRecordsBySearch();
    }
}

</script>

<style lang="scss" scoped>
.el-select .el-input:hover .el-input__icon,
.el-select .el-input:hover input {
  color: #66615B !important;
}

.el-select .el-input:hover .el-input__icon::-webkit-input-placeholder,
.el-select .el-input:hover input::-webkit-input-placeholder {
  color: #66615B !important;
}

.el-select .el-input:hover .el-input__icon::-ms-input-placeholder,
.el-select .el-input:hover input::-ms-input-placeholder {
  color: #66615B !important;
}

.el-select .el-input:hover .el-input__icon::placeholder,
.el-select .el-input:hover input::placeholder {
  color: #66615B !important;
}

.form-group .el-input__inner[readonly] {
  background-color: white !important;
}

.setShadow {
  box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
}

.btn {
  margin: 0px !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  background-color: #FFFFFF;
  border: 0 none;
  border-radius: 0px;
  margin-top: 0px;
  padding: 15px;
  min-width: 20rem !important;
}

.navbar-nav .dropdown-menu:after, .dropdown .dropdown-menu[x-placement="bottom-start"]:after, .dropdown .dropdown-menu[x-placement="bottom-end"]:after, .card.card-just-text .dropdown .dropdown-menu:after, .card-just-text .dropdown .dropdown-menu:after, .dropdown-btn .dropdown-menu:after {
  display: none;
}

.navbar-nav .dropdown-menu:before, .dropdown .dropdown-menu[x-placement="bottom-start"]:before, .dropdown .dropdown-menu[x-placement="bottom-end"]:before, .card.card-just-text .dropdown .dropdown-menu:before, .card-just-text .dropdown .dropdown-menu:before, .dropdown-btn .dropdown-menu:before {
  display: none;
}

p {
  margin-top: 0;
  margin-bottom: 0rem;
}

.set_div_posi {
  margin-top: 23px;
}

/* .set_div_map{
 position: absolute;
  z-index: 10000000;
  opacity: 1.5;
  width: calc(100% - 3.5%);
  padding: 15px;
  margin: 0px;
} */

.img_marker_popup {
  height: 70px;
  width: 70px;
}

.set_redius {
  border-radius: 0px !important;
  background: transparent !important;
}

.set_p_btn {
  float: left;
  margin-right: 10px;
  border-right: solid 1px lightgrey;
  padding-right: 10px;
  /*font-size: 17px;*/
  /*background-color: darkgrey;*/
  /*background-color: white;*/
  /*margin-bottom: 10px;*/
}

@media (min-width: 1399px) and (min-width: 1400px) {
  .col-xl-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 22%;
  }
}

.p_font {
  font-size: 17px;
}

.span_font {
  font-size: 13px;
}

.label-color {
  width: 12px;
  height: 12px;
  margin-bottom: 0px !important;
  border-radius: 3px;
}

.legend-div {
  margin: 0px 0px 8px 0px;
}

.type-span {
  margin-right: 5px;
  font-size: 15px;
}

.close-icon {
  font-size: 32px;
}

.set_div_maps {

  /*top: 29%;
  left: 5%;
  position: absolute;
  z-index: 10000000;
  opacity: 1.5;
  width: 25%;
  border: 1px solid #ddd;*/
}

@media (min-width: 768px) and (max-width: 1024px) {
  .set_div_maps {
    top: 27%;
    left: 7%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .set_div_maps {
    top: 42%;
    left: 8%;
    position: absolute;
    z-index: 10000000;
    opacity: 1.5;
    width: 42%;
    border: 1px solid #ddd;
  }
}
</style>
