<template>
    <div>
        <div class="platform-main-panel">
            <user-navbar></user-navbar>
            <div class="content">
                <div class="setHeader">
                    <div v-if="organisationData!=null">
                        <div data-background-color="blue" class="card" v-if="organisationData.verified==0">
                            <div class="card-body">
                                <span class="text-white">Your request for Org is pending for the approval</span>
                            </div>
                        </div>
                        <div class="text-center" v-else-if="organisationData.verified==1">
                           <span style="font-size: 21px">
                                <img style="margin-right: 10px" height="35" width="35" :src="organisationData.logo_url">{{ organisationData.name }}
                           </span>
                        </div>
                        <div data-background-color="red" class="card" v-else-if="organisationData.verified==2">
                            <div class="card-body">
                                <span class="text-white">Organisation request rejected</span>
                                <br>
                            </div>
                        </div>
                    </div>
                    <div data-background-color="red" class="card" v-else-if="organisationData==null">
                        <div class="card-body">
               <span class="text-white first_lbl">
               <i aria-hidden="true" class="fa fa-star"></i> New
               </span>
                            <span class="text-white second_lbl">Click here - To Create Your Organisation.</span>
                            <span class="text-white apply-now"><b class="setzindex"><a
                                    href="settings/organisation">APPLY NOW</a></b></span>
                        </div>
                    </div>
                </div>

                <v-offline
                        online-class="online"
                        offline-class="offline"
                        @detected-condition="amIOnline">
                    <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                        {{ onlineOfflineMsg }}
                    </template>
                    <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                        {{ onlineOfflineMsg }}
                    </template>
                </v-offline>

                <transition name="slide-fade" mode="out-in">
                    <router-view></router-view>
                </transition>

            </div>
        </div>
    </div>
</template>

<script>

import VOffline from 'v-offline';

export default {

    components: {
        VOffline
    },
    data()
    {
        return {
            organisationData: []
        }
    },
    methods: {

        getUsersDetails()
        {
            axios.get("/user")
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        var userData = response.data.response.data.user;
                        var user_name = userData.first_name + ' ' + userData.last_name;
                        var user_image = userData.photo_url;
                        var obj = {userName: user_name, userImage: user_image}
                        localStorage.setItem('userdata', JSON.stringify(obj))
                        this.organisationData = response.data.response.data.organisation;
                    }
                })
                .catch(error => {
                });
        },

    },
    created()
    {
        this.getUsersDetails();
    }


}


</script>

<style>
.platform-main-panel {
    width: 100%;
    overflow: hidden;
    position: relative;
    float: right;
    background-color: #f3efde !important;
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.platform-main-panel > .content {
    padding: 0 0 10px !important;
    margin-top: 0;
    min-height: calc(100vh - 123px);
}

/*.card .card-body {
    text-align: center;
}*/

.search-bar {
    color: black;
}

.nav_link_list ul {
    margin: 10px 0 10px 0;
    padding: 3px 0 3px 0;
}

.nav_link_list li {
    display: inline-block;
    margin: 7px 6px 7px 6px;
    padding: 6px 7px 1px 6px;
    color: black;
    font-size: 18px;
    font-weight: 500;
    height: 25px;
}

.nav_link_list a {
    padding: 0 !important;
    margin: 0 !important;
}

.nav_link_list .input-group-text {
    padding: 0 !important;
    margin: 0 !important;
}

.nav_link_list .nav-icons > li > a i {
    margin-bottom: 0px;
}

.nav_link_list .input-group-append i {
    font-size: 18px;
}

.nav_link_list .input-group-append {
    padding: 0px 11px 0px 0px;
    width: 33px;
}

.navbar.navbar-transparent.setGery {
    background-color: #e4d9d9 !important;
}

.navbar.navbar-transparent.setWhite {
    background-color: white !important;
}

.navbar.navbar-transparent.nav_link_list {
    padding: 0;
    z-index: 1;
}

.nav_link_list .nav-link {
    font-size: 13px !important;
}

.dropdown-content {
    background-color: #ffffff;
    border: 0 none;
    border-radius: 12px;
    margin-top: -10px;
    padding: 0px;
    display: none;
}

.hoverclick:hover .dropdown-content {
    display: block;
}

.showdropdowndiv {
    position: absolute;
    margin-left: calc(100% - 79%);
}

.showdfilterdropdowndiv {
    position: absolute;
    margin-left: calc(100% - 79%);
}

.showtrportdropdowndiv {
    position: absolute;
    margin-left: calc(100% - 79%);
}

.showdropdowndivReports {
    position: absolute;
    margin-left: calc(100% - 81%);
}

.setdropdownfont::after {
    left: 10 !important;
    top: -12px;
    right: unset !important;
}

.setdropdownfont:before {
    left: 10 !important;
    top: -12px;
    right: unset !important;
}

.dropdown-menu .dropdown-item {
    padding: 10px 45px 10px 15px !important;
}

.nav_link_list {
    background: white;
    margin-bottom: 0px !important;
}

.text-search {
    margin: -9px !important;
    margin-left: 11px !important;
}

/*.border-left-set {
border-left: 1px solid;
}
.border-right-set {
border-right: 1px solid;
}*/
.boxshadow {
    box-shadow: 0px 5px 7px 5px rgba(0, 0, 0, 0.15) !important;
}

.boxshadowsetheader {
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15) !important;
}

.set_search_textbox {
    margin-right: 28px;
}

.set_textbox_left {
    position: relative;
    left: -27px;
}

@media only screen and (max-device-width: 768px) {
    /* .setTitleDiv {
    margin-top: 109px;
    } */
    .set_msg {
        margin-top: 35% !important;
    }

    .set_textbox_left {
        margin-left: 53px;
    }

    .res_prj_btn {
        margin-left: 21px;
    }
}

/* Pradeep Start */
.set_assignProject {
    margin-top: 12px !important;
}

@media (min-width: 320px) and (max-width: 375px) {
    .set_assignProject {
        margin-top: 98px !important;
    }
}

@media (min-width: 425px) and (max-width: 768px) {
    .set_assignProject {
        margin-top: 70px !important;
    }
}

/* Dashboard,Filters and Reports dropdown responsive */
@media (min-width: 320px) and (max-width: 425px) {
    .showdropdowndiv {
        z-index: 1 !important;
        margin-top: -19px !important;
    }

    .showdfilterdropdowndiv {
        z-index: 1 !important;
        margin-top: -20px !important;
        margin-left: 42px !important;
    }

    .showtrportdropdowndiv {
        z-index: 1 !important;
        margin-top: -20px !important;
        margin-left: 42px !important;
    }

    .showdropdowndivReports {
        position: absolute;
        margin-left: calc(100% - 70%);
    }
}

@media (min-width: 768px) and (max-width: 769px) {
    .showdropdowndiv {
        z-index: 1 !important;
        margin-top: -20px !important;
    }

    .showdfilterdropdowndiv {
        z-index: 1 !important;
        margin-top: -20px !important;
        margin-left: 40px !important;
    }

    .showtrportdropdowndiv {
        z-index: 1 !important;
        margin-top: -20px !important;
        margin-left: 50px !important;
    }

    .showdropdowndivReports {
        position: absolute;
        margin-left: calc(100% - 75%);
        z-index: 10;
    }
}

@media (min-width: 1024px) and (max-width: 1025px) {
    .showdropdowndiv {
        position: absolute;
        margin-left: calc(100% - 65%);
    }

    .showdfilterdropdowndiv {
        position: absolute;
        margin-left: calc(100% - 65%);
    }

    .showtrportdropdowndiv {
        position: absolute;
        margin-left: calc(100% - 65%);
    }

    .nav_link_list .nav-link {
        font-size: 11px !important;
    }

    .showdropdowndivReports {
        position: absolute;
        margin-left: calc(100% - 66%);
    }
}

.el-autocomplete {
    width: 230px !important;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000000000000;
}

@media (min-width: 1026px) and (max-width: 1440px) {
    .showdropdowndivReports {
        position: absolute;
        margin-left: calc(100% - 71%);
    }
}

.first_lbl {
    left: calc(100% - 113%);
    top: -4px;
    position: relative;
    font-size: 15px;
    font-weight: 600;
}

.second_lbl {
    font-size: 13px;
    font-weight: 600;
    font-size: 13px;
    font-weight: 600;
    position: relative;
    top: -4px;
}

.third_lbl {
    left: 10%;
    top: -6px;
    position: relative;
    font-size: 18px;
    font-weight: 600;
}

.four_lb {
    left: 15%;
    top: -6px;
    position: relative;
    font-size: 18px;
    font-weight: 600;
}

.first_lbl i {
    font-size: 15px;
    color: yellow;
}

.third_lbl i {
    font-size: 20px;
    color: green;
}

.four_lb i {
    font-size: 20px;
    color: yellow;
}

.apply-now {
    position: relative;
    left: 168px;
    top: -26px;
    display: inherit;
}

.setHeader {
    width: 50%;
    position: absolute;
    top: 25px;
    right: calc(100% - 74%);
    z-index: 9999
}

.setzindex {
    position: absolute;
    z-index: 9999999;
}

@media only screen and (max-device-width: 768px) {
    .setheader {
        top: 92px !important;
        width: 100% !important;
        padding-left: 8px;
        padding-right: 8px;
        left: 0px !important;
        right: 0px !important;
    }
}

@media only screen and (max-device-width: 1024px) {
    .setheader {
        width: 44%;
        position: absolute;
        top: 8px;
        left: 222px;
        right: calc(100% - 63%);
        z-index: 9999;
    }
}

@media (min-width: 241px) and (max-width: 320px) {
    .apply-now {
        position: relative;
        left: 34px;
        top: -26px;
        display: inherit;
    }

    .second_lbl {
        font-size: 13px;
        font-weight: 600;
        font-size: 13px;
        font-weight: 600;
        position: relative;
        top: -4px;
        left: -28px;
    }

    .first_lbl {
        left: calc(100% - 113%);
        top: -4px;
        position: relative;
        font-size: 15px;
        font-weight: 600;
    }
}

@media (min-width: 780px) and (max-width: 1024px) {
    .apply-now {
        position: relative;
        left: 124px;
        top: -26px;
        display: inherit;
    }

    .second_lbl {
        font-size: 13px;
        font-weight: 600;
        font-size: 13px;
        font-weight: 600;
        position: relative;
        top: -4px;
        left: -41px;
    }
}

@media (min-width: 321px) and (max-width: 425px) {
    .first_lbl {
        left: calc(100% - 102%);
        top: -11px;
        position: relative;
        font-size: 15px;
        font-weight: 600;
    }

    .second_lbl {
        font-size: 13px;
        font-weight: 600;
        position: relative;
        top: -12px;
        left: 4px;
    }

    .apply-now {
        position: relative;
        left: -32px;
        top: -13px;
        display: inherit;
    }
}

@media (min-width: 241px) and (max-width: 320px) {
    .setHeader {
        z-index: 0;
    }
}

@media only screen and (max-width: 411px) {
    .setHeader {
        z-index: 0;
    }
}

@media (min-width: 341px) and (max-width: 375px) {
    .setHeader {
        z-index: 0;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .setHeader {
        z-index: 0;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .setHeader {
        z-index: 0;
    }
}
</style>
