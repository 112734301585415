import Vue from 'vue';
import Vuex from 'vuex';

import demo from './modules/demo';
import user from './modules/user';
import animal from './modules/animal';
import project from './modules/project';
import auth from './modules/auth';

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        demo,
        user,
        animal,
        project,
        auth
    },
    plugins: [createPersistedState()]
});
