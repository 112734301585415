import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VueNotify from 'vue-notifyjs';

import GlobalComponents from '../globalComponents';
import GlobalDirectives from '../globalDirectives';

import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

import store from '../store';

import swal from 'sweetalert2';



import SideBar from '../components/Template/UIComponents/SidebarPlugin/index';
import TopnavBar from '../components/Template/Layout/TopNavbar.vue';
import ContentFooter from '../components/Template/Layout/ContentFooter.vue';
import UserMenu from "../components/Template/UIComponents/SidebarPlugin/UserMenuNew.vue";
import UserNavbar from '../components/Template/Layout/UserNavbar.vue';
//Pradeep//
import ProjectNavbar from '../components/Template/Layout/ProjectNavbar.vue';
import HelpModal from "../components/Template/Layout/HelpModal";
//End//
const queryString = require('query-string');
Vue.use(SideBar);

Vue.use(VeeValidate);
Vue.use(VueNotify);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
locale.use(lang);

export default {
    store,
    data() {
        return {
            appConfig: {},
            onLine: true,
            wasOffline:false,
            onlineSlot: 'online',
            offlineSlot: 'offline',
        };
    },
    components: {
        'top-navbar': TopnavBar,
        'user-navbar': UserNavbar,
        'project-navbar':ProjectNavbar,
        'content-footer': ContentFooter,
        UserMenu,
        "help-modal":HelpModal
    },
    created() {
        this.appConfig = window.appConfig;
    },
    methods: {
        convertDate(convertDate, toFormat, timezone){
            let localTime = moment.utc(convertDate).toDate();
            try {
                return moment(localTime).tz(timezone).format(toFormat);
            }catch (e){
                console.log("maybe timezone not given");
            }
        },
        activeAgo(lastActiveDate, timezone){
            let localTime = moment.utc(lastActiveDate).toDate();
            return moment(localTime).tz(timezone).fromNow();
        },
        showAlert(title, text, success,callback=null){
            swal({
                title: title,
                text: text,
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-success btn-fill',
                type: success ? 'success' : 'error',
            }).then(callback);
        },
        getError: function getError(fieldName) {
            return this.errors.first(fieldName);
        },
        amIOnline(e) {
            this.onLine = e;
            if(this.onLine){
                if(this.wasOffline) {
                    setTimeout(function () {
                        document.querySelector('.online').style.display = "block";
                    }, 100);
                    setTimeout(function () {
                        document.querySelector('.online').style.display = "none";
                    }, 2000)
                }
            }else{
                setTimeout(function () {
                    document.querySelector('.offline').style.display="block";
                }, 100);
            }
        },
    },
    computed: {
        appName() {
            return this.appConfig.appName;
        },
        sidebarLinks() {
            return this.appConfig.sidebarLinks;
        },
        onlineOfflineMsg: function () {
            if (this.onLine) {
                if(this.wasOffline){
                    this.wasOffline=false;
                    setTimeout(function(){
                        document.querySelector('.online').style.display="none";
                    },2000)
                    return "You are back Online."
                }
            } else {
                this.wasOffline=true;
                setTimeout(function(){
                    document.querySelector('.offline').style.display="none";
                },60000)
                return "You have gone Offline, Please check you network connection.";
            }
        },
    },
};
