import * as types from '../mutation-types';

const state = {

    vaccinationDrug:[]

};

const getters = {

    vaccinationDrug: state => state.vaccinationDrug,

};

const mutations = {

    [types.SET_VACCINATION_DRUG](state, data){
        state.vaccinationDrug=data.vaccination_drug;
    }

};

const actions = {

    vaccinationDrug({commit}){
        return axios.get('project/vaccination_drug').then(r => {
            commit(types.SET_VACCINATION_DRUG,r.data.response.data);
        });
    }

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}