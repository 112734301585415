export const SET_DEMO_DATA = "SET_DEMO_DATA";
export const APPEND_DEMO_DATA = "APPEND_DEMO_DATA";
export const SET_SELECTED_DATA = "SET_SELECTED_DATA";

export const SET_USER_PROJECTS = "SET_USER_PROJECTS";
export const ADD_PROJECT = "ADD_PROJECT";

export const SET_ANIMALS = "SET_ANIMALS";

export const SET_USER_ROLES = "SET_USER_ROLES";

export const SET_ORGANISATION = "SET_ORGANISATION";

export const SET_USER_ORGANISATIONS = "SET_USER_ORGANISATIONS";

export const SET_VACCINATION_DRUG = "SET_VACCINATION_DRUG";

export const SET_SELECTED_PROJECT_ID = "SET_SELECTED_PROJECT_ID";

export const SET_JWT_TOKEN = "SET_JWT_TOKEN";

export const SET_AUTHENTICATION_ERROR = "SET_AUTHENTICATION_ERROR";

export const UNSET_JWT_TOKEN = "UNSET_JWT_TOKEN";
