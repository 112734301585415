<template>

    <div>
        <top-navbar button-name="Sign-Up"
                    style="border-bottom: 0; position: absolute; top: 0; background-color: transparent !important;"></top-navbar>
        <div class="login-page">
            <div class="wrapper wrapper-full-page">
                <div class="full-page login-page section-image">
                    <div class="content">
                        <div class="container">

                            <div class="col-lg-4 col-md-6 ml-auto mr-auto">

                                <card type="login" footer-line>
                                    <h4 slot="header" class="header text-center">HSAPPS</h4>

                                    <div class="text-left">
                                        <div class="form-group">
                                            <fg-input type="email"
                                                      required
                                                      label="Email Address"
                                                      name="email"
                                                      v-validate="modelValidations.email"
                                                      :error="getError('email')"
                                                      v-model="form.email">
                                            </fg-input>
                                        </div>

                                        <div class="form-group">
                                            <fg-input type="password"
                                                      required
                                                      label="Password"
                                                      name="password"
                                                      v-validate="modelValidations.password"
                                                      :error="getError('password')"
                                                      v-model="form.password">
                                            </fg-input>
                                        </div>
                                        <p-checkbox name="rememberMe" :inline="true" v-model="model.rememberMeChecked"
                                                    v-on:change="setRemember">
                                            Remember Me
                                        </p-checkbox>
                                        <br>
                                        <hr>
                                        <div slot="footer">
                                            <button v-on:click="tryLogin"
                                                    class="btn mb-3 btn-round btn-block btn-info">
                                                LOGIN
                                            </button>
                                            <GoogleLogin :params="params" :renderParams="renderParams"
                                                         :onSuccess="onSuccess" :onFailure="onFailure"></GoogleLogin>

                                            <div style="margin-top: 10px">
                                                <router-link to="forgot">
                                                    <a style="margin-top: 21px;margin-left: 30%;">Forgot Password</a>
                                                </router-link>
                                            </div>
                                        </div>

                                    </div>

                                </card>

                            </div>
                        </div>
                    </div>
                    <div class="full-page-background"></div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {Button, Card, Checkbox, InfoSection} from "./UIComponents";
import PCheckbox from "./UIComponents/Inputs/Checkbox";
import TopNavbar from "./Layout/TopNavbarNew";

import GoogleLogin from 'vue-google-login';

import {mapActions} from "vuex";

export default {
    components: {
        Card,
        [Checkbox.name]: Checkbox,
        [Button.name]: Button,
        InfoSection,
        PCheckbox,
        'top-navbar': TopNavbar,
        GoogleLogin
    },
    data()
    {
        return {
            params: {
                client_id: "1032811329065-lbrj0nuk4rfj6500rhdsp84sq44j6gbk.apps.googleusercontent.com",
                //redirect_uri: "http://127.0.0.1:8000/login"
            },
            // only needed if you want to render the button with the google ui
            renderParams: {
                width: 300,
                height: 50,
                longtitle: true
            },
            model: {
                email: '',
                password: '',
                rememberMeChecked: false,
                isLoggingIn: false,
            },
            modelValidations: {
                email: {
                    required: true,
                    email: true,
                },
                password: {
                    required: true,
                    min: 6,
                },
            },

            reset_email: '',
            form: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                password: '',
                password_confirmation: '',
                acceptTerms: false,
                isEmailDisabled: false,
            },
            formValidations: {
                firstName: {
                    alpha_spaces: true,
                },
                lastName: {
                    alpha_spaces: true,
                },
                email: {
                    required: true,
                    email: true,
                },
                acceptTerms: {
                    required: true
                },
                password: {
                    required: true,
                    min: 6,
                },
                phone: {
                    required: true,
                    min: 10,
                    max: 15,
                },
            }
        }
    },
    methods: {
        ...mapActions(["LogIn", "GoogleLogIn"]),
        getError(fieldName)
        {
            return this.errors.first(fieldName);
        },
        onSuccess(googleUser)
        {
            console.log(googleUser);

            // This only gets the user information: id, name, imageUrl and email
            console.log(googleUser.getBasicProfile());
            let user = {
                email: googleUser.getBasicProfile().getEmail(),
                google_id: googleUser.getBasicProfile().getId(),
                google_token: googleUser.$b.access_token,
                first_name: googleUser.getBasicProfile().getGivenName(),
                last_name: "",
                photo_url: googleUser.getBasicProfile().getImageUrl()
            };

            this.tryGoogleLogin(user)

        },
        onFailure(e)
        {
            console.log(e);
        },
        setRemember()
        {
            this.model.rememberMeChecked = !this.model.rememberMeChecked;
        },

        validateBeforeSubmit(e)
        {
            e.preventDefault();
            this.$validator.validateAll().then((result) => {
                if (result) {
                    // eslint-disable-next-line
                    document.querySelector('#loginForm').submit();
                }
                return false;
            }).catch(() => {
                return false
            });
        },
        validateBeforeadminSubmit(e)
        {
            let vm = this;
            e.preventDefault();
            vm.$validator.validateAll().then((result) => {
                if (result) {
                    // eslint-disable-next-line
                    vm.trylogin();
                    /* axios.post('/user/login', {
                         email: vm.form.email,
                         password: vm.form.password,
                     }).then(response => {
                         if (response.data.response.error.error_code === 0) {
                             vm.showAlert('Success', 'Welcome ' + vm.form.email, true);
                         }
                     }).catch(error => {
                         console.log(error)
                         vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                     });*/
                }
                return false;
            }).catch(() => {
                return false
            });
        },
        async tryLogin()
        {
            let user = {
                email: this.form.email,
                password: this.form.password
            }
            try {
                await this.LogIn(user)
                this.$router.push("/platform/projects");
            } catch (e) {
                this.showAlert('Error', this.$store.state.auth.authenticationErrorMsg, false);
            }
        },
        async tryGoogleLogin(user)
        {
            try {
                await this.GoogleLogIn(user)
                this.$router.push("/platform/projects");
            } catch (e) {
                this.showAlert('Error', this.$store.state.auth.authenticationErrorMsg, false);
            }
        },
        validateSignUpForm(e)
        {
            var acceptTerms = document.querySelector("input[name=acceptTerms]").checked;
            if (!acceptTerms) {
                $("#error").show();
            } else {
                $("#error").hide();
            }
            e.preventDefault();
            this.$validator.validateAll().then((result) => {
                if (result) {
                    // eslint-disable-next-line
                    document.querySelector('#signupForm').submit();
                }
                return false;
            }).catch(() => {
                return false
            });
        },
        getErrorAcceptTerms()
        {
            setTimeout(function () {
                var acceptTerms = document.querySelector("input[name=acceptTerms]").checked;
                if (acceptTerms) {
                    $("#error").hide();
                } else {
                    $("#error").show();
                }
            }, 200);
        },
        validateresetSubmit(e)
        {
            e.preventDefault();
            this.$validator.validateAll().then((result) => {
                if (result) {
                    // eslint-disable-next-line
                    document.querySelector('#resetForm').submit();
                }
                return false;
            }).catch(() => {
                return false
            });
        },
        validateforgotSubmit(e)
        {
            e.preventDefault();
            this.$validator.validateAll().then((result) => {
                if (result) {
                    // eslint-disable-next-line
                    document.querySelector('#forgotForm').submit();
                }
                return false;
            }).catch(() => {
                return false
            });
        },
        validateNumber(e)
        {
            let val = e.target.value;
            let rex = /^[0-9+]+$/;
            if (rex.test(val)) {
                return true;
            } else {
                e.target.value = val.slice(0, -1);
            }
        }
    },
    mounted()
    {
        if (window.localStorage.getItem('hsapps-expired') !== null) {
            window.localStorage.removeItem('hsapps-expired');

            toastr.error('Your session has expired.');
        }
        setTimeout(function () {
            $("#error").hide();
        }, 201);
    },
    created()
    {
        if (document.querySelector("input[name=invitation_email]")) {
            let invitationEmail = document.querySelector("input[name=invitation_email]").value;
            this.form.email = invitationEmail;
            if (invitationEmail !== '') {
                this.form.isEmailDisabled = true;
            }
        }

    }
}
</script>

<style type="text/css" scoped>
.buttonright {
    position: absolute;
    width: 113px;
    right: 0;
    margin-right: 20px;
    top: 18px;
}

.setformheight {
    height: 65px;
}

.loginBtn--google {
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin: 0.2em;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 16px;
    box-shadow: 1px 1px 6px grey;
    padding: 5px;
}

.navbar-toggler {
    display: none;
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation: portrait) {
    .buttonright {
        top: 6px !important;
    }
}

@media only screen and (max-device-width: 768px) {
    .buttonright {
        top: -5px;
    }

}

</style>
