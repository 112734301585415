<template>
    <div id="isModal" class="modal modal-help fade" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content modal-content-help">
                <div class="help_contact">HSApps Help</div>
                <div class="modal-header new-header">
                    <button type="button" class="close close-help" @click="closeModal()">&times;</button>
                    <a class="help-link1" href="#" @click="showTableContentsIframe()">Table of Contents</a>
                    <a class="help-link2" href="#" @click="showFAQIframe()">FAQ</a>
                    <a class="help-link3" href="#" @click="showContactSupportIframe()">Contact Support</a>
                </div>
                <div class="modal-body-help modal-body">
                    <iframe class="help-iframe" id="help-iframe" src=""></iframe>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data()
    {

    },
    props: {
    },
    methods: {
        closeModal()
        {
            document.getElementById('isModal').style.display = "none";
        },
        showTableContentsIframe()
        {
            document.getElementById("help-iframe").src = window.USER_MANUAL_URL;
        },
        showFAQIframe()
        {
            document.getElementById("help-iframe").src = window.USER_MANUAL_URL + "#h.kn7r5bd60tqd";
        },
        showContactSupportIframe()
        {
            document.getElementById("help-iframe").src = window.USER_MANUAL_URL + "#h.e3b877uzdiw";
        },
        loadUrl(url)
        {
            document.getElementById('isModal').style.display="block";
            document.getElementById("help-iframe").src = url;
        }
    },
    computed: {
    },
    mounted()
    {

    }
};

</script>
<!--pradeep -->
<style lang="scss">
/*modal css*/
.modal-help {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  padding: 30px;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.66); /* Black w/ opacity */
  display: none;
  opacity: 1 !important;
}

.modal-body-help {
  padding: 5px !important;
  color: #000 !important;
  height: 80% !important;
  margin-bottom: 0px !important;
}

.edit-header {
  height: 60px !important;
  margin-top: -13px !important;;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 900px !important;
    margin: 1.75rem auto;
  }
}

@media (max-width: 576px) {
  .modal-help {
    padding: 10px;
  }
}

.close-help {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 15px;
  top: 24px;
}

.close-help:hover, .close-help:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-content-help {
  border-radius: 3px;
  // height: 80%;
  height: 550px;
}

.new-header {
  border-bottom: 1px solid #DDDDDD;
  padding: 10px;
  display: block !important;
  padding-bottom: 4px;
  text-align: left;
}

.help-iframe {
  width: 100%;
  height: 100%;
}

.help-link1 {
  color: #3490dc !important;
  margin-right: 15px;
}

.help-link2 {
  color: #3490dc !important;
  margin-right: 15px;
}

.help-link3 {
  color: #3490dc !important;
}

.help_contact {
  padding-left: 10px;
  padding-top: 10px;
  font-size: 18px;
  font-weight: bold;
}
</style>
<!--End-->
