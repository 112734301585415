<template>
    <div>
		<v-offline
			online-class="online"
			offline-class="offline"
			@detected-condition="amIOnline">
		<template v-slot:[onlineSlot] :slot-name="onlineSlot">
			{{ onlineOfflineMsg }}
		</template>
		<template v-slot:[offlineSlot] :slot-name="offlineSlot">
			{{ onlineOfflineMsg }}
		</template>
		</v-offline>
        <div class="row setMarginTop">
			<div class="col-xs-6 col-sm-6 col-md-4 col-xl-4">
				<div class="card setShadow">
					<div class="card-body">
						<div>
							<div class="row">
								<div class="col-12">
									<div class="numbers">
										<p>Average surgery per day</p>
										<!-- 999 -->
										UC
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xs-6 col-sm-6 col-md-4 col-xl-4">
				<div class="card setShadow">
					<div class="card-body">
						<div>
							<div class="row">
								<div class="col-12">
									<div class="numbers">
										<p>Projects </p>
										<!-- 50 -->
										UC
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xs-6 col-sm-6 col-md-4 col-xl-4">
				<div class="card setShadow">
					<div class="card-body">
						<div>
							<div class="row">
								<div class="col-12">
									<div class="numbers">
										<p>Sterilisation </p>
										<!-- 9,99,999 -->
										UC
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row setMarginTop">
			<div class="col-xs-6 col-sm-6 col-md-3 col-xl-2">
				<div class="card setShadow">
					<div class="card-body">
						<div>
							<div class="row">
								<div class="col-12">
									<div class="numbers">
										<p>Male</p>
										<!-- 499 -->
										UC
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xs-6 col-sm-6 col-md-3 col-xl-2">
				<div class="card setShadow">
					<div class="card-body">
						<div>
							<div class="row">
								<div class="col-12">
									<div class="numbers">
										<p>Female</p>
										<!-- 499 -->
										UC
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xs-6 col-sm-6 col-md-3 col-xl-2">
				<div class="card setShadow">
					<div class="card-body">
						<div>
							<div class="row">
								<div class="col-12">
									<div class="numbers">
										<p>Young's</p>
										<!-- 1 -->
										UC
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xs-6 col-sm-6 col-md-3 col-xl-2">
				<div class="card setShadow">
					<div class="card-body">
						<div>
							<div class="row">
								<div class="col-12">
									<div class="numbers">
										<p>Average Surgery Time</p>
										<!-- 12M -->
										UC
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xs-6 col-sm-6 col-md-3 col-xl-2">
				<div class="card setShadow">
					<div class="card-body">
						<div>
							<div class="row">
								<div class="col-12">
									<div class="numbers">
										<p>Post op Complications</p>
										<!-- 0 -->
										UC
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xs-6 col-sm-6 col-md-3 col-xl-2">
				<div class="card setShadow">
					<div class="card-body">
						<div>
							<div class="row">
								<div class="col-12">
									<div class="numbers">
										<p>Mortality Percentage</p>
										<!-- 0% -->
										UC
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row setMarginTop set_res_margin_top">
			<div class="col-xs-12 col-sm-12 col-md-12 col-xl-12">
				<div class="row setTitleDiv">
					<p></p>
				</div>
				<!-- Pradeep Start -->
				<div class="setMarginTop">
					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-6">
							<div class="card setShadow">
								<!---->
								<!--<div class="card-header">
									<div class="containBytitile">
										<h4 class="card-title">
											Handling Method - for Catching
										</h4>
									</div>
									<p class="card-category">
									</p>
								</div>-->
								<div class="card-body" style="height: 435px;">
									<!--<pie-charts></pie-charts>-->
									<highcharts :options="PieChartOptions"></highcharts>
								</div>
							</div>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-6">
							<!--<line-mvd  charttitle='PM- Monthly Progress'></line-mvd>-->
							<div class="card setShadow">
								<div class="card-body" style="height: 435px;">
									<highcharts :options="LineChartOptions"></highcharts>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Pradeep End -->
			</div>
		</div>
		<div class="row setMarginTop set_res_margin_top">
			<div class="col-xs-12 col-md-12 col-xl-12">
				<div class="row setTitleDiv">
					<p>Field User - Stats</p>
				</div>
				<div class="row setMarginTop">
					<div class="col-xs-6 col-sm-12 col-md-6 col-md-6 col-xl-6">
						<div class="card">
							<div class="card-body setShadow set_res_activity_status-pm">
								<!--<div class="card-header">
									<div class="containBytitile">
										<h4 class="card-title">
											Number of User Active in last 10 Days
										</h4>
									</div>
									<p class="card-category"></p>
								</div>
								<charts></charts>-->
								<highcharts :options="RowLineOptions"></highcharts>
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-xs-6 col-md-6 col-xl-6 desktop_datatable">
						<div class="card" style="max-height: 603px;overflow: scroll;overflow-x: hidden;">
							<div class="card-header">
								<div class="containBytitile">
									<h4 class="card-title">Activity status</h4>
								</div>
								<p class="category"></p>
							</div>
							<div class="card-body">
								<dash-tables></dash-tables>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<div class="row setMarginTop">
			<div class="row col-md-12 col-xl-12">
				<div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
					<div class="card setShadow">
						<div class="card-body">
							<div>
								<div class="row">
									<div class="col-12">
										<div class="numbers">
											<p>Catch-Release users</p>
											<!-- 99 -->
											UC
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
					<div class="card setShadow">
						<div class="card-body">
							<div>
								<div class="row">
									<div class="col-12">
										<div class="numbers">
											<p>Clinic Users</p>
											<!-- 99 -->
											UC
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
					<div class="card setShadow">
						<div class="card-body">
							<div>
								<div class="row">
									<div class="col-12">
										<div class="numbers">
											<p>AVG Animal treated per user/ day</p>
											<!-- 22 -->
											UC
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xs-6 col-sm-6 col-md-3 col-xl-3">
					<div class="card setShadow">
						<div class="card-body">
							<div>
								<div class="row">
									<div class="col-12">
										<div class="numbers">
											<p>Deactive user</p>
											<!-- 0 -->
											UC
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row setMarginTop">
			<div class="col-md-12 col-xl-12 col-sm-12 col-xs-12 set_res_margin_top">
				<div class="row setTitleDiv">
					<p>Admin Users</p>
				</div>
				<div class="row setMarginTop">
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Manager</p>
												<!-- 1 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Contributors</p>
												<!-- 3 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Viewers</p>
												<!-- 2 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-3">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Admin Users</p>
												<!-- 10 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-3">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Non-Active user</p>
												<!-- 1 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row setMarginTop set_res_margin_top">
			<div class="col-sm-12 col-xs-12 col-md-12 col-xl-12">
				<div class="row setTitleDiv">
					<p>All User Info - Stats</p>
				</div>
				<div class="row setMarginTop">
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Field Users</p>
												<!-- 50 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Vets</p>
												<!-- 10 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Sr. AWO's</p>
												<!-- 5 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>AWO's</p>
												<!-- 6 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Project Managers</p>
												<!-- 5 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Project Managers &Vets </p>
												<!-- 5 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Assistant Manager</p>
												<!-- 5 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Assistant Manager & Vets </p>
												<!-- 5 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Drivers </p>
												<!-- 5 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Drivers & AWOs </p>
												<!-- 5 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Vaccinators </p>
												<!-- 5 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Dynamic 1 </p>
												<!-- 0 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Dynamic 2 </p>
												<!-- 0 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Dynamic 3</p>
												<!-- 0 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xs-6 col-md-3 col-xl-2">
						<div class="card setShadow">
							<div class="card-body">
								<div>
									<div class="row">
										<div class="col-12">
											<div class="numbers">
												<p>Dynamic 4</p>
												<!-- 0 -->
												UC
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
    import DashboardCharts from "../../Template/Dashboardchart.vue";
    import DashboardlineCharts from "../../Template/Dashboardlinechart.vue";
    import DashboardpieCharts from "../../Template/DashboardpieCharts.vue";
	import DashboardTab from "../../Template/Tables/DashboardTables";
	import Mvd from "../../Template/Mvd.vue";
	import mixin from '../../../mixins/GlobalMixin';
	import VOffline from 'v-offline';

	// Highcharts Start
	import {Chart} from 'highcharts-vue';
	import Highcharts from 'highcharts';
	import exportingInit from 'highcharts/modules/exporting';
	import exportingInitData from 'highcharts/modules/export-data';
	exportingInit(Highcharts);
	exportingInitData(Highcharts);
	// Highcharts End

    export default{
        components: {
            'charts': DashboardCharts,
            'line-charts':DashboardlineCharts,
            'pie-charts':DashboardpieCharts,
			'dash-tables': DashboardTab,
			'line-mvd':Mvd,
			VOffline,
			highcharts:Chart
        },
		mixins: [mixin],
		data(){
			return{
				//Pie Chart Start
				PieChartOptions: {
					chart: {
						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
						type: 'pie',
						style: {
							fontFamily: 'Montserrat,Helvetica Neue,Arial,sans-serif',
							fontSize:"10px"
						}
					},
					title: {
						text: 'Animals Treated (Species)',
						align:"left",
						style:{ "color": "#252422", "fontSize": "16px","fontWeight": "400","fontFamily": "Montserrat,Helvetica Neue,Arial,sans-serif"}
					},
					tooltip: {
						pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
					},
					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: 'pointer',
							dataLabels: {
							enabled: true,
							format: '<b>{point.name}</b>: {point.percentage:.1f} %',
							style:{
								fontWeight:"400"
							}
							}
						}
					},
					series: [{
					name: 'Brands',
					colorByPoint: true,
					data: [{
						name: 'Dog',
						y: 26.32,
						// sliced: true,
						// selected: true,
						color:'#98ccaf',
					}, {
						name: 'Cat',
						y: 26.32,
						color:'#4acccd'
					}, {
						name: 'Cattels',
						y: 19.74,
						color:'#fcc468'
					}, {
						name: 'Horse',
						y: 13.16,
						color:'#ed7107'
					}, {
						name: 'Camel',
						y: 14.47,
						color:'#ffed4a'
					}]
					}]
				},
				//Pie Chart End
				//Line Chart Start
				LineChartOptions: {
					chart: {
					type: 'column',
					style: {
						fontFamily: 'Montserrat,Helvetica Neue,Arial,sans-serif',
					}
					},
					title: {
						text: 'Monthly Total Vaccinations',
						align:"left",
						style:{ "color": "#252422", "fontSize": "16px","fontWeight": "400","fontFamily": "Montserrat,Helvetica Neue,Arial,sans-serif"}
					},
					subtitle: {
						text: ''
					},
					xAxis: {
						categories: [
							'1',
							'2',
							'3',
							'4',
							'5',
							'6',
							'7',
							'8',
							'9',
							'10',
							'11',
							'12'
						],
						crosshair: true
					},
					yAxis: {
						min: 0,
						title: {
							text: ''
						}
					},
					plotOptions: {
						column: {
							pointPadding: 0.2,
							borderWidth: 0
						}
					},
					series: [{
						name: 'Tokyo',
						color:'#4acccd',
						data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
					}, {
						name: 'New York',
						color:'#ed7107',
						data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3]
					},
				]
				},
				//Line Chart End
				//Row Line Chart Start
				RowLineOptions:{
					chart: {
						style: {
							fontFamily: 'Montserrat,Helvetica Neue,Arial,sans-serif',
						}
					},
					title: {
						text: 'Active Field Users, then a subtitle that says "(Past 10 Days)"',
						align:"left",
						style:{ "color": "#252422", "fontSize": "16px","fontWeight": "400","fontFamily": "Montserrat,Helvetica Neue,Arial,sans-serif"}
					},
					xAxis: {
						tickInterval: 1,
						type: 'logarithmic'
					},
					yAxis: {
						type: 'logarithmic',
						minorTickInterval: 0.1
					},
					tooltip: {
						headerFormat: '<b>{series.name}</b><br />',
						pointFormat: 'x = {point.x}, y = {point.y}'
					},
					series: [{
						color:'#ed7107',
						data: [1, 2, 4, 8, 16, 32, 64, 128, 256, 512],
						pointStart: 1
					}]
				},
				//Row Line Chart End
			}
		},
		mounted() {
			 try{
				document.querySelectorAll(".highcharts-credits").forEach(function(el){
					el.innerHTML='';
				});
				document.querySelectorAll('tspan').forEach(function(element){
					element.style.fontWeight="400";
				});
			}catch(e){}
		}
    }
</script>
