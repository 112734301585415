import * as types from '../mutation-types';

const state = {

    organisation: null,

    isFetchingUserProjects: false,

    ownProjects: [],

    assignedProjects: [],

    userRoles: [],

    userOrganisations: [],

    selectedProjectId: 0,

};

const getters = {

    ownProjects: state => state.ownProjects,
    assignedProjects: state => state.assignedProjects,
    isFetchingUserProjects: state => state.isFetchingUserProjects,
    userRoles: state => state.userRoles,
    organisation: state => state.organisation,
    userOrganisations: state => state.userOrganisations,
    selectedProjectId: state => state.selectedProjectId,

};

const mutations = {

    [types.SET_USER_PROJECTS](state, data){
        state.ownProjects = data.user.own_projects;
        state.assignedProjects = data.user.assigned_projects;
    },

    [types.ADD_PROJECT](state, data){
        state.assignedProjects.push(data.project);
    },

    [types.SET_USER_ROLES](state, data){
        state.userRoles = data.roles;
    },

    [types.SET_ORGANISATION](state, data){
        state.organisation = data.organisation;
    },

    [types.SET_USER_ORGANISATIONS](state, data){
        if (data.own_organisation != null) {
            state.userOrganisations.push({
                organisation: {
                    id: data.own_organisation.id,
                    name: data.own_organisation.name + ' (Director)'
                },
            });
        }
        state.userOrganisations = [...state.userOrganisations, ...data.joined_organisations];
    },
    [types.SET_SELECTED_PROJECT_ID](state, selectedProjectId){
        state.selectedProjectId = selectedProjectId;
    },

};

const actions = {

    getOrganisation({commit}){
        return axios.get('/organisation').then(r => {
            commit(types.SET_ORGANISATION, r.data.response.data);
        });
    },

    getUserOrganisations({commit}){
        return axios.get('user/organisations').then(r => {
            commit(types.SET_USER_ORGANISATIONS, r.data.response.data);
        });
    },

    getProjects({commit}){
        return axios.get('/user/projects').then(r => {
            commit(types.SET_USER_PROJECTS, r.data.response.data);
        }).catch(function (error) {
            //location.href = '/logout';
        });
    },

    createProject({commit}, projectDetails){
        return axios.post('/project/create', projectDetails).then(r => {
            if (r.data.response.error.error_code == 0) {
                commit(types.ADD_PROJECT, r.data.response.data);
                return true;
            } else {
                console.log(r);
                return false;
            }
        }).catch(function (error) {
            let errors = [];
            for (let key in error.response.data.response.data) {
                console.log("Key: " + key);
                console.log("Value: " + error.response.data.response.data[key]);
                errors.push(error.response.data.response.data[key]);
            }
            console.log(error);
            return errors;
        });
    },

    getUserRoles({commit}){
        return axios.get('/user/roles').then(r => {
            if (r.data.response.error.error_code == 0) {
                commit(types.SET_USER_ROLES, r.data.response.data);
                return true;
            } else {
                console.log(r);
                return false;
            }
        }).catch(function (error) {
            let errors = [];
            for (let key in error.response.data.response.data) {
                console.log("Key: " + key);
                console.log("Value: " + error.response.data.response.data[key]);
                errors.push(error.response.data.response.data[key]);
            }
            console.log(error);
            return errors;
        });
    },

    setSelectedProjectId({commit}, projectId){
        commit(types.SET_SELECTED_PROJECT_ID, projectId);
    }

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
