<template>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <h5>{{ getTitle }}</h5>
                <hr/>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-xs-12 col-md-12 col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <table
                                id="example"
                                class="display table-bordered"
                                cellspacing="0"
                                width="100%"
                        >
                            <thead>
                            <tr>
                                <th></th>
                                <th>Vaccinated</th>
                                <th>Location</th>
                                <th>Created At</th>
                                <th>Surveyor</th>
                                <th>Area</th>
                            </tr>
                            </thead>
                        </table>
                        <div class="float-right">
                            <button
                                    v-if="bulkArchiveData.isLoading"
                                    class="btn btn-primary"
                                    type="button"
                                    disabled
                            >
                            <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                            ></span>
                                Loading...
                            </button>
                            <button
                                    v-else
                                    class="btn btn-default"
                                    :disabled="!bulkArchiveData.selectedEntries.length"
                                    @click="bulkArchive"
                            >
                                Archive Selected
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from "../../mixins/GlobalMixin";

export default {
    props: {
        projectId: {
            type: Number,
        },
        areaId: {
            type: Number,
            default: 0
        },
    },
    components: {},
    data()
    {
        return {
            table: null,
            bulkArchiveData: {
                selectedEntries: [],
                isLoading: false
            },
        };
    },
    mixins: [mixin],
    methods: {
        init()
        {
            this.getSurveyRecords();
        },
        getSurveyRecords()
        {
            let vm = this;

            if (vm.table != null) {
                vm.table.destroy();
            }

            vm.table = $("#example").DataTable({
                scrollX: true,
                responsive: true,
                dom: "lBfrtip",
                bInfo: false,
                buttons: [
                    {
                        extend: "excel",
                        text: "Export to excel",
                        className: "btn btn-default",
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4, 5]
                        }
                    }
                ],
                columnDefs: [
                    {
                        targets: 0,
                        render: function (data, type, full, meta) {
                            return `<input type="checkbox" id="rowCheck${data}" />`;
                        },
                        orderable: false
                    },
                    {
                        targets: 1,
                        render: function (data, type, full, meta) {
                            return full[1];
                        }
                    },
                    {
                        targets: 2,
                        render: function (data, type, full, meta) {
                            return `<a href="https://www.google.com/maps/search/?api=1&query=${full[2]}" target="_blank">Open in maps </a>`
                        }
                    },
                    {
                        targets: 3,
                        render: function (data, type, full, meta) {
                            return full[3];
                        }
                    },
                    {
                        targets: 4,
                        render: function (data, type, full, meta) {
                            return full[4];
                        }
                    },
                    {
                        targets: 5,
                        render: function (data, type, full, meta) {
                            return full[5];
                        }
                    }
                ],
                fnRowCallback: function (nRow, aData, iDisplayIndex) {
                    const checkBox = $("input[type='checkbox']", nRow);
                    vm.selectTableEntries(checkBox, nRow);
                    if (
                        $.inArray(
                            aData.DT_RowId,
                            vm.bulkArchiveData.selectedEntries
                        ) !== -1
                    ) {
                        $(nRow).addClass("selected");
                        checkBox.prop("checked", true);
                    }
                    return nRow;
                },
                processing: true,
                serverSide: true,
                ajax: {
                    url:
                        window.DATATABLES_BASE_URL + "surveyRecords",
                    // "url": "http://localhost:8888/api/datatables/surveyRecords",
                    type: "POST",
                    data: {
                        project_id: vm.projectId,
                        selected_area: vm.areaId
                    }
                    //"dataType": "jsonp"
                },

                lengthMenu: [
                    [5, 10, 15],
                    [5, 10, 15]
                ],
                pageLength: 10
            });
        },
        selectTableEntries(checkBoxEl, row)
        {
            const vm = this;
            checkBoxEl.on("change", function () {
                const id = row.id;
                const index = $.inArray(id, vm.bulkArchiveData.selectedEntries);
                if (index === -1) {
                    vm.bulkArchiveData.selectedEntries.push(id);
                } else {
                    vm.bulkArchiveData.selectedEntries.splice(index, 1);
                }
                $(row).toggleClass("selected");
            });
        },
        async bulkArchive()
        {
            if (this.bulkArchiveData.selectedEntries.length) {
                let selectedIds = this.bulkArchiveData.selectedEntries.map(
                    el => {
                        return el.replace("row_", "");
                    }
                );
                const requestPayload = {
                    record_ids: selectedIds,
                    // record_ids: ["241147", "241148"],
                    area_id: +localStorage.getItem('selectedArea'),
                    project_id: +this.projectId
                };
                try {
                    this.bulkArchiveData.isLoading = true;
                    const bulkArchiveResponse = await axios.post(
                        "survey/bulk/archive",
                        requestPayload
                    );
                    this.getSurveyRecords();
                    this.showAlert(
                        "Success",
                        bulkArchiveResponse.data.response.error.msg,
                        true
                    );
                    this.bulkArchiveData.selectedEntries = [];
                } catch (error) {
                    this.showAlert(
                        "Sorry",
                        error.response.data.response.error.msg,
                        false
                    );
                }
                this.bulkArchiveData.isLoading = false;
            } else {
                this.showAlert("OOPS", "Something went wrong !", false);
            }
        }
    },
    computed: {
        getTitle: function () {

            if (this.areaId > 0) {
                return "Records > Survey > List > " + localStorage.SurveyAreaName
            }

            return "Records > Survey > List > Project Level View"

        }
    },
    mounted()
    {
        this.init();
        // Pradeep Start
        let vm = this;
        vm.project = JSON.parse(localStorage.project);
        Bus.$emit("projectype", vm.project);
        // Pradeep End
    }
};
</script>

<style lang="scss">

</style>
