<template>
    <div>
        <div class="sidebar" style="background-color:#66615B !important;">
            <div class="logo logo_img_hsaapps">
                <a href="/admin/dashboard" v-if="usertype=='admin'">
                    <div class="logo-img" style="text-align: center;">
                        <img :src="logo" style="width: 150px;">
                    </div>
                </a>
                <router-link to="/platform/projects" v-else>
                    <div class="logo-img" style="text-align: center;">
                        <img :src="logo" style="width: 150px;">
                    </div>
                </router-link>

            </div>
            <div class="sidebar-wrapper set_border" ref="sidebarScrollArea" v-if="usertype=='admin'">
                <slot>

                </slot>
                <ul class="nav">
                    <slot name="links">
                        <sidebar-item v-for="(link, index) in sidebarLinksAdmin"
                                      :key="link.name + index"
                                      :location="location"
                                      :id="link.id"
                                      :collapsed="link.collapsed"
                                      :link="link">

                            <sidebar-item v-for="(subLink, index) in link.children"
                                          :key="subLink.name + index"
                                          :location="location"
                                          :parent="link.id"
                                          :link="subLink">
                            </sidebar-item>
                        </sidebar-item>
                    </slot>
                </ul>
            </div>
            <div class="sidebar-wrapper set_border" ref="sidebarScrollArea" v-if="usertype=='user'">

                <div class="setShadow_menu" @click="mvdHome">
                    <div class="logo-img projectimg">
                        <img :src="projectimg" alt="" class="img_fix_size">
                    </div>
                    <hr>
                    <div class="project_name_div" :title="projectname">
                        <h5 class="project_name">{{ projectname }}</h5>
                    </div>
                </div>

                <ul class="nav">

                    <slot name="links">
                        <sidebar-item v-for="(link, index) in DPMprojectsidebarLinks"
                                      :key="link.name + index"
                                      :location="location"
                                      :id="link.id"
                                      :collapsed="link.collapsed"
                                      :link="link">

                            <sidebar-item v-for="(subLink, index) in link.children"
                                          :key="subLink.name + index"
                                          :location="location"
                                          :parent="link.id"
                                          :link="subLink">
                            </sidebar-item>
                        </sidebar-item>
                    </slot>

                </ul>
            </div>
        </div>
        <div class="main-panel">
            <project-navbar
                    usertype="user">
            </project-navbar>
            <div class="project-details-content">
                <transition name="slide-fade" mode="out-in">
                    <router-view :projectId="projectId"></router-view>
                </transition>
            </div>
            <content-footer></content-footer>
        </div>
    </div>
</template>
<script>

import ProjectNavbar from "../../Layout/ProjectNavbar";
import ContentFooter from "../../Layout/ContentFooter";

import pmLogo from '../../../../assets/images/pm_logo.png';

import PmSideNav from '../../../../../src/PmSideNav';

export default {
    components: {
        'project-navbar': ProjectNavbar,
        'content-footer': ContentFooter
    },
    data()
    {
        return {
            location: {...location},
            ps: null,
            projecttype: '',
            projectname: '',
            projectimg: '',
        };
    },
    props: {
        usertype: {
            type: String,
            default: 'user',
        },
        projecturl: {
            type: String,
            default: 'url',
        },
        autoClose: {
            type: Boolean,
            default: true,
        },
        projectId: {
            type: String,
        }
    },
    provide()
    {
        return {
            autoClose: this.autoClose,
        };
    },
    methods: {
        mvdHome()
        {
            window.location = '/project/details/' + this.projectId;
        },
        getLocation()
        {
            return this.location;
        },
        async initScrollBarAsync()
        {
            let PerfectScroll = await import('perfect-scrollbar');
            PerfectScroll.initialize(this.$refs.sidebarScrollArea);
        }
    },

    computed: {
        DPMprojectsidebarLinks()
        {
            let loc = this.location;

            var tmp = [];

            for (var i = 0; i < PmSideNav.nav.length; i++) {
                tmp.push(PmSideNav.nav[i]);
            }

            let links = tmp.map((item, index) => {
                item.collapsed = true;
                if (item.children && item.children.length) {
                    for (let idx = 0; idx < item.children.length; idx++) {
                        let child = item.children[idx];
                        item.children[idx].path = '/project/pm/' + this.projectId + item.children[idx].p;
                    }
                } else {
                    item.path = '/project/pm/' + this.projectId + item.p;
                }
                return item;
            });
            return links;
        },
        title()
        {
            return window.appConfig.appName;
        },
        logo()
        {
            return window.appConfig.logo;
        },
    },
    mounted()
    {
        Bus.$on('projectype', data => {
            console.log("PROJECT DATA: " + data);
            this.projecttype = data.type;
            this.projectname = data.name;
            this.projectimg = data.image_url.includes("pm_logo.png") ? pmLogo : data.image_url;
            this.projectId = data.id;
        });
        //this.ps = new PerfectScroll(this.$refs.sidebarScrollArea);
        this.initScrollBarAsync();
        /*Bus.$on('link.clicked', (path) => {
            this.location.hash = '#' + path;
        });*/
    },
    beforeDestroy()
    {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    },
};

</script>
<style>

.main-panel > .project-details-content {
    padding: 0 30px 30px;
    min-height: calc(100vh - 123px);
    margin-top: 30px;
}

@media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
        display: none;
    }
}

.project_name_div {
    margin-top: 10px;
    width: 99%;
    height: 50px;
    margin-bottom: -15px;
}

.project_name {
    text-align: center;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.projectimg {
    text-align: center;
    margin-top: 10px;
}

.img_fix_size {
    height: 100px;
    margin-top: 12px;
}

.setShadow_menu {
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
    background-color: white;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer !important;
}

.sidebar-normal {
    margin-left: 44px !important;
}

.sidebar-mini-icon {
    display: none !important;
}

.logo_img_hsaapps {
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
    background-color: white;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer !important;
}

.set_border {
    border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.sidebar .nav li.active > a.active::after,
.sidebar .nav li.active > a.active::before {
    opacity: 0 !important;
}

.sidebar .nav li > a.active, .off-canvas-sidebar .nav li > a.active {
    font-weight: 800;
    font-size: 14px;
}
</style>
