<template>
    <div>
        <!--<h5>Assign Users and Teams on Areas</h5>-->
        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <h5 class="set_font_title">Assign Field Users/Teams to Areas</h5>
            </div>
            <div v-if="this.project.organisation_id" class="col-sm-12 col-md-4 text-right">
                <a v-bind:disabled="!onLine" href="/new/field_team">
                    <b><span style="font-size: 18px;">+</span> New Field Team/User</b>
                </a>
            </div>
            <div class="col-sm-12">
                <p>Select Teams and/or Individual Users</p>
            </div>
        </div>
        <hr>

        <div class="row">
            <div class="col-sm-12 col-md-4">
                <div class="form-group">
                    <template>
                        <label>Select Areas ({{ selectedAreas.length }})</label>
                        <el-select v-model="selectedAreas" multiple filterable placeholder="Select Areas">
                            <el-option
                                    v-for="area in projectAreas"
                                    :key="area.id"
                                    :label="area.name"
                                    :value="area.id">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group">
                    <template>
                        <label>Teams ({{ selectedTeams.length }})</label>
                        <el-select v-model="selectedTeams" multiple filterable placeholder="Select Teams"
                                   @change="teamSelectionChange">
                            <el-option
                                    v-for="team in teams"
                                    :key="team.id"
                                    :label="team.name"
                                    :value="team.id">
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group">
                    <template>
                        <label>Users ({{ selectedUsers.length }})</label>
                        <el-select v-model="selectedUsers" multiple filterable placeholder="Select Users"
                                   @change="">
                            <el-option
                                    class="select-user-options"
                                    v-for="user in users"
                                    :key="user.id"
                                    :label="`${user.first_name} ${user.last_name}`"
                                    :value="user.id">
                                <div>
                                    <span class="float-left" v-if="user.first_name"> <strong>  {{ user.first_name }} {{ user.last_name }} </strong></span>
                                    <br>
                                    <span style="font-size: 12px">{{ user.email }}</span>
                                </div>
                            </el-option>
                        </el-select>
                    </template>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-sm-12">
                <div class="row">
                    <label class="col-sm-12 control-label">Assign selected users for</label>
                    <div class="col-sm-12">
                        <p-radio inline v-model="assignSelection" value="vaccination" label="vaccination">Vaccination
                        </p-radio>
                        <p-radio inline v-model="assignSelection" value="survey" label="survey">Survey</p-radio>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col col-sm-12" style="text-align:right !important;">
                <button v-if="onLine" type="button"
                        :disabled="isAssigning"
                        v-on:click="assignAreasToUsers()"
                        name="Assign Areas"
                        class="btn btn-round btn-danger">
                    Assign Users
                </button>
                <button type="button"
                        v-else v-bind:disabled="true"
                        v-on:click="assignAreasToUsers()"
                        name="Assign Areas"
                        class="btn btn-round btn-danger">
                    Assign Users
                </button>
            </div>
        </div>
        <div class="row" v-if="this.project.organisation_id">
            <div class="col col-sm-12 text-right">
                <a v-bind:disabled="!onLine" href="/invite/field_user">
                    Invite a New User
                </a>
            </div>
        </div>
        <br>
    </div>
</template>
<script>

import mixin from '../../mixins/GlobalMixin';
import {Select, Option,} from 'element-ui';

import swal from 'sweetalert2';

import AreaCard from "./UIComponents/Cards/AreaCard.vue";
import VOffline from 'v-offline';

export default {
    components: {
        AreaCard,
        [Option.name]: Option,
        [Select.name]: Select,
        VOffline
    },
    props: {
        projectId: {
            type: Number,
        },
    },
    data()
    {
        return {

            searchQuery: '',

            isAssigning: false,

            projectAreas: [],
            users: [],
            teams: [],

            selectedAreas: [],
            selectedUsers: [],
            selectedTeams: [],

            previousSelectedTeams: [],

            areas: [],

            assignSelection: 'vaccination',

            project: null,

        }
    },
    mixins: [mixin],
    methods: {
        init()
        {
            this.getAllProjectAreas();
            this.getAllUsersAndTeams();
            this.getAssignedAreas();
        },
        getAllProjectAreas()
        {
            let vm = this;
            axios.get('/area', {
                params: {
                    project_id: vm.projectId,
                }
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.projectAreas = response.data.response.data;
                }
            });
        },
        getAllUsersAndTeams()
        {
            let vm = this;
            axios.get('/user/users_and_teams', {
                params: {
                    project_id: vm.projectId,
                }
            })
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.users = response.data.response.data.users;
                        vm.teams = response.data.response.data.teams;
                    }
                }).catch(error => {

            });
        },
        getAssignedAreas()
        {
            let vm = this;
            axios.get('area/assigned/vaccination/' + vm.projectId)
                .then(response => {

                    if (response.data.response.error.error_code == 0) {
                        vm.areas = response.data.response.data.assigned_vaccination_areas;
                    }

                }).catch(error => {

            });

        },
        teamSelectionChange: function (value) {
            let vm = this;
            //alert("LENGTH: " + vm.selectedTeams.length);
            for (let r = 0; r < vm.selectedTeams.length; r++) {
                //alert("IN ARRAY: " + jQuery.inArray(vm.selectedTeams[r], vm.previousSelectedTeams));
                if (jQuery.inArray(vm.selectedTeams[r], vm.previousSelectedTeams) == -1) {
                    //alert("SELECTED: " + vm.selectedTeams[r]);
                    vm.previousSelectedTeams.push(this.selectedTeams[r]);
                }
            }

            let diffArray = vm.arr_diff(vm.selectedTeams, vm.previousSelectedTeams);
            //alert("DIFF: " + diffArray);
            for (let i = 0; i < diffArray.length; i++) {
                for (let j = 0; j < vm.teams.length; j++) {
                    if (vm.teams[j].id == diffArray[i]) {
                        for (let k = 0; k < vm.teams[j].field_users.length; k++) {
                            //alert("Field Team USER ID: " + vm.teams[j].field_users[k].user.id);
                            if (jQuery.inArray(vm.teams[j].field_users[k].user.id, vm.selectedUsers) >= 0) {
                                //alert('REMOVING USER ID: ' + vm.teams[j].field_users[k].user.id);
                                let index = vm.selectedUsers.indexOf(vm.teams[j].field_users[k].user.id);
                                if (index > -1) {
                                    vm.selectedUsers.splice(index, 1);
                                }
                                //alert('AFTER REMOVING SELECTED USERS: ' + vm.selectedUsers);
                            }
                        }
                    }
                }
            }
            for (let i = 0; i < vm.selectedTeams.length; i++) {
                for (let j = 0; j < vm.teams.length; j++) {
                    if (vm.teams[j].id == vm.selectedTeams[i]) {
                        for (let k = 0; k < vm.teams[j].field_users.length; k++) {
                            if (jQuery.inArray(vm.teams[j].field_users[k].user.id, vm.selectedUsers) == -1) {
                                //alert('ADDING USER ID: ' + vm.teams[j].field_users[k].user.id);
                                vm.selectedUsers.push(vm.teams[j].field_users[k].user.id);
                                //alert('AFTER ADDING SELECTED USERS: ' + vm.selectedUsers);
                            }
                        }
                    }
                }
            }

            vm.previousSelectedTeams = vm.selectedTeams;

        },
        arr_diff(a1, a2)
        {

            let a = [], diff = [];

            for (let i = 0; i < a1.length; i++) {
                a[a1[i]] = true;
            }

            for (let i = 0; i < a2.length; i++) {
                if (a[a2[i]]) {
                    delete a[a2[i]];
                } else {
                    a[a2[i]] = true;
                }
            }

            for (let k in a) {
                diff.push(k);
            }

            return diff;
        },
        assignAreasToUsers()
        {
            let vm = this;
            swal({
                title: 'Alert!',
                text: 'Are you sure you want to assign selected users to selected areas?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Assign',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    if (vm.selectedAreas.length == 0) {
                        vm.showAlert('Error', "Please select atleast one area", false);
                    } else
                        if (vm.selectedUsers == 0) {
                            vm.showAlert('Error', "Please select atleast one user", false);
                        } else {
                            vm.isAssigning = true;
                            axios.post('area/assign/users/' + vm.assignSelection + '/' + vm.projectId, {
                                area_ids: vm.selectedAreas,
                                user_ids: vm.selectedUsers,
                            }).then(response => {
                                vm.isAssigning = false;
                                if (response.data.response.error.error_code === 0) {
                                    vm.showAlert('Assigned', response.data.response.error.msg, true);
                                    vm.getAssignedAreas();
                                } else {
                                    vm.showAlert('Error', response.data.response.error.error_msg, false);
                                }
                            }).catch(error => {
                                vm.isAssigning = false;
                                vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                            })
                        }
                }
            });

        }

    },
    computed: {
        filteredAreas: function () {
            return this.areas.filter(item => {

                return (
                    item.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
                );

            });
        },
    },
    mounted()
    {
        this.init();
        // Pradeep Start
        let vm = this;
        vm.project = JSON.parse(localStorage.project);
        Bus.$emit("projectype", vm.project);
        // Pradeep End
    }
}


</script>
<style>
.set_font_title {
    font-size: 20px;
    font-weight: 600;
    color: black;
    /* margin-left: -12px !important; */
}

.invite-btn {
    margin-top: -15px;
    text-align: right;
}
.select-user-options{
    height: 45px !important;
    line-height: 15px !important;
    padding-top: 5px;
}
</style>
